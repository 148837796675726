import { ValueOf } from '../utilities/types';

export const requestRoles = {
  PROCESSOR: 'processor',
  RECIPIENT: 'recipient',
  INITIATOR: 'initiator',
  SELLER: 'seller',
  BUYER: 'buyer',
  CAR: 'car',
  ENTITY: 'entity',
  GUARANTOR: 'guarantor',
} as const;

export type RequestRoles = ValueOf<typeof requestRoles>;

const requestRolesChoices = {
  [requestRoles.PROCESSOR]: { id: requestRoles.PROCESSOR, name: 'Processor' },
  [requestRoles.RECIPIENT]: { id: requestRoles.RECIPIENT, name: 'Recipient' },
  [requestRoles.INITIATOR]: { id: requestRoles.INITIATOR, name: 'Initiator' },
  [requestRoles.SELLER]: { id: requestRoles.SELLER, name: 'Seller' },
  [requestRoles.BUYER]: { id: requestRoles.BUYER, name: 'Buyer' },
  [requestRoles.CAR]: { id: requestRoles.CAR, name: 'Car' },
  [requestRoles.ENTITY]: { id: requestRoles.ENTITY, name: 'Entity' },
  [requestRoles.GUARANTOR]: { id: requestRoles.GUARANTOR, name: 'Guarantor' },
};

export default requestRolesChoices;
