import React from 'react';
import { Box, Typography } from '@mui/material';

import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import fieldStatus, { FieldStatus } from '../../constants/fieldStatus';

const ConflictResolutionTitle = ({
  status,
  label,
  children,
  selected,
}: {
  status: FieldStatus;
  label: string;
  children?: React.ReactNode | false;
  selected?: boolean;
}) => (
  <Box display="flex" width="100%" gap={4} alignItems="center">
    {(status === fieldStatus.CONFLICT && !selected) && <WarningIcon color="yellow" sx={{ fontSize: 24 }} />}
    {status === fieldStatus.EMPTY && <CancelIcon color="disabled" sx={{ fontSize: 24 }} />}
    {(status === fieldStatus.RESOLVED || selected) && <CheckCircleIcon color="secondary" sx={{ fontSize: 24 }} />}
    <Box display="flex" flexDirection="column">
      <Typography variant="label">{label}</Typography>
      {children}
    </Box>
  </Box>
);

export default ConflictResolutionTitle;
