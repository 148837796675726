import React from 'react';

import {
  minValue,
  required,
  SelectInput,
} from 'react-admin';

import {
  Grid,
} from '@mui/material';

import useThresholdVariant from '../../../../hooks/useThresholdVariant';

import PriorityParameters from './PriorityParameters';
import ThresholdInput from './ThresholdInput';

import NumberInput from '../../../layout/inputFields/NumberInput';

import Label from '../../../../customFields/Label';

import timePeriods from '../../../../constants/timePeriods';

const TransactionRollingSum = () => {
  const { thresholdVariant } = useThresholdVariant({ source: 'form' });

  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <ThresholdInput source="default" />
            </Grid>
            <Grid item xs={12} md={12}>
              <ThresholdInput
                source="form"
                thresholdVariant={thresholdVariant}
                displayText="Deviation in %"
                allowCommon
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Label
                variant="label"
                title="Period"
                info="The period that the threshold will be checked against."
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <NumberInput
                source="ruleParameters.period.amount"
                fullWidth
                label="Length"
                validate={[required(), minValue(1)]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectInput
                source="ruleParameters.period.unit"
                choices={Object.values(timePeriods).map((period) => ({ id: period, name: period }))}
                fullWidth
                label="Period"
                validate={[required(), minValue(1)]}
              />
            </Grid>
          </Grid>
        </Grid>
        <PriorityParameters />
      </Grid>
    </Grid>

  );
};

export default TransactionRollingSum;
