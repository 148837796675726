import { requestRoles } from '../../constants/requestRoles';
import { requestTypes } from '../../constants/requestTypes';

const ruleRequestEntityFields = {
  [requestTypes.TRANSACTION]: [
    requestRoles.INITIATOR,
    requestRoles.RECIPIENT,
    requestRoles.PROCESSOR,
  ],
  [requestTypes.CAR_PURCHASE]: [
    requestRoles.SELLER,
    requestRoles.BUYER,
  ],
  [requestTypes.CAR_KYC]: [requestRoles.ENTITY],
  [requestTypes.INDIVIDUAL_KYC]: [requestRoles.ENTITY],
  [requestTypes.BUSINESS_KYC]: [requestRoles.ENTITY],
};

export default ruleRequestEntityFields;
