import React, { useCallback, useMemo } from 'react';

import {
  TextField,
  DateField,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Datagrid,
  Pagination,
  usePermissions,
  useRecordContext,
  EditBase,
  useRefresh,
} from 'react-admin';

import {
  Box,
  Grid,
  Card,
  Typography,
  Container,
  Dialog,
  DialogTitle,
  IconButton,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { useParams } from 'react-router-dom';

import hasAccess from '../../../utilities/hasAccess';

import ReferenceMemberField from '../../../customFields/ReferenceMemberField';

import { boldDataGridStyle } from '../../../constants/style/datagridStyles';

import useDialogStatus from '../../../hooks/useDialogStatus';
import { Actions } from '../../../constants/actions';

const EditDialog = ({
  customToolbar,
  title,
  children,
  action,
}: {
  customToolbar: React.ReactElement,
  title: string,
  children: React.ReactElement,
  action: Actions,
}) => {
  const { '*': tab } = useParams();
  const { permissions } = usePermissions<Actions[]>();
  const record = useRecordContext();
  const { open, openDialog, closeDialog } = useDialogStatus();

  const maxWidth = useMemo(() => (tab === '1' ? 'lg' : undefined), [tab]);

  const refresh = useRefresh();

  const onSuccess = useCallback(() => {
    closeDialog();
    refresh();
  }, [closeDialog, refresh]);

  return (
    <Box>
      <Typography
        onClick={openDialog}
        color="primary"
        sx={{
          textDecoration: 'underline',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        {record?.id}
      </Typography>
      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth={maxWidth}>
        <DialogTitle>
          <Box display="flex" alignItems="baseline" justifyContent="space-between">
            <Typography variant="largeTitle">{`Edit ${title}`}</Typography>
            <IconButton onClick={closeDialog}>
              <CloseIcon color="primary" />
            </IconButton>
          </Box>
        </DialogTitle>
        <EditBase
          id={record?.id}
          mutationMode="pessimistic"
          mutationOptions={{ onSuccess }}
          queryOptions={{ enabled: false }}
          redirect={false}
        >
          <TabbedForm mode="all" toolbar={hasAccess(permissions, action) ? customToolbar : false}>
            <FormTab label="Information">
              {children}
            </FormTab>
            {hasAccess(permissions, 'AuditTrackList') && (
              <FormTab label="Audit Tracks">
                <Container>
                  <Grid item xs={12} md={12}>
                    <Card variant="outlined">
                      <ReferenceManyField
                        record={record}
                        reference="audit-tracks"
                        target="resource.id"
                        sort={{ field: 'date', order: 'DESC' }}
                        pagination={<Pagination />}
                      >
                        <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                          <TextField sortable={false} source="id" />
                          <ReferenceMemberField label="User" />
                          <TextField sortable={false} source="description" />
                          <DateField sortable source="date" showTime />
                        </Datagrid>
                      </ReferenceManyField>
                    </Card>
                  </Grid>
                </Container>
              </FormTab>
            )}
          </TabbedForm>
        </EditBase>
      </Dialog>
    </Box>
  );
};

export default EditDialog;
