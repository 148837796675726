import React from 'react';

import { RecordContextProvider, useRecordContext } from 'react-admin';

import { Box, Typography } from '@mui/material';

import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import fieldStatus, { FieldStatus } from '../../../constants/fieldStatus';

const DEFAULT_SX = {
  wordBreak: 'break-all',
  wordWrap: 'normal',
};

const OTHER_SELECTED_VALUE_SX = {
  ...DEFAULT_SX,
  textDecoration: 'line-through',
};

export const DisplayValues = ({
  label,
  children,
  shouldDisplayOtherSelectedValue,
  selectedValue,
}: {
  label:string;
  children?: React.ReactElement | false;
  selectedValue?: unknown;
  shouldDisplayOtherSelectedValue: boolean;
}) => {
  const record = useRecordContext();

  return (
    <>
      <Typography variant="label">{label}</Typography>
      <RecordContextProvider
        value={{
          ...record,
          ...(selectedValue ? { value: selectedValue } : {}),
          sx: shouldDisplayOtherSelectedValue ? OTHER_SELECTED_VALUE_SX : DEFAULT_SX,
        }}
      >
        {children}
      </RecordContextProvider>
    </>
  );
};

const AnswerTitle = ({
  status,
  label,
  children,
  selectedValue,
  shouldDisplayOtherSelectedValue,
}: {
  status: FieldStatus | 'selection-different-kyc-value' | 'no-datapoint';
  label:string;
  children?: React.ReactElement | false;
  selectedValue?: unknown;
  shouldDisplayOtherSelectedValue: boolean;
}) => (
  <Box display="flex" flexDirection="column" gap={2}>
    <Box display="flex" width="100%" gap={4} alignItems="center">
      {(status === 'conflict') && <WarningIcon color="yellow" sx={{ fontSize: 24 }} />}
      {status === fieldStatus.EMPTY && <CancelIcon color="disabled" sx={{ fontSize: 24 }} />}
      {(status === fieldStatus.RESOLVED || status === 'selection-different-kyc-value') && <CheckCircleIcon color="secondary" sx={{ fontSize: 24 }} />}
      {status === 'no-datapoint' && <Box width={24} />}
      <Box display="flex" flexDirection="column">
        <DisplayValues
          label={label}
          shouldDisplayOtherSelectedValue={shouldDisplayOtherSelectedValue}
        >
          {children}
        </DisplayValues>
      </Box>
    </Box>
    {shouldDisplayOtherSelectedValue && (
      <Box display="flex" flexDirection="column" pl={10}>
        <DisplayValues
          label="Currently selected value"
          shouldDisplayOtherSelectedValue={false}
          selectedValue={selectedValue}
        >
          <Box display="flex" flexWrap="wrap" minWidth={0}>
            {children}
          </Box>
        </DisplayValues>
      </Box>
    )}
  </Box>
);

export default AnswerTitle;
