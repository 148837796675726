import React, { useState } from 'react';

import {
  Button,
  Box,
  Typography,
  styled,
} from '@mui/material';

import { SelectInput, useRecordContext } from 'react-admin';

import CreateIcon from '@mui/icons-material/Create';
import CancelIcon from '@mui/icons-material/Cancel';
import StatusField from './StatusField';

const Title = styled(Typography)({
  fontWeight: '400',
  fontSize: '13px',
  color: '#646464',
});

const EditStatus = ({
  source,
  label,
  showEdit,
}: {
  source: string,
  label: string,
  showEdit: boolean,
}) => {
  const [editable, setEditable] = useState(false);
  const record = useRecordContext();

  const handleChange = () => {
    setEditable(!editable);
  };

  if (!editable) {
    return (
      <Box>
        <Title>{label}</Title>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <StatusField record={record} source={source} />
          {showEdit && (
            <Button startIcon={<CreateIcon color="secondary" />} size="small" onClick={handleChange}>
              Edit
            </Button>
          )}
        </Box>
      </Box>
    );
  }
  return (
    <Box>
      <Title>{label}</Title>
      <Box display="flex" alignItems="baseline" justifyContent="space-between">
        <SelectInput
          source="status"
          choices={[
            { id: 'Blocked', name: 'Blocked' },
            { id: 'Available', name: 'Available' },
          ]}
          label="Select status"
        />
        <Button startIcon={<CancelIcon color="error" />} size="small" onClick={handleChange}>
          Cancel
        </Button>
      </Box>

    </Box>
  );
};

export default EditStatus;
