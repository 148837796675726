import React from 'react';

import { DateField, usePermissions } from 'react-admin';

import {
  Card,
  CardHeader,
  Box,
  Divider,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';

import EditStatus from '../../customFields/EditStatus';
import RiskClassField from '../../customFields/RiskClassField';

import hasAccess from '../../utilities/hasAccess';

import RiskIndicator from './RiskIndicator';
import RiskScoreSeries from './RiskScoreSeries';
import GroupsField from '../../customFields/GroupsField';
import { Actions } from '../../constants/actions';

const RiskManagement = () => {
  const { permissions } = usePermissions<Actions[]>();
  return (
    <Card variant="outlined">
      <CardHeader
        sx={{
          color: 'black',
          fontWeight: 'bold',
          flexWrap: 'wrap',
        }}
        titleTypographyProps={{ variant: 'inherit' }}
        title="Risk Management"
        action={(
          <Box alignSelf="center" gap={1} display="flex" mt={1}>
            <Typography variant="smallTitle">
              Last update:
            </Typography>
            <DateField
              variant="smallTitle"
              source="scoreLatestCalculationDate"
              showTime
            />
          </Box>
        )}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6} lg={12} xl={6}>
            <Grid container spacing={6} pt={5} justifyContent="center">
              <Grid item xs={12} display="flex" justifyContent="center">
                <RiskIndicator source="riskScore" />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <RiskScoreSeries />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={12} xl={6}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={12}>
                <EditStatus showEdit={hasAccess(permissions, 'IndividualUpdate')} source="status" label="Status" />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} py={1}>
              <GroupsField showEdit={hasAccess(permissions, 'IndividualUpdate')} />
            </Grid>
            <Grid item xs={12} md={12} py={1}>
              <Box>
                <Typography variant="smallTitle">
                  Risk classes
                </Typography>
                <RiskClassField chip source="riskScore" />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RiskManagement;
