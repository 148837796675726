import React from 'react';

import {
  Edit,
  TextField,
  DateField,
  ReferenceManyField,
  Datagrid,
  useRedirect,
  useNotify,
  FormDataConsumer,
  SimpleForm,
  Pagination,
} from 'react-admin';

import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
} from '@mui/material';

import HistoryIcon from '@mui/icons-material/History';

import ReferenceMemberField from '../../customFields/ReferenceMemberField';
import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import ruleTypeMapper from './ruleTypeMapper';
import useDialogStatus from '../../hooks/useDialogStatus';
import RuleBreadcrumb from './RuleBreadcrumb';
import useRule from '../../hooks/useRule';
import useValidateId from '../../hooks/useValidateId';

const RuleEdit = () => {
  const redirect = useRedirect();
  const notify = useNotify();

  const {
    ruleType,
    view,
  } = useRule();

  const { open, openDialog, closeDialog } = useDialogStatus();

  const onSuccess = () => {
    notify('Rule updated');
    redirect('list', 'rules', undefined, {}, { record: { ruleType, view } });
  };

  const validatedId = useValidateId();
  if (!validatedId.isValid) return null;

  return (
    <Edit
      mutationOptions={{ onSuccess }}
      mutationMode="pessimistic"
      actions={(
        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button onClick={openDialog} variant="outlined" startIcon={<HistoryIcon />}>Audit track</Button>
        </Box>
      )}
    >
      <>
        <RuleBreadcrumb />
        <SimpleForm mode="all" toolbar={false}>
          <FormDataConsumer>
            {({ formData }) => formData?.ruleType && ruleTypeMapper[formData.ruleType]()}
          </FormDataConsumer>
          <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xl">
            <DialogContent>
              <Card variant="outlined">
                <ReferenceManyField
                  reference="audit-tracks"
                  target="resource.id"
                  sort={{ field: 'date', order: 'DESC' }}
                  pagination={<Pagination />}
                >
                  <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                    <TextField sortable={false} source="id" />
                    <ReferenceMemberField label="User" />
                    <TextField sortable={false} source="description" />
                    <DateField sortable source="date" showTime />
                  </Datagrid>
                </ReferenceManyField>
              </Card>
            </DialogContent>
          </Dialog>
        </SimpleForm>
      </>
    </Edit>
  );
};

export default RuleEdit;
