import React, { useCallback, useState } from 'react';

import {
  Datagrid,
  DateField,
  Edit,
  FormTab,
  Pagination,
  ReferenceManyField,
  TabbedForm,
  TextField,
  usePermissions,
} from 'react-admin';

import {
  Card,
  Grid,
  SxProps,
} from '@mui/material';

import { Routes, Route } from 'react-router-dom';
import useCustomEditView from '../../hooks/useCustomEditView';

import { StyledTab, StyledTabs } from '../layout/Tab';
import SubNavigation from '../layout/SubNavigation';

import { AccountEntityListBody } from '../accountEntity/AccountEntityList';

import CarOwnership from '../../customFields/CarOwnership';
import ReferenceMemberField from '../../customFields/ReferenceMemberField';
import CustomToolbar from '../../customFields/CustomToolbar';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import actions, { Actions } from '../../constants/actions';

import hasAccess from '../../utilities/hasAccess';

import IndividualBreadcrumb from './IndividualBreadcrumb';

import Transaction from '../transaction';
import Categorization from '../categorization';
import KycForm from '../kycForm';
import KycFormView from '../kycForm/view';

import ConflictResolution from '../conflict/ConflictResolution';
import ConflictResolutionManualInput from '../conflict/ConflictResolutionManualInput';
import ConflictResolutionHistory from '../conflict/ConflictResolutionHistory';

import Information from './editTabs/Information';
import BusinessAssociation from './editTabs/kyc/BusinessAssociation';
import SPAR from './editTabs/kyc/PopulationRegister';
import Pep from './editTabs/kyc/Pep';
import SanctionList from './editTabs/kyc/SanctionList';
import CriminalRecord from './editTabs/kyc/CriminalRecord';
import useValidateId from '../../hooks/useValidateId';

const customEditSx = (isCustomView: boolean): SxProps => (
  isCustomView
    ? { '& .MuiPaper-root': { backgroundColor: 'transparent', boxShadow: 'none' } }
    : {}
);
const IndividualsEdit = () => {
  const { permissions } = usePermissions<Actions[]>();
  const [kycTab, setKycTab] = useState<string>('spar');

  const { isCustomView } = useCustomEditView();

  const handleKycTabChange = useCallback((
    _event: React.SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setKycTab(newValue);
  }, []);

  const validatedId = useValidateId();
  if (!validatedId.isValid) return null;

  return (
    <Edit
      mutationMode="pessimistic"
      redirect={false}
      sx={customEditSx(isCustomView)}
    >
      <>
        <IndividualBreadcrumb />

        {/* Custom nested routes */}
        <Routes>
          <Route path="/form-answers/:formId/:kycFormId/:formPage">
            <Route path="" Component={KycFormView} />
          </Route>
          <Route path="conflict-resolution">
            <Route path="" Component={ConflictResolution} />
            <Route path="manual-input" Component={ConflictResolutionManualInput} />
            <Route path="resolution-history" Component={ConflictResolutionHistory} />
          </Route>
          <Route
            path="*"
            element={(
              <TabbedForm
                mode="all"
                toolbar={
                  <CustomToolbar excludeDirtyFields={['exposure']} />
                }
                sx={isCustomView ? { display: 'none' } : {}}
              >
                <FormTab label="Information">
                  <Information setKycTab={setKycTab} />
                </FormTab>
                <FormTab label="KYC Data">
                  <SubNavigation>
                    <StyledTabs value={kycTab} onChange={handleKycTabChange}>
                      <StyledTab label="Population register" value="spar" />
                      <StyledTab label="Business Association" value="business-association" />
                      <StyledTab label="PEP" value="pep" />
                      <StyledTab label="Sanction List" value="sanction-list" />
                      <StyledTab label="Criminal record" value="criminal-record" />
                    </StyledTabs>
                  </SubNavigation>
                  {kycTab === 'spar' && <SPAR />}
                  {kycTab === 'business-association' && <BusinessAssociation />}
                  {kycTab === 'pep' && <Pep />}
                  {kycTab === 'sanction-list' && <SanctionList />}
                  {kycTab === 'criminal-record' && <CriminalRecord />}
                </FormTab>
                <FormTab label="KYC Form" path="form-answers">
                  <KycForm />
                </FormTab>
                {hasAccess(permissions, actions.CAR_GET) && (
                  <FormTab label="Car ownership">
                    <CarOwnership />
                  </FormTab>
                )}
                {hasAccess(permissions, actions.REQUEST_GET) && (
                  <FormTab label="Transactions">
                    <Transaction />
                  </FormTab>
                )}
                {hasAccess(permissions, actions.ACCOUNT_ENTITY_GET) && (
                  <FormTab label="Connected Accounts">
                    <Grid container>
                      <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                          <ReferenceManyField
                            fullWidth
                            perPage={5}
                            pagination={<Pagination rowsPerPageOptions={[5, 10, 20]} />}
                            reference="account-entities"
                            target="connectedEntities.id"
                            sort={{ field: 'createdAt', order: 'DESC' }}
                          >
                            <AccountEntityListBody />
                          </ReferenceManyField>
                        </Card>
                      </Grid>
                    </Grid>
                  </FormTab>
                )}
                {hasAccess(permissions, actions.BANK_DATA_GET_CATEGORIZATION) && (
                  <FormTab label="Categorization">
                    <Categorization />
                  </FormTab>
                )}
                {hasAccess(permissions, actions.AUDIT_TRACK_LIST) && (
                  <FormTab label="Audit Tracks">
                    <Grid container>
                      <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                          <ReferenceManyField
                            reference="audit-tracks"
                            target="resource.id"
                            sort={{ field: 'date', order: 'DESC' }}
                            pagination={<Pagination />}
                          >
                            <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                              <TextField sortable={false} source="id" />
                              <ReferenceMemberField label="User" />
                              <TextField sortable={false} source="description" />
                              <DateField sortable source="date" showTime />
                            </Datagrid>
                          </ReferenceManyField>
                        </Card>
                      </Grid>
                    </Grid>
                  </FormTab>
                )}
              </TabbedForm>
            )}
          />
        </Routes>
      </>
    </Edit>
  );
};

export default IndividualsEdit;
