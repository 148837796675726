/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';

import {
  SelectField, useRecordContext,
} from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';
import { get } from 'lodash';
import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import booleanChoices from '../../../../constants/booleanChoices';
import { countryChoices } from '../../../../constants/countries';
import { pepRelationChoices } from '../../../../constants/pepRelations';
import { pepRoleChoices } from '../../../../constants/pepRoles';
import conflictFields from '../../../../constants/conflictFields';

const PEP_TITLE = {
  [languages.EN]: 'Politically exposed person',
  [languages.SV]: 'Politiskt utsatt ställning?',
} as const;

const IS_PEP_TITLE = {
  [languages.EN]: 'Is the person or someone close to them politically exposed?',
  [languages.SV]: 'Är du eller någon i din närhet en person i politiskt utsatt ställning?',
} as const;

const PEP_POSITION_TITLE = {
  [languages.EN]: 'PEP position',
  [languages.SV]: 'PEP position',
} as const;

const PEP_RELATION_TITLE = {
  [languages.EN]: 'Relation to the politically exposed person',
  [languages.SV]: 'Vilken relation har du till personen?',
} as const;

const PEP_COUNTRY_TITLE = {
  [languages.EN]: 'In which country is the role held that entails PEP status',
  [languages.SV]: 'I vilket land innehas rollen som medför PEP status',
} as const;

const ValueField = ({
  language,
  md = 6,
  boldFont = true,
}: {
  language?: Languages,
  md?: number,
  boldFont?: boolean
}) => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  if (!language) return null;

  return (
    <>
      <Grid item xs={12} md={md}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">{IS_PEP_TITLE[language]}</Typography>
          <SelectField
            record={value}
            fontWeight={boldFont ? 'bold' : ''}
            choices={booleanChoices[language]}
            source="isPep"
            emptyText="-"
            sx={sx}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={md}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">{PEP_POSITION_TITLE[language]}</Typography>
          <SelectField
            fontWeight={boldFont ? 'bold' : ''}
            record={value}
            choices={pepRoleChoices(language)}
            source="pep.role"
            emptyText="-"
            sx={sx}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={md}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">{PEP_RELATION_TITLE[language]}</Typography>
          <SelectField
            fontWeight={boldFont ? 'bold' : ''}
            record={value}
            choices={pepRelationChoices(language)}
            source="pep.relation"
            emptyText="-"
            sx={sx}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={md}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">{PEP_COUNTRY_TITLE[language]}</Typography>
          <SelectField
            fontWeight={boldFont ? 'bold' : ''}
            record={value}
            choices={countryChoices(language)}
            source="pep.country"
            emptyText="-"
            sx={sx}
          />
        </Box>
      </Grid>
    </>
  );
};

const Pep = () => {
  const record = useRecordContext();

  const language = 'en';

  const value = get(record, 'pep', get(record, 'value'));

  if (!value || !language) return <Typography>-</Typography>;

  return (
    <Answer
      label={PEP_TITLE[language]}
      source="pep"
      datapoint={conflictFields.PEP}
      isComplexType
    >
      <Grid item xs={12} md={12}>
        <Grid container spacing={2}>
          <ValueField language={language} />
        </Grid>
      </Grid>
    </Answer>
  );
};

Pep.ValueField = ({ language }: {language?: Languages}) => (
  <Grid item xs={12} md={12}>
    <Grid container spacing={2}>
      <ValueField language={language} />
    </Grid>
  </Grid>
);

Pep.Nested = () => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <ValueField language={language} md={3} boldFont={false} />
  );
};

export default Pep;
