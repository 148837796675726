import React from 'react';
import {
  Card,
  Grid,
  Typography,
  Box,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';

import {
  Datagrid,
  TextField,
  ArrayField,
  useRecordContext,
  NumberField,
  DateField,
  SelectField,
} from 'react-admin';

import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';

import ReferenceEntityField from '../../../../customFields/ReferenceEntityField';
import ReferenceScoreField from '../../../../customFields/ReferenceScoreField';
import ReferenceLastKycRequest from '../../../../customFields/ReferenceLastKycRequest';
import { transmodeTypesChoices } from '../../../../constants/transmodeTypes';
import entityTypes from '../../../../constants/entityTypes';

const Transaction = () => {
  const record = useRecordContext();
  if (!record) return null;

  const { extraInformation } = record;

  const reformedEntities = {
    entities: [
      {
        requestRole: 'Processor',
        id: extraInformation?.processor?.id,
        ip: extraInformation?.processor?.ip,
        type: extraInformation?.processor?.type,
      },
      {
        requestRole: 'Recipient',
        id: extraInformation?.recipient?.id,
        ip: extraInformation?.recipient?.ip,
        type: extraInformation?.recipient?.type,
      },
      {
        requestRole: 'Initiator',
        id: extraInformation?.initiator?.id,
        ip: extraInformation?.initiator?.ip,
        type: extraInformation?.initiator?.type,
      },
      ...(extraInformation?.initiator?.conductors
        ? extraInformation.initiator.conductors.map(
          (conductor: { id: string, type: typeof entityTypes.INDIVIDUAL }) => ({
            requestRole: 'Conductor',
            id: conductor?.id,
            type: conductor.type,
          }),
        )
        : []
      ),
    ].filter((item) => item?.id),
  };

  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <CardHeader title="Transaction Details" />
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={3} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="smallTitle">Amount</Typography>
                    <NumberField record={extraInformation} source="amount" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="smallTitle">Currency</Typography>
                    <TextField record={extraInformation} source="currency" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="smallTitle">Date</Typography>
                    <DateField showTime record={extraInformation} source="date" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="smallTitle">Text message</Typography>
                    <TextField record={extraInformation} source="textMessage" emptyText="-" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="smallTitle">Reference</Typography>
                    <TextField record={extraInformation} source="reference" emptyText="-" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="smallTitle">
                      Transaction channel
                    </Typography>
                    <SelectField record={extraInformation} choices={transmodeTypesChoices} source="transactionChannel" emptyText="-" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="smallTitle">
                      Transaction channel provide
                    </Typography>
                    <TextField record={extraInformation} source="transactionChannelProvider" emptyText="-" />
                  </Box>
                </Grid>
              </Grid>
              <Card variant="outlined">
                <CardHeader title="Involved Entities" />
                <Divider />
                <ArrayField record={reformedEntities} source="entities">
                  <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                    <TextField emptyText="-" source="requestRole" label="Role" />
                    <ReferenceEntityField source="" label="Name" />
                    <TextField emptyText="-" source="type" label="Type" />
                    <ReferenceLastKycRequest source="" label="Latest KYC request" />
                    <ReferenceScoreField source="" label="Risk score" />
                    <TextField source="ip" label="IP address" emptyText="-" />
                  </Datagrid>
                </ArrayField>
              </Card>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Transaction;
