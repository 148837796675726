import React from 'react';

import {
  TextField,
  useRecordContext,
  RecordContextProvider,
} from 'react-admin';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Box,
  Divider,
  styled,
} from '@mui/material';

import useEntityData from '../../../../hooks/useEntityData';
import actions from '../../../../constants/actions';
import { NationalIdentifierSingleField } from '../../../../customFields/NationalIdentifier';
import { PopulationRegister } from '../../../../utilities/schemas/populationRegister';

const Title = styled(Typography)({
  fontWeight: '400',
  fontSize: '13px',
  color: '#646464',
});

const EntityData = () => {
  const record = useRecordContext();
  const { data } = useEntityData<PopulationRegister>({
    resource: 'individuals',
    source: 'population-register',
    getAction: actions.INDIVIDUAL_GET_SPAR,
  });

  return (
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <CardHeader title="Entity Data" />
        <Divider />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Title>First name</Title>
              <TextField record={record} source="firstName" emptyText="-" />
            </Box>
            <Box>
              <Title>Middle name</Title>
              <TextField record={data?.record?.name?.at(0)} source="middleName" emptyText="-" />
            </Box>
            <Box>
              <Title>Last name</Title>
              <TextField record={record} source="lastName" emptyText="-" />
            </Box>
            <Box>
              <Title>National identifiers</Title>
              <RecordContextProvider value={record}>
                <NationalIdentifierSingleField underline={false} />
              </RecordContextProvider>
            </Box>
            <Box>
              <Title>Gender</Title>
              <TextField emptyText="-" record={data?.record?.details?.at(0)} source="gender" />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default EntityData;
