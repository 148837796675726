import React, { useEffect, useState } from 'react';

import { useGetList } from 'react-admin';

import {
  Card,
  CardContent,
  Box,
  CardHeader,
  LinearProgress,
  Typography,
} from '@mui/material';

import ReactApexChart from 'react-apexcharts';

import { isEmpty } from 'lodash';

import EntityType from './EntityType';

const useGetGraphData = (
  data?: {
    x: string,
    y: number,
  }[],
) => {
  const [hasData, setHasData] = useState(false);
  const [options, setOptions] = useState<ReactApexChart['props']['options']>();
  const [series, setSeries] = useState<ReactApexChart['props']['series']>();

  useEffect(() => {
    if (isEmpty(data)) return;

    const newSeries = [{
      name: 'Score distribution',
      type: 'area',
      data,
    }] as ReactApexChart['props']['series']; // satisfies ReactApexChart['props']['series']  when ts is upgraded;

    const newOptions = {
      chart: {
        redrawOnParentResize: true,
        height: 400,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        curve: 'smooth',
        show: false,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          shadeIntensity: 0.1,
          type: 'horizontal',
          opacityFrom: 1,
          opacityTo: 1,
          colorStops: [
            {
              offset: 0,
              color: '#50C878',
              opacity: 1,
            },
            {
              offset: 50,
              color: '#E4D00A',
              opacity: 1,
            },
            {
              offset: 90,
              color: '#C70039',
              opacity: 1,
            },
          ],
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        tickAmount: 10,
      },
    } as ReactApexChart['props']['options']; // satisfies ReactApexChart['props']['options'] when ts is upgraded;

    setHasData(true);
    setOptions(newOptions);
    setSeries(newSeries);
  }, [data]);

  return {
    hasData,
    options,
    series,
  };
};

const ScoreDistribution = () => {
  const [type, setType] = useState('All');
  const { data, isLoading } = useGetList('dashboard/score-distribution', { filter: { type } });

  const { hasData, options, series } = useGetGraphData(data);

  return (
    <Card style={{ margin: 0 }}>
      <CardHeader
        title="Score distribution"
        action={<EntityType type={type} setType={setType} />}
      />
      <CardContent>
        {hasData && <ReactApexChart options={options} series={series} type="line" height={300} />}
        {(isLoading && !hasData) && <LinearProgress /> }
        {(!hasData && !isLoading) && (
          <Box margin="4rem">
            <Typography align="center"> No scores available </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ScoreDistribution;
