import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import {
  Identifier,
  RaRecord,
  useGetList,
  useNotify,
} from 'react-admin';

import {
  isEmpty,
  isNumber,
} from 'lodash';

import ReactApexChart from 'react-apexcharts';

import {
  Grid,
  Card,
  CardHeader,
  Divider,
  Box,
  LinearProgress,
  TextField,
} from '@mui/material';

import useDialogStatus from '../../../hooks/useDialogStatus';

import NoResults from '../../layout/NoResults';
import SelectInputButton from '../../layout/inputFields/SelectInputButton';

import ReferenceDetailedTransactionTable from '../ReferenceDetailedTransactionTable';
import Params from '../Params';
import { requestTypes } from '../../../constants/requestTypes';

type DataPoint = {
  x: string,
  y: number,
  transactions: unknown[]
  currency: string
}

type Data = {
  id: Identifier;
  window: {
    from: string;
    to: string;
  };
  max: number;
  min: number;
  totalVolume: number;
  incomingVolume: number;
  outgoingVolume: number;
  incomingFrequency: number;
  totalFrequency: number;
  outgoingFrequency: number;
  transactions: string[];
};

const CHOICES = [
  { id: 'Total Frequency', name: 'Total Frequency Distribution' },
  { id: 'Outgoing Frequency', name: 'Outgoing Frequency Distribution' },
  { id: 'Incoming Frequency', name: 'Incoming Frequency Distribution' },
  { id: 'Total Volume', name: 'Total Volume Distribution' },
  { id: 'Incoming Volume', name: 'Incoming Volume Distribution' },
  { id: 'Outgoing Volume', name: 'Outgoing Volume Distribution' },
];

const withinBucket = (
  value1: number,
  value2: number,
  bucket: number,
) => Math.abs(value1 - value2) <= bucket;

const processData = (
  data: Data[] | undefined,
  prop: keyof Data,
  bucketSize: number,
) => {
  if (isEmpty(data)) return data;

  const combinedData = data!.reduce<{
    x: number;
    y: number;
    transactions: string[];
  }[]>(
    (acc, curr) => {
      const propValue = curr[prop] as number;

      const foundIndex = acc.findIndex(
        (item) => withinBucket(item.x, propValue, bucketSize),
      );

      if (foundIndex !== -1) {
        acc[foundIndex].y += 1;
        acc[foundIndex].transactions = [...acc[foundIndex].transactions, ...curr.transactions];
      } else {
        acc.push({
          x: propValue,
          y: 1,
          transactions: curr.transactions,
        });
      }

      return acc;
    },
    [],
  ).sort((a, b) => a.x - b.x);

  return combinedData;
};

const useGetGraphData = (
  data: Data[] | undefined,
  onClickGraph: (_event: unknown, _chartContext: unknown, config: unknown) => void,
  currency: string,
  bucketWidth: number,
  showSeries: string,
) => {
  const [hasData, setHasData] = useState(false);
  const [options, setOptions] = useState<ReactApexChart['props']['options']>();
  const [series, setSeries] = useState<ReactApexChart['props']['series']>();

  const {
    totalFrequency,
    totalVolume,
    incomingVolume,
    outgoingVolume,
    outgoingFrequency,
    incomingFrequency,
  } = useMemo(() => ({
    totalFrequency: processData(data, 'totalFrequency', bucketWidth),
    incomingFrequency: processData(data, 'incomingFrequency', bucketWidth),
    outgoingFrequency: processData(data, 'outgoingFrequency', bucketWidth),
    totalVolume: processData(data, 'totalVolume', bucketWidth),
    incomingVolume: processData(data, 'incomingVolume', bucketWidth),
    outgoingVolume: processData(data, 'outgoingVolume', bucketWidth),
  }), [bucketWidth, data]);

  useEffect(() => {
    if (isEmpty(data)) return;

    const newSeries = [{
      name: 'Total Frequency',
      data: totalFrequency,
      color: '#FEB019',
    },
    {
      name: 'Outgoing Frequency',
      data: outgoingFrequency,
      color: '#FEB019',
    },
    {
      name: 'Incoming Frequency',
      data: incomingFrequency,
      color: '#FEB019',
    },
    {
      name: 'Total Volume',
      data: totalVolume,
      color: '#388e3c',
    },
    {
      name: 'Incoming Volume',
      data: incomingVolume,
      color: '#388e3c',
    },
    {
      name: 'Outgoing Volume',
      data: outgoingVolume,
      color: '#388e3c',
    },

    ]?.filter((item) => item.name === showSeries) as ReactApexChart['props']['series'];

    const newOptions = {
      chart: {
        redrawOnParentResize: true,
        stacked: false,
        height: 400,
        events: {
          dataPointSelection: onClickGraph,
        },
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: undefined,
          title: {
            formatter: () => 'Frequency',
          },
        },
        x: {
          formatter(_value: number, {
            seriesIndex, w, dataPointIndex,
          }: {
            seriesIndex: number,
            dataPointIndex: number,
            w: RaRecord
          }) {
            const {
              name,
            } = w.globals.initialSeries[seriesIndex];

            const {
              x,
            } = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

            return `${name} of ${x}`;
          },
        },
      },
      legend: {
        show: false,
      },
      yaxis: {
        decimalsInFloat: 0,
        title: {
          text: 'Frequency',
        },
      },
      xaxis: {
        tickAmount: 'dataPoints',
        tickPlacement: 'on',
        type: 'numeric',
        decimalsInFloat: 0,
        labels: {
          rotate: -90,
        },
      },
    } as ReactApexChart['props']['options']; // satisfies ReactApexChart['props']['options'] when ts is upgraded;

    setHasData(true);
    setOptions(newOptions);
    setSeries(newSeries);
  }, [
    currency,
    data,
    incomingVolume,
    onClickGraph,
    outgoingVolume,
    showSeries,
    totalFrequency,
    totalVolume,
    outgoingFrequency,
    incomingFrequency,
  ]);

  return {
    hasData,
    options,
    series,
  };
};

const Distribution = ({
  params,
  setParams,
  filter,
}: {
  params: Params
  setParams: React.Dispatch<React.SetStateAction<Params>>
  filter: {
    from: string;
    to: string;
    currency: string;
    entityId: string;
    requestType: typeof requestTypes.TRANSACTION;
  }
}) => {
  const notify = useNotify();

  const { open, closeDialog, openDialog } = useDialogStatus();

  const [bucketWidth, setBucketWidth] = useState(0);

  const [selectedData, setData] = useState<DataPoint>();
  const [showSeries, setShowSeries] = useState('Total Volume');

  const { data, isLoading } = useGetList<Data>(
    'requests/graph/distribution',
    {
      filter: {
        ...filter,
        windowSize: params.distributionWindow,
      },
    },
    {
      enabled: isNumber(params.distributionWindow),
      onError: () => {
        notify('Could not get distribution data', { type: 'warning' });
      },
    },
  );

  const onClickGraph = useCallback((
    _event: any,
    _chartContext: any,
    config: any,
  ) => {
    const dataPoint = config?.w?.config?.series
      .find((series: RaRecord) => series.data.length > 0)
      ?.data[config?.dataPointIndex];
    setData(() => dataPoint);
    openDialog();
  }, [openDialog]);

  const { hasData, options, series } = useGetGraphData(
    data,
    onClickGraph,
    params.currency,
    bucketWidth,
    showSeries,
  );

  if (isLoading) return <LinearProgress />;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardHeader
            title={(
              <Box maxWidth="fit-content">
                <SelectInputButton
                  choices={CHOICES}
                  value={showSeries}
                  setValue={setShowSeries}
                  size="large"
                  color="secondary"
                  sx={{ justifyContent: 'flex-start' }}
                />
              </Box>
            )}
            action={(
              <Box gap="1rem" display="flex" flexDirection="row">
                <TextField
                  label="Window"
                  type="number"
                  size="small"
                  value={params.distributionWindow ?? ''}
                  onChange={
                    (e) => setParams((prev) => ({
                      ...prev,
                      distributionWindow: e.target.value ? parseInt(e.target.value, 10) : null,
                    }))
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="Bucket width"
                  type="number"
                  size="small"
                  value={bucketWidth}
                  onChange={
                    (e) => setBucketWidth(parseInt(e.target.value, 10))
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            )}
          />
          <Divider />
          {hasData && <ReactApexChart options={options} series={series} type="bar" height={600} />}
          {!hasData && <NoResults variant="h4" />}
        </Card>
      </Grid>
      <ReferenceDetailedTransactionTable
        open={open}
        closeDialog={closeDialog}
        data={selectedData}
      />
    </Grid>
  );
};

export default Distribution;
