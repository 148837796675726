import React, { useCallback } from 'react';
import {
  useNotify,
  useRecordContext,
  useUpdate,
  useRefresh,
} from 'react-admin';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import DoneAllIcon from '@mui/icons-material/DoneAll';

type CaseDoc = {
  id: string;
  status: 'Open' | 'Closed';
};

const UpdateCaseStatusField = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext<CaseDoc>();
  const { id, status } = record || {};

  const [closed] = useUpdate();

  const handleSubmit = useCallback(() => {
    const newStatus = status === 'Open' ? 'Closed' : 'Open';
    closed(
      'cases',
      { id: `${id}/status`, data: { status: newStatus } },
      {
        onSuccess: () => {
          notify('Case status have been updated');
          refresh();
        },
        onError: () => {
          notify('Could not update case status', { type: 'error' });
        },
      },
    );
  }, [closed, id, notify, refresh, status]);

  return (
    <Box display="flex">
      <Box display="flex" marginRight={2}>
        <Button
          variant="outlined"
          startIcon={status === 'Closed' ? <RemoveDoneIcon color="secondary" /> : <DoneAllIcon color="secondary" />}
          onClick={handleSubmit}
        >
          {status === 'Closed' ? 'Open Case' : 'Close Case'}
        </Button>
      </Box>
    </Box>
  );
};

export default UpdateCaseStatusField;
