import React from 'react';

import {
  FormDataConsumer,
  required,
  TextInput,
  DateInput,
  SelectInput,
  AutocompleteInput,
} from 'react-admin';

import { Grid } from '@mui/material';

import SelectRequestParty from '../../../../customFields/SelectRequestParty';

import requestEntityFields from '../../../../constants/requestEntityFields';
import requestTypes from '../../../../constants/requestTypes';
import currencies from '../../../../constants/currencies';
import requestRoles from '../../../../constants/requestRoles';
import { transmodeTypesChoices } from '../../../../constants/transmodeTypes';

const Transaction = () => (
  <Grid container spacing={6}>
    <FormDataConsumer>
      {({ formData }) => formData.requestType && (
        <Grid item xs={12} lg={8}>
          <Grid container spacing={6}>
            <Grid item xs={3} md={3}>
              <TextInput source="extraInformation.amount" label="Amount" validate={required()} fullWidth />
            </Grid>
            <Grid item xs={3} md={3}>
              <DateInput source="extraInformation.date" label="Date" validate={required()} fullWidth />
            </Grid>
            <Grid item xs={3} md={3}>
              <AutocompleteInput label="Currency" source="extraInformation.currency" choices={currencies.map((currency) => ({ id: currency, name: currency }))} validate={required()} fullWidth />
            </Grid>
            <Grid item xs={3} lg={3}>
              <TextInput source="extraInformation.textMessage" label="Text message" fullWidth />
            </Grid>
            <Grid item xs={3} lg={3}>
              <TextInput source="extraInformation.reference" label="Reference" fullWidth />
            </Grid>
            <Grid item xs={3} md={3}>
              <SelectInput choices={transmodeTypesChoices} source="extraInformation.transactionChannel" label="Transaction channel" fullWidth />
            </Grid>
            <Grid item xs={3} lg={3}>
              <TextInput source="extraInformation.transactionChannelProvider" label="Transaction channel provider" fullWidth />
            </Grid>
          </Grid>
        </Grid>
      )}
    </FormDataConsumer>

    {requestEntityFields[requestTypes.TRANSACTION.id].map((role) => (
      <Grid item xs={12} md={12} display="flex">
        <SelectRequestParty key={role} role={role} resettableRoles={[requestRoles.processor.id]} />
      </Grid>
    ))}
  </Grid>
);

export default Transaction;
