import React from 'react';

import {
  Box, Typography, Fade, TypographyVariant,
} from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';

const NoResults = ({ variant, timeout }: { variant?: TypographyVariant, timeout?: number }) => (
  <Fade in timeout={timeout}>
    <Box sx={{
      padding: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    }}
    >
      <SearchOffIcon />
      <Typography variant={variant}>
        No results found
      </Typography>
    </Box>
  </Fade>

);

export default NoResults;
