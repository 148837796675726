import React, { useState, useCallback } from 'react';

import {
  ReferenceManyField,
  Button,
  Confirm,
  useCreate,
  useRefresh,
  useRedirect,
  Identifier,
  useResourceContext,
} from 'react-admin';

import {
  Grid,
  Card,
  CardHeader,
  Divider,
  Box,
} from '@mui/material';

import CallMergeIcon from '@mui/icons-material/CallMerge';
import AddIcon from '@mui/icons-material/Add';

import { useParams } from 'react-router-dom';

import { CaseListBody } from '../components/case/CaseList';
import Pagination from '../components/layout/Pagination';

import { entityTypesFromResource } from '../constants/entityTypes';

const MergeButton = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();

  const [merge, { isLoading }] = useCreate();

  const handleClick = () => setOpen(true);

  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    merge(
      'cases/merge',
      { data: { entityId: id } },
      {
        onSuccess: () => {
          refresh();
          setOpen(false);
        },
      },
    );
  };

  return (
    <>
      <Button label="Merge Open Cases" onClick={handleClick}><CallMergeIcon /></Button>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Merge Cases"
        content="Are you sure you want merge all open cases into one?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const CreateButton = () => {
  const { id } = useParams();

  const [create] = useCreate();
  const redirect = useRedirect();
  const resource = useResourceContext();

  const entityType = entityTypesFromResource[resource];

  const handleClick = useCallback(() => {
    create(
      'cases',
      { data: { entityId: id, entityType } },
      { onSuccess: ((resp: { id: Identifier }) => redirect('list', `cases/${resp.id}`)) },
    );
  }, [create, id, redirect, entityType]);

  return (
    <Button label="Create" startIcon={<AddIcon />} onClick={handleClick} />
  );
};

const LatestRelatedCases = () => (
  <Grid item xs={12} md={12}>
    <Card variant="outlined">
      <CardHeader
        title="Related Cases"
        action={(
          <Box display="flex" gap={1}>
            <MergeButton />
            <CreateButton />
          </Box>
        )}
      />
      <Divider />
      <ReferenceManyField
        perPage={5}
        pagination={<Pagination disableEmptyText rowsPerPageOptions={[5, 10, 20]} />}
        reference="cases"
        target="entities"
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <CaseListBody bulkActionButtons={false} />
      </ReferenceManyField>
    </Card>
  </Grid>
);

export default LatestRelatedCases;
