import { Card } from '@mui/material';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  SelectField,
} from 'react-admin';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';

import ColoredScoreField from '../../customFields/ColoredScoreField';

import NoResults from '../layout/NoResults';
import Pagination from '../layout/Pagination';
import SearchField from '../layout/SearchField';
import LinkField from '../layout/LinkField';

import countries from '../../constants/countries';

export const BusinessListBody = ({ children }: {children?: React.ReactElement}) => (
  <Datagrid sx={boldDataGridStyle} bulkActionButtons={false} empty={<NoResults variant="h6" />}>
    <LinkField sortable={false} source="id" />
    <TextField sortable={false} source="customId" label="Custom id" />
    <TextField sortable={false} source="name" />
    <TextField sortable={false} source="registrationNumber" />
    <SelectField sortable={false} source="residence" label="Registration country" choices={countries} />
    <ColoredScoreField source="riskScore" />
    {children}
  </Datagrid>
);

export const BusinessFilters = [
  <SearchField source="registrationNumber" alwaysOn label="Registration number" />,
  <SearchField source="customId" label="Custom Id" />,
  <SearchField source="name" />,
];

const BusinessList = () => (
  <List
    filters={BusinessFilters}
    empty={false}
    pagination={<Pagination disableEmptyText />}
    exporter={false}
  >
    <Card sx={{ margin: 0 }} variant="outlined">
      <BusinessListBody />
    </Card>
  </List>
);

export default BusinessList;
