import React from 'react';

import {
  AutocompleteArrayInput,
  Create,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { Box } from '@mui/material';
import { permissionRolesChoices } from '../../constants/permissionRoles';

const MemberCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput validate={required()} source="firstName" />
      <TextInput validate={required()} source="lastName" />
      <TextInput validate={required()} source="email" />
      <ReferenceArrayInput source="teams" reference="teams" filter={{ active: true }}>
        <AutocompleteArrayInput
          label="Team"
          filterToQuery={(searchText: string) => ({ name: searchText })}
          optionText="name"
        />
      </ReferenceArrayInput>
      <Box style={{ width: '20%' }}>
        <SelectArrayInput
          source="permissionRoles"
          fullWidth
          validate={required()}
          choices={permissionRolesChoices}
          label="Permission Roles"
        />
      </Box>
    </SimpleForm>
  </Create>
);

export default MemberCreate;
