import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const CUSTOM_ROUTES = {
  KYC_FORMS: 'form-answers/:formId/:kycFormId/:formPage',
  ADD_PING: 'add-ping',
  CONFLICT_RESOLUTION: 'conflict-resolution',
  CONFLICT_RESOLUTION_MANUAL_INPUT: 'conflict-resolution/manual-input',
  CONFLICT_RESOLUTION_RESOLUTION_HISTORY: 'conflict-resolution/resolution-history',
};

const useCustomEditView = () => {
  const [isCustomView, setIsCustomView] = useState(false);
  const { '*': path } = useParams();

  useEffect(() => {
    const isMatchingRoute = (route: string) => {
      const routeParts = route.split('/');
      const pathParts = path?.split('/');

      return routeParts.length === pathParts?.length
             && routeParts.every(
               (part, i) => part.startsWith(':') || part === pathParts[i],
             );
    };

    const matched = Object.values(CUSTOM_ROUTES).some(isMatchingRoute);
    setIsCustomView(matched);
  }, [path]);

  return {
    isCustomView,
  };
};

export default useCustomEditView;
