import React, { useEffect, useMemo } from 'react';

import { useListContext } from 'react-admin';

import pingStatuses from '../../../constants/pingStatuses';

import { BulkUpdate } from '../../ping/components/UpdatePingStatus';

const BulkUpdatePings = ({
  setDisabled,
  open,
  closeDialog,
  setHavePendingPing,
}: {
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  closeDialog: () => void;
  setHavePendingPing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { selectedIds, data } = useListContext();

  const filteredPings = useMemo(
    () => (data ?? []).filter(
      (ping) => ping.status.toLowerCase() === pingStatuses.PENDING,
    ),
    [data],
  );

  const filteredSelections = useMemo(
    () => (selectedIds ?? []).filter((id) => filteredPings.some((ping) => ping.id === id)),
    [filteredPings, selectedIds],
  );

  useEffect(() => {
    if (data) {
      setHavePendingPing(filteredPings.length > 0);
    }
  }, [data, filteredPings.length, setHavePendingPing]);

  useEffect(() => {
    if (filteredPings.length > 0) {
      setDisabled(false);
    }
    if (filteredPings.length < 1) {
      setDisabled(true);
    }
  }, [filteredPings.length, setDisabled]);

  return (
    <BulkUpdate
      selectedIds={filteredSelections}
      open={open}
      closeDialog={closeDialog}
    />
  );
};

export default BulkUpdatePings;
