import { Theme } from '@mui/material';

const components = {
  RaLabeled: {
    styleOverrides: {
      root: {
        '& .RaLabeled-label': {
          fontSize: 'inherit',
        },
      },
    },
  },
  RaSelectInput: {
    styleOverrides: {
      root: {
        minWidth: 200,
        margin: 0,
      },
    },
  },
  RaFormInput: {
    styleOverrides: {
      root: {
        width: 'auto',
      },
    },
  },
  RaDatagrid: {
    defaultProps: {
      bulkActionButtons: false,
    },
    styleOverrides: {
      root: {
        '& .RaDatagrid-rowOdd': {
          backgroundColor: 'rgba(0, 0, 0, 0.02)',
        },
      },
      bulkActionsDisplayed: {
        root: { marginTop: 100 },
      },
    },
  },
  RaList: {
    styleOverrides: {
      root: {
        '& .RaList-main': {
          maxWidth: '100%',
        },
      },
    },
  },
  RaListToolbar: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        [theme.breakpoints.down('sm')]: {
          backgroundColor: 'transparent',
        },
      }),
    },
  },
  RaTopToolbar: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        [theme.breakpoints.down('sm')]: {
          backgroundColor: 'transparent',
        },
      }),
    },
  },
  RaBulkActionsToolbar: {
    styleOverrides: {
      root: {
        top: '0px',
        width: '100%',
        position: 'absolute',
        backgroundColor: 'green',
        marginTop: 2,
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'filled',
      size: 'small',
    },
  },
  MuiTabs: {
    defaultProps: {
      variant: 'scrollable',
      scrollButtons: 'auto',
    },
  },
  MuiFormControl: {
    defaultProps: {
      variant: 'filled',
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  RaAutocompleteInput: {
    styleOverrides: {
      root: {
        '& .RaAutocompleteInput-textField': {
          minWidth: 200,
        },
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      variant: 'filled',
    },
    styleOverrides: {
      root: {
        minWidth: 200,
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'h6',
      },
    },
    styleOverrides: {
      action: {
        marginTop: '-4px',
        marginRight: '-4px',
      },
      root: {
        color: '#238061',
        backgroundColor: '#12413210',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        marginTop: 20,
        marginBottom: 20,
        borderRadius: '6px',
        boxShadow:
            'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
        backgroundImage: 'none',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none',
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: '300',
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: '64px',
      },
    },
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: '6px',
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: 'transparent',
        '& > *': {
          backgroundColor: 'transparent',
        },
      },
      switchHeader: {
        marginTop: '2px',
        marginBottom: '4px',
      },
    },
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        margin: '32px 0 4px',
      },
    },
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: 'calc(50% - 16px)',
        width: '32px',
        height: '32px',
      },
    },
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        '& h4': {
          fontSize: '2.125rem',
          fontWeight: 400,
        },
      },
      timeHeader: {
        '& h3': {
          fontSize: '3rem',
          fontWeight: 400,
        },
      },
    },
  },
  MuiPickersTimePicker: {
    styleOverrides: {
      hourMinuteLabel: {
        '& h2': {
          fontSize: '3.75rem',
          fontWeight: 300,
        },
      },
    },
  },
  MuiPickersToolbar: {
    styleOverrides: {
      toolbar: {
        '& h4': {
          fontSize: '2.125rem',
          fontWeight: 400,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: '200px',
        backgroundColor: '#124131',
      },
      label: {
        color: '#FFFFFF',
        textDeclaration: 'underline',
      },
    },
  },
} as const;

export default components;

declare module '@mui/material/Select' {
  interface SelectClasses {
    root: string;
  }
}
