import React from 'react';

import { isArray, isEmpty } from 'lodash';

import {
  ArrayField,
  Datagrid,
  DateField,
  RecordContextProvider,
  useRecordContext,
  TextField,
  FunctionField,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import useEntityData from '../../../../hooks/useEntityData';

import actions from '../../../../constants/actions';
import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';

import ImageField from '../../../../customFields/ImageField';
import AccordionField from '../../../../customFields/AccordionField';
import { ViewJSONDialog } from '../../../../customFields/ViewJSON';

import NoResults from '../../../layout/NoResults';
import PurchaseButton from '../../../layout/button/PurchaseButton';

import EntityData from './EntityData';

import person from '../../../../assets/exposure/person.png';
import { SanctionListMatches } from '../../../../utilities/schemas/sanctionListMatches';
import { relatedEntityRelationsText } from '../../../../constants/relatedEntityRelations';

// eslint-disable-next-line no-empty-pattern
const SanctionsAccordion = ({}: { label: string }) => {
  const record = useRecordContext<SanctionListMatches['potentialMatches'][number]>();

  if (!record || isEmpty(record?.sanctions)) {
    return null;
  }

  const accordionTitle = record.sanctions
    .map((sanction) => sanction.issuingBody ?? sanction.sanctionType)
    .join(', ');

  return (
    <AccordionField title={accordionTitle}>
      <Box display="flex" gap={2} flexDirection="column">
        {record?.sanctions?.map((sanction, index) => (
          <RecordContextProvider value={sanction} key={JSON.stringify(sanction)}>
            <Box width="fit-content" padding={1}>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>Number:</strong>
                  {' '}
                  {index + 1}
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>Issuing body:</strong>
                  {' '}
                  <TextField source="issuingBody" emptyText="-" />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>Sanction type:</strong>
                  {' '}
                  <TextField source="sanctionType" emptyText="-" />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>Regime:</strong>
                  {' '}
                  <TextField source="regime" emptyText="-" />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>Measures:</strong>
                  {' '}
                  <FunctionField
                    render={(sanctionRecord: SanctionListMatches['potentialMatches'][number]['sanctions'][number]) => {
                      if (!sanctionRecord.measures?.length) return '-';
                      return sanctionRecord.measures.join(', ');
                    }}
                  />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>From date:</strong>
                  {' '}
                  <DateField source="fromDate" emptyText="-" />
                </Typography>
              </Box>
              <Box display="flex" gap={1}>
                <Typography>
                  <strong>Country:</strong>
                  <TextField source="country" emptyText="-" />
                </Typography>
              </Box>
            </Box>
          </RecordContextProvider>
        ))}
      </Box>
    </AccordionField>
  );
};

const SanctionListBody = ({
  data,
  handlePurchase,
  isLoading,
}: {
  data: SanctionListMatches | undefined,
  handlePurchase: () => void
  isLoading: boolean
}) => (
  <RecordContextProvider value={data}>
    <Card variant="outlined">
      <CardHeader
        title="Sanction List"
        subheader={(
          <Box display="flex" alignItems="baseline">
            <Typography>Latest update:</Typography>
            <DateField sx={{ marginLeft: 1, fontWeight: 'bold' }} source="createdAt" emptyText="-" showTime />
          </Box>
        )}
        action={(
          <PurchaseButton
            loading={isLoading}
            label="Get Sanction List"
            onClick={handlePurchase}
            empty={!isArray(data?.potentialMatches)}
            action={actions.INDIVIDUAL_CREATE_SANCTION_LIST}
          />
        )}
      />
      <Divider />
      <ArrayField source="potentialMatches">
        <Datagrid empty={<NoResults variant="h6" />} sx={{ ...boldDataGridStyle, width: '100%' }} bulkActionButtons={false}>
          <FunctionField
            label="Names"
            sx={{ whiteSpace: 'pre' }}
            render={(record: SanctionListMatches['potentialMatches'][number]) => {
              const { firstName, lastName, fullName } = record;
              if (!firstName && !lastName && !fullName) return '-';
              const names = [{ firstName, lastName, fullName }, ...record.otherNames];
              return names
                .map((name) => name.fullName ?? [
                  name.firstName,
                  name.lastName,
                ].filter((item) => !!item).join(' '))
                .slice(0, 5).join('\n');
            }}
          />
          <TextField source="gender" emptyText="-" />
          <DateField source="birthDate" label="Birth date" showTime={false} emptyText="-" />
          <FunctionField
            label="Addresses"
            sx={{ whiteSpace: 'pre' }}
            render={(record: SanctionListMatches['potentialMatches'][number]) => {
              if (!record.address) return '-';
              const addresses = [record.address, ...record.otherAddresses];
              return addresses
                .map((address) => address.description ?? [
                  address.streetAddress,
                  address.additionalAddress,
                  address.postcode,
                  address.city,
                  address.state,
                  address.country,
                ].filter((item) => !!item).join(', '))
                .slice(0, 5).join('\n');
            }}
          />
          <FunctionField
            label="Nationalities"
            render={(record: SanctionListMatches['potentialMatches'][number]) => {
              if (record.nationalities.length === 0) return '-';
              return record.nationalities.join(', ');
            }}
          />
          <SanctionsAccordion label="Sanctions" />
          <FunctionField
            label="Relations"
            sx={{ whiteSpace: 'pre' }}
            render={(record: SanctionListMatches['potentialMatches'][number]) => {
              if (record.relatedEntities.length === 0) return '-';
              return record.relatedEntities
                .map((relatedEntity) => [
                  relatedEntity.entityName,
                  relatedEntity.relationshipType
                    ? relatedEntityRelationsText[relatedEntity.relationshipType] : undefined,
                ].filter((text) => !!text).join(' - '))
                .slice(0, 5).join('\n');
            }}
          />
          <ImageField label="Image" source="profileImages[0]" emptyImage={person} />
          <ViewJSONDialog title="Inspect raw data" />
        </Datagrid>
      </ArrayField>
    </Card>
  </RecordContextProvider>
);

const SanctionList = () => {
  const {
    data,
    handlePurchase,
    isLoading,
  } = useEntityData<SanctionListMatches>({
    source: 'sanction-list',
    getAction: actions.INDIVIDUAL_GET_SANCTION_LIST,
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <EntityData />
      </Grid>
      <Grid item xs={12} md={12}>
        <SanctionListBody data={data} handlePurchase={handlePurchase} isLoading={isLoading} />
      </Grid>
    </Grid>
  );
};

export default SanctionList;
