import React from 'react';
import { Grid } from '@mui/material';
import {
  NumberInput,
  SelectInput, minValue, required,
} from 'react-admin';

import timePeriods from '../../../../constants/timePeriods';
import Label from '../../../../customFields/Label';

const SimpleAnomalyDetectionWithoutWindow = () => (
  <Grid item xs={12} md={12}>
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <NumberInput source="ruleParameters.threshold" fullWidth label="Deviation (%)" />
      </Grid>
      <Grid item xs={12} md={12}>
        <NumberInput source="ruleParameters.minimumNumberOfObservations" fullWidth />
      </Grid>

      <Grid item xs={12} md={12}>
        <Label
          variant="label"
          title="Minimum usage time"
          info="The period will determine if a entity have been in the system long enough to be used."
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput
          source="ruleParameters.minimumUsageTime.amount"
          fullWidth
          label="Length"
          validate={[required(), minValue(1)]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectInput
          source="ruleParameters.minimumUsageTime.unit"
          choices={Object.values(timePeriods).map((period) => ({ id: period, name: period }))}
          fullWidth
          label="Period"
          validate={[required(), minValue(1)]}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Label
          variant="label"
          title="Period to consider"
          info="The period that will be used to determine the start of the history to include."
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput
          source="ruleParameters.periodToConsider.amount"
          fullWidth
          label="Length"
          validate={[required(), minValue(1)]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectInput
          source="ruleParameters.periodToConsider.unit"
          choices={Object.values(timePeriods).map((period) => ({ id: period, name: period }))}
          fullWidth
          label="Period"
          validate={[required(), minValue(1)]}
        />
      </Grid>
    </Grid>
  </Grid>
);

export default SimpleAnomalyDetectionWithoutWindow;
