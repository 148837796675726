import React from 'react';

import { isArray, isEmpty, uniq } from 'lodash';

import {
  ArrayField,
  Datagrid,
  DateField,
  RecordContextProvider,
  useRecordContext,
  TextField,
  SelectField,
  FunctionField,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import useEntityData from '../../../../hooks/useEntityData';

import actions from '../../../../constants/actions';
import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';

import ImageField from '../../../../customFields/ImageField';
import AccordionField from '../../../../customFields/AccordionField';
import { ViewJSONDialog } from '../../../../customFields/ViewJSON';

import NoResults from '../../../layout/NoResults';
import PurchaseButton from '../../../layout/button/PurchaseButton';

import EntityData from './EntityData';

import person from '../../../../assets/exposure/person.png';
import { PepMatches } from '../../../../utilities/schemas/pepMatches';
import { pepRelationChoices } from '../../../../constants/pepRelations';
import { pepRoleChoices } from '../../../../constants/pepRoles';
import { relatedEntityRelationsText } from '../../../../constants/relatedEntityRelations';

// eslint-disable-next-line no-empty-pattern
const RolesAccordion = ({}: { label: string }) => {
  const record = useRecordContext<PepMatches['potentialMatches'][number]>();

  if (isEmpty(record?.pepRoles)) {
    return null;
  }

  const accordionTitle = record?.pepRoles
    .map((role) => role.roleDescription ?? role.role)
    .join(', ') ?? '';

  return (
    <AccordionField title={accordionTitle} fullWidth>
      <Box display="flex" gap={2} flexDirection="column">
        {record?.pepRoles?.map((role, index) => (
          <RecordContextProvider value={role} key={JSON.stringify(role)}>
            <Box width="fit-content" padding={1}>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>Number:</strong>
                  {' '}
                  {index + 1}
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>Role type:</strong>
                  {' '}
                  <SelectField source="role" emptyText="-" choices={pepRoleChoices('en')} />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>Role description:</strong>
                  {' '}
                  <TextField source="roleDescription" emptyText="-" />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>Country:</strong>
                  {' '}
                  <TextField source="country" emptyText="-" />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>Relation type:</strong>
                  {' '}
                  <SelectField source="relation" emptyText="-" choices={pepRelationChoices('en')} />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>Relation description:</strong>
                  {' '}
                  <TextField source="relationDescription" emptyText="-" />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>From:</strong>
                  {' '}
                  <DateField source="fromDate" emptyText="-" />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>To:</strong>
                  {' '}
                  <DateField source="toDate" emptyText="-" />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>Organization:</strong>
                  {' '}
                  <TextField source="organization" emptyText="-" />
                </Typography>
              </Box>
            </Box>
          </RecordContextProvider>
        ))}
      </Box>
    </AccordionField>
  );
};

const PepBody = ({
  data,
  handlePurchase,
  isLoading,
}: {
  data: PepMatches | undefined,
  handlePurchase: () => void
  isLoading: boolean
}) => (
  <RecordContextProvider value={data}>
    <Card variant="outlined">
      <CardHeader
        title="Politically Exposed Person (PEP) Records"
        subheader={(
          <Box display="flex" alignItems="baseline">
            <Typography>Latest update:</Typography>
            <DateField sx={{ marginLeft: 1, fontWeight: 'bold' }} source="createdAt" emptyText="-" showTime />
          </Box>
        )}
        action={(
          <PurchaseButton
            loading={isLoading}
            label="Get PEP Records"
            onClick={handlePurchase}
            empty={!isArray(data?.potentialMatches)}
            action={actions.INDIVIDUAL_CREATE_PEP}
          />
        )}
      />
      <Divider />
      <ArrayField source="potentialMatches">
        <Datagrid empty={<NoResults variant="h6" />} sx={{ ...boldDataGridStyle, width: '100%' }} bulkActionButtons={false}>
          <FunctionField
            label="Names"
            sx={{ whiteSpace: 'pre' }}
            render={(record: PepMatches['potentialMatches'][number]) => {
              const { firstName, lastName, fullName } = record;
              if (!firstName && !lastName && !fullName) return '-';
              const names = [{ firstName, lastName, fullName }, ...record.otherNames];
              return names
                .map((name) => name.fullName ?? [
                  name.firstName,
                  name.lastName,
                ].filter((item) => !!item).join(' '))
                .slice(0, 5).join('\n');
            }}
          />
          <FunctionField
            label="National identifier"
            render={(record: PepMatches['potentialMatches'][number]) => {
              if (record.nationalIdentifiers.length === 0) return '-';
              return record.nationalIdentifiers.map((identifier) => identifier.identifier).join(', ');
            }}
          />
          <FunctionField
            label="Addresses"
            sx={{ whiteSpace: 'pre' }}
            render={(record: PepMatches['potentialMatches'][number]) => {
              if (!record.address) return '-';
              const addresses = [record.address, ...record.otherAddresses];
              return addresses
                .map((address) => address.description ?? [
                  address.streetAddress,
                  address.additionalAddress,
                  address.postcode,
                  address.city,
                  address.state,
                  address.country,
                ].filter((item) => !!item).join(', '))
                .slice(0, 5).join('\n');
            }}
          />
          <FunctionField
            label="PEP countries"
            render={(record: PepMatches['potentialMatches'][number]) => {
              if (record.pepRoles.length === 0) return '-';
              return uniq(record.pepRoles.map((role) => role.country)).join(', ');
            }}
          />
          <RolesAccordion label="Roles" />
          <FunctionField
            label="Relations"
            sx={{ whiteSpace: 'pre' }}
            render={(record: PepMatches['potentialMatches'][number]) => {
              if (record.relatedEntities.length === 0) return '-';
              return record.relatedEntities
                .map((relatedEntity) => [
                  relatedEntity.entityName,
                  relatedEntity.relationshipType
                    ? relatedEntityRelationsText[relatedEntity.relationshipType] : undefined,
                ].filter((text) => !!text).join(' - '))
                .slice(0, 5).join('\n');
            }}
          />
          <ImageField label="Image" source="profileImages[0]" emptyImage={person} />
          <ViewJSONDialog title="Inspect raw data" />
        </Datagrid>
      </ArrayField>
    </Card>
  </RecordContextProvider>
);

const Pep = () => {
  const {
    data,
    handlePurchase,
    isLoading,
  } = useEntityData<PepMatches>({
    source: 'pep',
    getAction: actions.INDIVIDUAL_GET_PEP,
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <EntityData />
      </Grid>
      <Grid item xs={12} md={12}>
        <PepBody data={data} handlePurchase={handlePurchase} isLoading={isLoading} />
      </Grid>
    </Grid>
  );
};

export default Pep;
