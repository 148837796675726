import React from 'react';

import {
  TextField,
  ReferenceManyField,
  ReferenceField,
  SingleFieldList,
  ChipField,
  useRecordContext,
  useGetList,
} from 'react-admin';

import {
  Box,
  Typography,
} from '@mui/material';

const MergedField = () => {
  const record = useRecordContext();

  const { data } = useGetList('cases', { filter: { mergedInto: record?.mergedInto } }, { enabled: !!record?.mergedInto });

  if (record?.mergedInto) {
    return (
      <Box>
        <Typography variant="smallTitle">Merged Into</Typography>
        <ReferenceField source="mergedInto" reference="cases">
          <TextField source="id" />
        </ReferenceField>
      </Box>
    );
  }

  if (!data) return null;

  return (
    <Box>
      <Typography variant="smallTitle">Merged from:</Typography>
      <ReferenceManyField label="Comments by" reference="cases" target="mergedInto">
        <SingleFieldList>
          <ChipField source="id" />
        </SingleFieldList>
      </ReferenceManyField>
    </Box>
  );
};

export default MergedField;
