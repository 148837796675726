import React from 'react';
import { TextField, useRecordContext } from 'react-admin';
import { get, isEmpty } from 'lodash';
import { Typography } from '@mui/material';

import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import conflictFields from '../../../../constants/conflictFields';

const VALUE_LABEL = {
  [languages.SV]: 'Brutto månadsinkomst från angiven förmögenhetskälla',
  [languages.EN]: 'Gross monthly income of provided source of wealth',
};

const unitToDenominator = {
  day: 1 / 30,
  month: 1,
  year: 12,
} as const;

const getCoefficientFromPeriod = (unit?: 'day' | 'month' | 'year', period?: number) => {
  if (!unit && !period) return 1;
  return (unitToDenominator[unit ?? 'month']) / (period ?? 1);
};

const ValueField = ({
  extraParameters,
}: {
  extraParameters?: {
    label?: string,
    unit?: 'day' | 'month' | 'year';
    period?: number;
  }
  language?: Languages;
}) => {
  const record = useRecordContext();

  const valueObj = get(record, 'value');
  const sx = get(record, 'sx', {});

  if (isEmpty(valueObj)) return <Typography>-</Typography>;

  const value = `${valueObj.value * getCoefficientFromPeriod(extraParameters?.unit, extraParameters?.period)} ${valueObj.currency}`;

  return <TextField record={{ value }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const SourceOfWealthAmount = ({ extraParameters }: {
  extraParameters?: {
    label?: string,
    unit?: 'day' | 'month' | 'year';
    period?: number;
  }
}) => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={extraParameters?.label ?? VALUE_LABEL[language]}
      source="sourceOfWealthAmount"
      datapoint={conflictFields.INCOME}
    >
      <ValueField extraParameters={extraParameters} />
    </Answer>

  );
};

SourceOfWealthAmount.ValueField = ValueField;

export default SourceOfWealthAmount;
