import React from 'react';

import { TextField, useRecordContext } from 'react-admin';

import { get, isEmpty, keyBy } from 'lodash';

import { Typography } from '@mui/material';

import Answer from '../Answer';

import { QuestionElementTypes } from '../../../../constants/questionElementTypes';
import { Languages } from '../../../../constants/languages';
import conflictFields from '../../../../constants/conflictFields';

const ValueField = ({
  extraParameters,
}: {
  extraParameters?: {
    label?: string,
    options?: { id: string, label: string }[]
    type?: QuestionElementTypes
  }
  language?: Languages;
}) => {
  const record = useRecordContext();

  const options = get(record, 'value.options');
  const other = get(record, 'value.other');
  const sx = get(record, 'sx', {});

  if (isEmpty(options)) return <Typography>-</Typography>;

  const mapFields = keyBy(
    extraParameters?.options?.map((option) => ({ id: option.id, name: option.label })),
    'id',
  );

  const mappedValues = options?.map((v: string) => get(mapFields, v, { name: v }).name);
  if (other) mappedValues.push(other);

  const value = mappedValues.join(', ');

  return <TextField record={{ value }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const SourceOfWealth = ({
  extraParameters,
}: {
  extraParameters?: {
    label?: string,
    options?: { id: string, label: string }[]
    type?: QuestionElementTypes
  }
}) => (
  <Answer
    label={extraParameters?.label ?? ''}
    source="sourceOfWealth"
    datapoint={conflictFields.SOURCE_OF_WEALTH}
  >
    <ValueField extraParameters={extraParameters} />
  </Answer>
);

SourceOfWealth.ValueField = ValueField;

export default SourceOfWealth;
