import React from 'react';
import {
  Edit,
  TextField,
  DateField,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Datagrid,
  SaveButton,
  Toolbar,
  Pagination,
  usePermissions,
} from 'react-admin';

import { useFormState } from 'react-hook-form';

import {
  Box,
  Grid,
  Card,
  Typography,
  Container,
} from '@mui/material';

import ReferenceMemberField from '../../customFields/ReferenceMemberField';

import hasAccess from '../../utilities/hasAccess';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import actions, { Actions } from '../../constants/actions';

import ActivationButton from '../../customFields/ActivationButton';

import TeamBreadcrumb from './TeamBreadcrumb';
import ColorPicker from '../layout/ColorPicker';
import TeamMembers, { transform } from './TeamMembers';
import useValidateId from '../../hooks/useValidateId';

const CustomToolbar = () => {
  const { isDirty } = useFormState();
  if (!isDirty) {
    return null;
  }
  return (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
};

const TeamEdit = () => {
  const { permissions } = usePermissions<Actions[]>();

  const validatedId = useValidateId();
  if (!validatedId.isValid) return null;

  return (
    <Edit mutationMode="pessimistic" redirect={false} transform={transform}>
      <TeamBreadcrumb />
      <TabbedForm mode="all" toolbar={hasAccess(permissions, actions.TEAM_UPDATE) ? <CustomToolbar /> : false}>
        <FormTab label="Information">
          <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" flexDirection="column">
                  <Typography variant="label">ID</Typography>
                  <TextField source="id" />
                </Box>
                <Box display="flex">
                  {hasAccess(permissions, actions.TEAM_UPDATE_ACTIVATION) && (
                    <Box display="flex" marginLeft={2}>
                      <ActivationButton />
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box display="flex" flexDirection="column">
                <Typography variant="label">Name</Typography>
                <TextField source="name" />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <ColorPicker />
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label="Members">
          <TeamMembers />
        </FormTab>
        {hasAccess(permissions, actions.AUDIT_TRACK_LIST) && (
          <FormTab label="Audit Tracks">
            <Container maxWidth={false}>
              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <ReferenceManyField
                    reference="audit-tracks"
                    target="resource.id"
                    sort={{ field: 'date', order: 'DESC' }}
                    pagination={<Pagination />}
                  >
                    <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                      <TextField sortable={false} source="id" />
                      <ReferenceMemberField label="User" />
                      <TextField sortable={false} source="description" />
                      <DateField sortable source="date" showTime />
                    </Datagrid>
                  </ReferenceManyField>
                </Card>
              </Grid>
            </Container>
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};

export default TeamEdit;
