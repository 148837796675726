import React, { useCallback } from 'react';
import {
  Button,
  useRefresh,
  useNotify,
  useUnselectAll,
  useCreate,
  useListContext,
} from 'react-admin';

import CallMergeIcon from '@mui/icons-material/CallMerge';

const MergeCases = () => {
  const { selectedIds } = useListContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll('cases');

  const [updateMany, { isLoading }] = useCreate();

  const handleClick = useCallback(() => updateMany(
    'cases/merge',
    { data: { selectedIds } },
    {
      onSuccess: () => {
        unselectAll();
        refresh();
        notify('Cases merged');
      },
      onError: () => {
        notify('Error: Could not merge cases', { type: 'warning' });
      },
    },
  ), [notify, refresh, selectedIds, unselectAll, updateMany]);

  return (
    <Button
      label="Merge"
      disabled={isLoading}
      onClick={handleClick}
      variant="text"
    >
      <CallMergeIcon />
    </Button>
  );
};

export default MergeCases;
