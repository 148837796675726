import React from 'react';

import {
  Datagrid,
  DateField,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from 'react-admin';

import {
  Dialog,
  Button,
  DialogContent,
  Card,
  CardHeader,
  Divider,
  Typography,
  Box,
  IconButton,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import ClearIcon from '@mui/icons-material/Clear';

import useDialogStatus from '../../hooks/useDialogStatus';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';

const SourceField = ({ label }: {label?: string}) => {
  const record = useRecordContext();

  if (record?.createdBy) {
    return <TextField label={label} source="createdBy" emptyText="-" />;
  }
  return <TextField label={label} source="provider" emptyText="-" />;
};

const ConflictResolutionSources = () => {
  const { open, openDialog, closeDialog } = useDialogStatus();

  return (
    <>
      <Button onClick={openDialog} startIcon={<VisibilityIcon />}>View</Button>
      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="lg">
        <Box display="flex" alignItems="center" justifyContent="space-between" pt={6} px={6}>
          <Typography variant="h5">Sources details</Typography>
          <IconButton onClick={closeDialog}>
            <ClearIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Card variant="outlined">
            <CardHeader title="Sources" sx={{ backgroundColor: 'greenGray.main' }} />
            <Divider />
            <ReferenceManyField reference="data-point-sources" target="datapointId">
              <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                <DateField source="createdAt" showTime label="Timestamp" emptyText="-" />
                <TextField source="comment" emptyText="-" />
                <TextField source="type" emptyText="-" />
                <SourceField label="Source" />
              </Datagrid>
            </ReferenceManyField>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConflictResolutionSources;
