import React from 'react';

import {
  Datagrid,
  TextField,
  List,
  ArrayField,
  SingleFieldList,
  SelectInput,
} from 'react-admin';

import {
  Card,
} from '@mui/material';

import NoResults from '../layout/NoResults';
import Pagination from '../layout/Pagination';
import SearchField from '../layout/SearchField';

import ReferenceEntityField from '../../customFields/ReferenceEntityField';
import ColoredScoreField from '../../customFields/ColoredScoreField';
import LinkField from '../layout/LinkField';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import { countryChoices } from '../../constants/countries';
import languages from '../../constants/languages';
import { IAccountEntity } from '../../utilities/schemas/accountEntity';

export const AccountEntityListBody = ({ children }: {children?: React.ReactElement}) => (
  <Datagrid bulkActionButtons={false} sx={boldDataGridStyle} empty={<NoResults variant="h6" />}>
    <LinkField<IAccountEntity> sortable={false} source="id" />
    <TextField<IAccountEntity> sortable={false} source="customId" emptyText="-" />
    <TextField<IAccountEntity> sortable={false} source="accountName" emptyText="-" />
    <TextField<IAccountEntity> sortable={false} source="iban" label="IBAN" emptyText="-" />
    <ArrayField<IAccountEntity> sortable={false} source="connectedEntities">
      <SingleFieldList linkType={false}>
        <ReferenceEntityField source="" chip />
      </SingleFieldList>
    </ArrayField>
    <ColoredScoreField<IAccountEntity> source="riskScore" />
    {children}
  </Datagrid>
);

export const AccountEntityFilters = [
  <SearchField source="customId" alwaysOn label="Custom id" />,
  <SearchField source="bban" alwaysOn label="BBAN" />,
  <SearchField source="iban" alwaysOn label="IBAN" />,
  <SelectInput choices={countryChoices(languages.EN)} source="country" label="Country" />,
  <SearchField source="bic" label="BIC" />,
  <SearchField source="bankgiroNumber" label="Bankgiro" />,
  <SearchField source="plusgiroNumber" label="Plusgiro" />,
];

const AccountEntityList = () => (
  <List<IAccountEntity>
    sort={{ field: 'createdAt', order: 'DESC' }}
    empty={false}
    pagination={<Pagination disableEmptyText />}
    filters={AccountEntityFilters}
    exporter={false}
  >
    <Card sx={{ margin: 0 }} variant="outlined">
      <AccountEntityListBody />
    </Card>
  </List>
);

export default AccountEntityList;
