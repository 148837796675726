import React, { useCallback } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';

import ArrowBackIosFilled from '@mui/icons-material/ArrowBackIos';

import {
  Form,
  useCreate,
  useNotify,
  useResourceContext,
} from 'react-admin';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FieldValues } from 'react-hook-form';

import conflictFieldsMap from './conflictFieldsMap';
import { ConflictFields } from '../../constants/conflictFields';
import { entityTypesFromResource } from '../../constants/entityTypes';
import conflictFieldsValidationMap from './conflictFieldsValidationMap';

const ConflictResolutionManualInput = () => {
  const { id } = useParams();
  const resource = useResourceContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [create] = useCreate();

  const name = searchParams.get('name') as ConflictFields;

  const handelBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const notify = useNotify();

  const handleSubmit = useCallback((data: FieldValues) => {
    create('data-points', {
      data: {
        entity: {
          id,
          type: entityTypesFromResource[resource],
        },
        name,
        value: data.value,
        comment: data.comment,
        type: 'manual',
        selected: true,
      },
    }, {
      onSuccess: () => {
        handelBack();
      },
      onError: () => {
        notify('Could not save manual input. If the problem persists, reach out to our support.', { type: 'error' });
      },
    });
  }, [create, handelBack, id, name, notify, resource]);

  return (
    <Slide in direction="right">
      <Grid container gap={1}>
        <Grid item xs={12} md={12} mb={3}>
          <Box display="flex" alignItems="center">
            <IconButton size="medium" onClick={handelBack}>
              <ArrowBackIosFilled fontSize="medium" color="primary" />
            </IconButton>
            <Typography color="primary" variant="h5">Back</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12} md={12} sx={{ backgroundColor: 'background.paper' }}>
              <Card
                sx={{
                  m: 0,
                  p: 2,
                  borderRadius: 1,
                }}
                variant="outlined"
              >
                <CardContent>
                  <Form
                    onSubmit={handleSubmit}
                    resolver={conflictFieldsValidationMap[name]}
                    record={{}}
                  >
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={12}>
                        {React.createElement(conflictFieldsMap[name])}
                      </Grid>
                      <Grid item xs={12} md={12} display="flex" justifyContent="center">
                        <Box display="flex" gap={8}>
                          <Button onClick={handelBack}>Cancel</Button>
                          <Button variant="contained" type="submit">Save</Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default ConflictResolutionManualInput;
