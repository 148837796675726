import React, { useCallback } from 'react';
import {
  Edit,
  TextField,
  DateField,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Datagrid,
  useNotify,
  required,
  email,
  SaveButton,
  Toolbar,
  useGetIdentity,
  usePermissions,
  useCreate,
  maxLength,
  regex,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';

import { useFormState } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  Box,
  Grid,
  Card,
  Typography,
  Button,
  CircularProgress,
  Container,
} from '@mui/material';

import ReferenceMemberField from '../../customFields/ReferenceMemberField';
import ReferenceResourceField from '../../customFields/ReferenceResourceField';

import hasAccess from '../../utilities/hasAccess';

import { permissionRolesChoices } from '../../constants/permissionRoles';
import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import ActivationButton from '../../customFields/ActivationButton';
import countries from '../../constants/countries';
import MemberBreadcrumb from './MemberBreadcrumb';
import Pagination from '../layout/Pagination';
import EditField from '../../customFields/EditField';
import EditSelectInput from '../../customFields/EditSelectInput';
import { Actions } from '../../constants/actions';
import useValidateId from '../../hooks/useValidateId';

const CustomToolbar = () => {
  const { isDirty } = useFormState();
  if (!isDirty) {
    return null;
  }
  return (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
};

const MemberEdit = () => {
  const { permissions } = usePermissions<Actions[]>();
  const { id } = useParams();
  const notify = useNotify();
  const [initiateResetPassword, { isLoading }] = useCreate(
    `members/${id}/initiate-reset-password`,
    { data: undefined },
    {
      onSuccess: () => {
        notify('Password reset email sent successfully');
      },
      onError: () => {
        notify('Could not send password reset email', { type: 'error' });
      },
    },
  );

  const handleClick = useCallback(() => {
    initiateResetPassword();
  }, [initiateResetPassword]);

  const { identity } = useGetIdentity();

  const validatedId = useValidateId();
  if (!validatedId.isValid) return null;

  return (
    <Edit mutationMode="pessimistic" redirect={false}>
      <MemberBreadcrumb />
      <TabbedForm mode="all" toolbar={hasAccess(permissions, 'MemberUpdate') ? <CustomToolbar /> : false}>

        <FormTab label="Information">

          <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
              <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap={4}>
                <Box flexDirection="column" display="flex">
                  <Typography variant="smallTitle">Id</Typography>
                  <TextField source="id" />
                </Box>
                <Box display="flex">
                  {hasAccess(permissions, 'MemberInitiateRest') && (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={isLoading && <CircularProgress size={14} />}
                      onClick={handleClick}
                      disabled={isLoading}
                    >
                      Reset password
                    </Button>
                  )}
                  {id !== identity?.id && hasAccess(permissions, 'MemberUpdateActivation') && (
                    <Box display="flex" marginLeft={2}>
                      <ActivationButton />
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <EditField
                showEdit
                source="firstName"
                label="First name"
                validate={required()}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <EditField
                showEdit
                source="lastName"
                label="Last name"
                validate={required()}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <EditField
                showEdit
                source="email"
                label="Email"
                validate={[email(), required()]}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <EditField
                showEdit
                source="occupation"
                label="Occupation"
                validate={[maxLength(255)]}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <EditField
                showEdit
                source="birthDate"
                label="Birth date"
                type="date"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <EditField
                showEdit
                source="swedishSocialSecurityNumber"
                label="Swedish social security number"
                validate={[regex(/^[0-9]{12}$/, 'yyyymmddxxxx')]}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <EditSelectInput
                choices={countries}
                showEdit
                autocomplete
                label="Residence"
                source="residence"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <EditField
                showEdit
                source="foreignId"
                label="Foreign ID"
                validate={[maxLength(25)]}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <EditSelectInput
                choices={countries}
                showEdit
                autocomplete
                source="foreignIdCountry"
                label="Foreign ID country"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <EditSelectInput
                source="permissionRoles"
                showEdit
                validate={required()}
                choices={permissionRolesChoices}
                label="Permission Roles"
                multiple
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ReferenceArrayInput source="teams" reference="teams" filter={{ active: true }}>
                <AutocompleteArrayInput
                  label="Team"
                  filterToQuery={(searchText: string) => ({ name: searchText })}
                  optionText="name"
                />
              </ReferenceArrayInput>
            </Grid>
          </Grid>
        </FormTab>
        {hasAccess(permissions, 'AuditTrackList') && (
          <FormTab label="Audit Tracks">
            <Container maxWidth={false}>
              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <ReferenceManyField
                    reference="audit-tracks"
                    target="resource.id"
                    sort={{ field: 'date', order: 'DESC' }}
                    perPage={15}
                    pagination={<Pagination disableEmptyText />}
                  >
                    <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                      <TextField sortable={false} source="id" />
                      <ReferenceMemberField label="User" />
                      <TextField sortable={false} source="description" />
                      <DateField sortable source="date" showTime />
                    </Datagrid>
                  </ReferenceManyField>
                </Card>
              </Grid>
            </Container>
          </FormTab>
        )}
        <FormTab label="Activity">
          <Container maxWidth={false}>
            <Grid item xs={12} md={12}>
              <Card variant="outlined">
                <ReferenceManyField
                  reference="audit-tracks"
                  target="authenticator.id"
                  sort={{ field: 'date', order: 'DESC' }}
                  perPage={15}
                  pagination={<Pagination disableEmptyText />}
                >
                  <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                    <TextField sortable={false} source="id" />
                    <TextField sortable={false} source="description" />
                    <ReferenceResourceField label="Resource" />
                    <DateField sortable source="date" showTime />
                  </Datagrid>
                </ReferenceManyField>
              </Card>
            </Grid>
          </Container>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default MemberEdit;
