import conflictFields, { ConflictFields } from '../../constants/conflictFields';

const conflictFieldsLabel: Record<ConflictFields, string> = {
  [conflictFields.FIRST_NAME]: 'Firstname',
  [conflictFields.LAST_NAME]: 'Lastname',
  [conflictFields.COMPANY_NAME]: 'Company name',
  [conflictFields.REGISTRATION_NUMBER]: 'Company registration number',
  [conflictFields.TIN]: 'Tax identification number (TIN)',
  [conflictFields.CITY]: 'City',
  [conflictFields.RESIDENCE]: 'Country of residence',
  [conflictFields.EMAIL]: 'Email',
  [conflictFields.POSTAL_CODE]: 'Postcode',
  [conflictFields.WEBSITE]: 'Website',
  [conflictFields.PHONE_NUMBER]: 'Phone number',
  [conflictFields.ADDRESS]: 'Address',
  [conflictFields.SIGNATORIES]: 'Signatories',
  [conflictFields.BENEFICIAL_OWNER]: 'Beneficial owner',
  [conflictFields.BENEFICIAL_OWNERS]: 'Beneficial owners',
  [conflictFields.ALTERNATIVE_BENEFICIAL_OWNERS]: 'Alternative beneficial owners',
  [conflictFields.OWNER_STRUCTURE]: 'Owner structure',
  [conflictFields.LEGAL_FORM]: 'Legal form of business',
  [conflictFields.IS_FINANCIAL_INSTITUTION]: 'Is it a financial institution?',
  [conflictFields.INDUSTRY]: 'Industry',
  [conflictFields.ACTIVITIES]: 'Activities',
  [conflictFields.TRANSACTION_MONITORING_THRESHOLD]: 'Transaction monitoring thresholds',
  [conflictFields.SIGNER]: 'Signer',
  [conflictFields.PURPOSE]: 'Purpose',
  [conflictFields.PRODUCTS]: 'Products',
  [conflictFields.TAX_RESIDENCY]: 'Tax residency',
  [conflictFields.PEP]: 'PEP',
  [conflictFields.CITIZENSHIP]: 'Citizenship',
  [conflictFields.EMPLOYMENT_STATUS]: 'Employment status',
  [conflictFields.INCOME]: 'Monthly income',
  [conflictFields.SOURCE_OF_FUNDS]: 'Source of funds',
  // [conflictFields.PERSONAL_NUMBER]: 'Personal number',
  [conflictFields.ROLE]: 'Role',
  [conflictFields.STATE_OWNED]: 'Is state owned?',
  [conflictFields.FORECASTED_TURNOVER]: 'Forecasted yearly turnover',
  [conflictFields.PREVIOUS_TURNOVER]: 'Previous yearly turnover',
  [conflictFields.LISTED_FINANCIAL_MARKET]: 'Is listed on financial market?',
  [conflictFields.DATE_OF_BIRTH]: 'Date of birth',
  [conflictFields.SOURCE_OF_WEALTH]: 'Source of wealth',
  [conflictFields.MIDDLE_NAME]: 'Middle name',
  [conflictFields.OTHER_LEGAL_FORM]: 'Other legal form of business',
  [conflictFields.OPERATION_START_DATE]: 'Date the business started operating',
} as const;

export default conflictFieldsLabel;
