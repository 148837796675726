import React, { useState } from 'react';

import {
  List,
  Datagrid,
  TextField,
  usePermissions,
  SingleFieldList,
  ChipField,
  ArrayField,
  useRecordContext,
  SelectField,
  ReferenceArrayField,
} from 'react-admin';

import { Card, Divider } from '@mui/material';

import TeamChip from '../../customFields/TeamChip';

import hasAccess from '../../utilities/hasAccess';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import { permissionRolesChoices } from '../../constants/permissionRoles';

import { SubListNavigation } from '../layout/SubNavigation';
import { StyledTab, StyledTabs } from '../layout/Tab';
import Pagination from '../layout/Pagination';
import NoResults from '../layout/NoResults';
import LinkField from '../layout/LinkField';
import { Actions } from '../../constants/actions';

const MemberChip = () => {
  const role = useRecordContext<string>();
  return <ChipField record={{ role: permissionRolesChoices.find((permissionRole) => permissionRole.id === role)?.name }} source="role" />;
};

export const MemberListBody = ({
  isMemberResourceView = false,
  CustomButton,
}: {
  isMemberResourceView?: boolean
  CustomButton?: React.ReactElement
}) => {
  const { permissions } = usePermissions<Actions[]>();

  return (
    <Datagrid sx={boldDataGridStyle} bulkActionButtons={false} empty={<NoResults variant="h6" />}>
      {(isMemberResourceView && hasAccess(permissions, 'MemberGet')) && <LinkField sortable={false} source="id" />}
      {!(isMemberResourceView && hasAccess(permissions, 'MemberGet')) && <TextField sortable={false} source="id" />}
      <TextField sortable={false} source="firstName" />
      <TextField sortable={false} source="lastName" />
      <TextField sortable={false} source="email" />
      <ArrayField sortable={false} source="permissionRoles" label="Permission roles">
        <SingleFieldList linkType={false}>
          <MemberChip />
        </SingleFieldList>
      </ArrayField>
      {isMemberResourceView && (
        <ReferenceArrayField sortable={false} source="teams" reference="teams" label="Teams">
          <SingleFieldList>
            <TeamChip />
          </SingleFieldList>
        </ReferenceArrayField>
      )}
      {CustomButton ?? CustomButton}
      {isMemberResourceView && <SelectField sortable={false} choices={[{ id: true, name: 'Active' }, { id: false, name: 'Inactive' }]} source="active" label="status" />}
    </Datagrid>
  );
};

const MemberList = () => {
  const [activationTab, setActivationTab] = useState(0);
  const [active, setActive] = useState(true);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActivationTab(() => newValue);
    setActive(() => newValue === 0);
  };
  return (
    <List
      filter={{ active }}
      empty={false}
      pagination={<Pagination disableEmptyText />}
      exporter={false}
    >
      <Card sx={{ margin: 0 }} variant="outlined">
        <Card sx={{ margin: 0 }}>
          <SubListNavigation>
            <StyledTabs value={activationTab} onChange={handleChange}>
              <StyledTab label="Active " />
              <StyledTab label="Inactive" />
            </StyledTabs>
          </SubListNavigation>
          <Divider />
        </Card>
        <MemberListBody isMemberResourceView />
      </Card>
    </List>
  );
};

export default MemberList;
