import React from 'react';

import {
  SelectField, TextField, useRecordContext,
} from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';

import { get } from 'lodash';

import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import booleanChoices from '../../../../constants/booleanChoices';
import { countryChoices } from '../../../../constants/countries';
import { identifierTypeChoices } from '../../../../constants/identifierTypes';
import conflictFields from '../../../../constants/conflictFields';

const SIGNER_TITLE = {
  [languages.EN]: 'Signer',
  [languages.SV]: 'Signerare',
} as const;

const FIRST_NAME_LABEL = {
  [languages.SV]: 'Förnamn',
  [languages.EN]: 'First name',
};

const LAST_NAME_LABEL = {
  [languages.SV]: 'Efternamn',
  [languages.EN]: 'Last name',
};

const ROLE_LABEL = {
  [languages.SV]: 'Arbetsroll',
  [languages.EN]: 'Role',
};

const PHONE_NUMBER_LABEL = {
  [languages.SV]: 'Telefonnummer',
  [languages.EN]: 'Phone Number',
};

const SIGNER_CONFIRM_LABEL = {
  [languages.SV]: 'Härmed försäkras att inlämnade uppgifter är riktiga och fullständiga.',
  [languages.EN]: 'Hereby it is certified that the provided information is accurate and complete.',
};

const ValueField = ({
  language,
  md = 6,
  boldFont = true,
}: {
  language?: Languages,
  md?: number,
  boldFont?: boolean
}) => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  if (!language) return null;

  return (
    <>
      <Grid item xs={12} md={md}>
        <Box display="flex" flexDirection="column">
          <Typography sx={sx} variant="label">{SIGNER_CONFIRM_LABEL[language]}</Typography>
          <SelectField
            record={value}
            fontWeight={boldFont ? 'bold' : ''}
            choices={booleanChoices[language]}
            source="confirm"
            emptyText="-"
            sx={sx}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={md}>
        <Box display="flex" flexDirection="column">
          <Typography sx={sx} variant="label">{FIRST_NAME_LABEL[language]}</Typography>
          <TextField
            record={value}
            fontWeight={boldFont ? 'bold' : ''}
            source="firstName"
            emptyText="-"
            sx={sx}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={md}>
        <Box display="flex" flexDirection="column">
          <Typography sx={sx} variant="label">{LAST_NAME_LABEL[language]}</Typography>
          <TextField
            record={value}
            fontWeight={boldFont ? 'bold' : ''}
            source="lastName"
            emptyText="-"
            sx={sx}
          />
        </Box>
      </Grid>
      {value?.nationalIdentifier?.map((item: {country: string, identifier: string}) => (
        <Grid key={item.country} item xs={12} md={md}>
          <Box display="flex" flexDirection="column">
            <Box gap={1} display="flex">
              <Typography variant="label">National Identifier for</Typography>
              <SelectField
                record={item}
                source="country"
                variant="label"
                choices={countryChoices(languages.EN)}
              />
            </Box>
            <Box display="flex" gap={2}>
              <SelectField
                record={item}
                choices={identifierTypeChoices(languages.EN)}
                source="type"
                fontWeight={boldFont ? 'bold' : ''}
              />
              <Typography fontWeight={boldFont ? 'bold' : ''}>-</Typography>
              <TextField
                record={item}
                source="identifier"
                fontWeight={boldFont ? 'bold' : ''}
              />
            </Box>
          </Box>
        </Grid>
      ))}
      <Grid item xs={12} md={md}>
        <Box display="flex" flexDirection="column">
          <Typography sx={sx} variant="label">{ROLE_LABEL[language]}</Typography>
          <TextField
            record={value}
            fontWeight={boldFont ? 'bold' : ''}
            source="role"
            emptyText="-"
            sx={sx}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={md}>
        <Box display="flex" flexDirection="column">
          <Typography sx={sx} variant="label">Email</Typography>
          <TextField
            fontWeight={boldFont ? 'bold' : ''}
            record={value}
            source="email"
            emptyText="-"
            sx={sx}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={md}>
        <Box display="flex" flexDirection="column">
          <Typography sx={sx} variant="label">{PHONE_NUMBER_LABEL[language]}</Typography>
          <TextField
            record={value}
            fontWeight={boldFont ? 'bold' : ''}
            source="phoneNumber"
            emptyText="-"
            sx={sx}
          />
        </Box>
      </Grid>
    </>
  );
};

const Signer = () => {
  const record = useRecordContext();

  const language = 'en';

  const value = get(record, 'signer', get(record, 'value'));

  if (!value || !language) return <Typography>-</Typography>;

  return (
    <Answer
      label={SIGNER_TITLE[language]}
      source="signer"
      datapoint={conflictFields.SIGNER}
      isComplexType
    >
      <Grid item xs={12} md={12}>
        <Grid container spacing={2}>
          <ValueField language={language} />
        </Grid>
      </Grid>
    </Answer>
  );
};

Signer.ValueField = ({ language }: {language?: Languages}) => (
  <Grid item xs={12} md={12}>
    <Grid container spacing={2}>
      <ValueField language={language} />
    </Grid>
  </Grid>
);

export default Signer;
