import { Grid, Typography } from '@mui/material';
import React from 'react';

import {
  ReferenceArrayInput, SelectArrayInput,
} from 'react-admin';

const TeamArraySelectInput = ({ edit }: { edit: boolean }) => (
  <Grid container spacing={1}>
    <Grid item xs={12} md={12}>
      <Typography variant="label">Team assignment</Typography>
    </Grid>
    <Grid item xs={12} md={12}>
      <ReferenceArrayInput source="teams" reference="teams" filter={{ active: true }}>
        <SelectArrayInput disabled={!edit} label="Team names" fullWidth />
      </ReferenceArrayInput>
    </Grid>
  </Grid>
);

export default TeamArraySelectInput;
