import React, { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { SubmitHandler, FieldValues, useFormContext } from 'react-hook-form';
import {
  DateInput,
  Form,
  SelectInput,
  required,
  useRecordContext,
} from 'react-admin';
import { Currencies } from '../../constants/currencies';
import Params from './Params';

const SaveOnSubmit = <T extends FieldValues>({
  onSubmit,
}: {
  onSubmit: SubmitHandler<T>
}) => {
  const { handleSubmit, watch, trigger } = useFormContext<T>();

  useEffect(() => {
    const subscription = watch(async () => {
      const isFormValid = await trigger();
      if (isFormValid) handleSubmit(onSubmit)();
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, trigger, watch]);

  return null;
};

const TransactionFilterForm = ({
  params,
  setParams,
}: {
  params: Params;
  setParams: React.Dispatch<React.SetStateAction<Params>>;
}) => {
  const record = useRecordContext<{
    currencies?: Currencies[]
  }>();

  const onSubmit = useCallback((newParams: Params) => {
    setParams((previousParams) => ({ ...previousParams, ...newParams }));
  }, [setParams]);

  return (
    <Form values={params}>
      <Box display="flex" gap={4} flexWrap="wrap">
        <SelectInput
          label="Currency"
          source="currency"
          choices={record?.currencies?.map((currency) => ({
            id: currency,
            name: currency,
          }))}
          validate={required()}
        />
        <DateInput source="from" label="From" validate={required()} />
        <DateInput source="to" label="To" validate={required()} />
        <SaveOnSubmit onSubmit={onSubmit} />
      </Box>
    </Form>
  );
};

export default TransactionFilterForm;
