import decodeJwt from 'jwt-decode';
import { fetchUtils } from 'react-admin';
import config from '../config';
import authTokenStore from './authTokenStore';
import { Actions } from '../constants/actions';

const httpClient = fetchUtils.fetchJson;

type Token = {
  id?: string;
  username?: string;
  displayName?: string;
  accountName?: string;
  accountId?: string;
  actions?: Actions[];
}

type LoginParams = {
  username: string;
  password: string;
  alias: string
}

const AuthProvider = {
  login: async (login: LoginParams) => {
    const response = await httpClient(`${config.apiUrl}/auth/login`, {
      method: 'POST',
      body: JSON.stringify(login),
    });
    const auth = response.json;
    if (!auth) throw new Error('Network error');
    authTokenStore.setToken(auth.token);
  },
  checkError: async (error: { message: string, status: number, body: unknown }) => {
    const { status } = error;
    const checkToken = authTokenStore.getToken();
    if (status === 401 && checkToken) {
      authTokenStore.removeToken();
      throw new Error('Authentication error');
    }
  },
  checkAuth: async () => {
    const checkToken = authTokenStore.getToken();
    // eslint-disable-next-line prefer-promise-reject-errors
    if (!checkToken) return Promise.reject({ message: false });
    return Promise.resolve();
  },

  logout: async () => {
    authTokenStore.removeToken();
  },

  getIdentity: async () => {
    const token = authTokenStore.getToken();
    if (!token) throw new Error('Authentication error');
    const {
      id,
      username,
      displayName,
      accountName,
      accountId,
    } = decodeJwt<Token>(token);
    if (!id) throw new Error('Authentication error');
    return {
      id,
      displayName,
      username,
      avatar: undefined,
      accountName,
      accountId,
    };
  },
  getPermissions: async () => {
    const token = authTokenStore.getToken();
    if (!token) return [];
    const { actions } = decodeJwt<Token>(token);
    return actions ?? [];
  },
  async handleCallback() {
    const urlParams = new URLSearchParams(window.location.search);
    const error = urlParams.get('error');
    if (error) return { redirectTo: `/login?error=${error}` };
    const token = urlParams.get('token');
    if (!token || !token.length) return { redirectTo: '/login?error=Invalid token' };
    return authTokenStore.setToken(token);
  },
};

export default AuthProvider;
