import React from 'react';

import { Theme, useTheme } from '@mui/material';

import { ChipField, useRecordContext } from 'react-admin';

const TeamChip = ({
  label,
  small,
} : {
  label?: string,
  small?: boolean,
  sortable?: boolean,
}) => {
  const record = useRecordContext();
  const { palette } = useTheme<Theme>();

  if (!record) return null;

  return (
    <ChipField
      sx={{
        ...(small ? { fontSize: 9.5, height: 15 } : {}),
        backgroundColor: record.color,
        '& .MuiChip-label': {
          color: palette.getContrastText(record.color),
          ...(small ? { paddingLeft: 2, paddingRight: 2 } : {}),
        },
      }}
      label={label}
      source="name"
    />
  );
};

export default TeamChip;
