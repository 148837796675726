import React, { useCallback, useEffect, useState } from 'react';

import {
  Identifier,
  RaRecord, useGetList,
} from 'react-admin';

import { isEmpty, isNil } from 'lodash';

import ReactApexChart from 'react-apexcharts';

import {
  Grid,
  Card,
  CardHeader,
  Divider,
  LinearProgress,
} from '@mui/material';

import useDialogStatus from '../../../hooks/useDialogStatus';

import NoResults from '../../layout/NoResults';
import DetailedTransactionTable from '../DetailedTransactionTable';

import { requestTypes } from '../../../constants/requestTypes';

type ApexChartOnClickHandler<T extends unknown> = (
  _event: unknown,
  _chartContext: unknown,
  config: {
    w: {
      config: {
        series: {
          data: {
            [x: string]: T;
          };
        }[];
      };
    };
    dataPointIndex: string | number; },
) => void;

const useGetGraphData = <T extends unknown>(
  data: RaRecord[] | undefined,
  onClickGraph: ApexChartOnClickHandler<T>,
) => {
  const [hasData, setHasData] = useState(false);
  const [options, setOptions] = useState<ReactApexChart['props']['options']>();
  const [series, setSeries] = useState<ReactApexChart['props']['series']>();

  useEffect(() => {
    if (isEmpty(data)) return;

    const newSeries = data as ReactApexChart['props']['series'];

    const newOptions = {
      colors: ['#388e3c', '#880808'],
      chart: {
        redrawOnParentResize: true,
        height: 400,
        events: {
          markerClick: onClickGraph,
        },
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: true,
        },
      },
      stroke: {
        curve: 'smooth',
        width: [2, 2],
      },
      fill: {
        type: 'solid',
        opacity: [0.35, 0.35],
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        decimalsInFloat: 0,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter(value: number, {
            seriesIndex, dataPointIndex, w,
          }: {
            seriesIndex: number,
            dataPointIndex: number,
            w: RaRecord
          }) {
            const {
              y,
              currency: curr,
              frequency,
            } = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

            if (!isNil(y)) {
              return `${y.toFixed(0)} ${curr} (${frequency})`;
            }
            return 'NaN';
          },
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          rotate: -90,
        },
      },
    } as ReactApexChart['props']['options']; // satisfies ReactApexChart['props']['options'] when ts is upgraded;

    setHasData(true);
    setOptions(newOptions);
    setSeries(newSeries);
  }, [data, onClickGraph]);

  return {
    hasData,
    options,
    series,
  };
};

const DailySum = ({
  filter,
}: {
  filter: {
    from: string;
    to: string;
    currency: string;
    entityId: string;
    requestType: typeof requestTypes.TRANSACTION;
  }
}) => {
  const [selectedData, setData] = useState<RaRecord>();
  const { open, closeDialog, openDialog } = useDialogStatus();

  const { data, isLoading } = useGetList(
    'requests/graph/daily-sum',
    {
      filter,
    },
  );

  const onClickGraph = useCallback<ApexChartOnClickHandler<{
    id: Identifier;
    requests: unknown[];
  }>>((
    _event,
    _chartContext,
    config,
  ) => {
    const dataPointFirstSeries = config.w.config.series[0].data[config.dataPointIndex];
    const dataPointSecondSeries = config.w.config.series[1].data[config.dataPointIndex];
    setData(() => ({
      ...dataPointFirstSeries,
      requests: [...dataPointFirstSeries.requests, ...dataPointSecondSeries.requests],
    }));
    openDialog();
  }, [openDialog]);

  const { hasData, options, series } = useGetGraphData(data, onClickGraph);

  if (isLoading) return <LinearProgress />;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardHeader title="Daily Sum of Transactions" />
          <Divider />
          {hasData && <ReactApexChart options={options} series={series} type="line" height={600} />}
          {!hasData && <NoResults variant="h4" />}
        </Card>
      </Grid>
      <DetailedTransactionTable
        open={open}
        closeDialog={closeDialog}
        from={selectedData?.x}
        to={selectedData?.x}
        currency={filter.currency}
        entityId={filter.entityId}
      />
    </Grid>
  );
};

export default DailySum;
