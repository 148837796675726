import React, { useCallback } from 'react';

import { useGetOne, useRecordContext, useRedirect } from 'react-admin';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button } from '@mui/material';

const KycFormView = () => {
  const record = useRecordContext();
  const redirect = useRedirect();

  const { data } = useGetOne('forms', { id: record?.formId }, { enabled: !!record?.formId });

  const page = data?.pages?.at(0)?.url;

  const onClick = useCallback(() => {
    redirect(`form-answers/${record?.formId}/${record?.id}/${page}`);
  }, [page, record?.formId, record?.id, redirect]);

  return (
    <Button startIcon={<VisibilityIcon />} onClick={onClick}>
      View
    </Button>

  );
};

export default KycFormView;
