import React, { useEffect } from 'react';

import {
  Box, Grid, Typography,
} from '@mui/material';

import { get, isEmpty } from 'lodash';

import {
  useRecordContext,
  TextField,
  TextInput,
  RecordContextProvider,
  SelectInput,
  NumberInput,
  minValue,
  required,
} from 'react-admin';

import { useFormContext, useWatch } from 'react-hook-form';
import { getName } from 'i18n-iso-countries';

import ArrayInput from './base/ArrayInput';
import { countryChoices } from '../../../constants/countries';
import currencies from '../../../constants/currencies';
import { unitsOfTimeChoices } from '../../../constants/unitsOfTime';
import cleaner from '../../../utilities/dataProvider/cleaner';

type TxObj = {
  currency: string,
  monthlyVolume: number,
  monthlyFrequency: number,
  max: number,
  median: number
  expectedTransactionTimeline: {
    amount: number,
    unit: string,
  }
}
interface TransactionCountry {
  country: string;
  incoming: TxObj;
  outgoing: TxObj;
  process: TxObj;
  total: TxObj;
}

interface TxThreshold {
  incoming?: Omit<TxObj, 'currency'>;
  process?: Omit<TxObj, 'currency'>
  outgoing?: Omit<TxObj, 'currency'>;
  total?: Omit<TxObj, 'currency'>;
}

type ITxMonitoringThresholds = Record<string, Record<string, TxThreshold>>;

const getTxMonitoringThresholds = (
  significantTransactionCountries: TransactionCountry[],
): ITxMonitoringThresholds => significantTransactionCountries?.reduce((
  acc: ITxMonitoringThresholds,
  {
    country: cCode, incoming, outgoing, process, total,
  },
) => {
  const { currency: incomingCurrency, ...incomingDetails } = incoming ?? {};
  const { currency: outgoingCurrency, ...outgoingDetails } = outgoing ?? {};
  const { currency: processCurrency, ...processDetails } = process ?? {};
  const { currency: totalCurrency, ...totalDetails } = total ?? {};

  const setTxThreshold = (txType: 'incoming' | 'outgoing' | 'process' | 'total', currency?: string, details?: Omit<TxObj, 'currency'>) => {
    if (!currency) return;

    const countryMap = acc[currency] || {};
    const txThreshold = countryMap[cCode] || {};

    countryMap[cCode] = {
      ...txThreshold,
      [txType]: details,
    };

    acc[currency] = countryMap;
  };

  setTxThreshold('incoming', incomingCurrency, incomingDetails);
  setTxThreshold('process', processCurrency, processDetails);
  setTxThreshold('outgoing', outgoingCurrency, outgoingDetails);
  setTxThreshold('total', totalCurrency, totalDetails);

  return acc;
}, {} as ITxMonitoringThresholds);

const NestedField = ({
  label,
  source,
  unit,
}: {
  label: string;
  source: string;
  unit?: string;
}) => {
  const record = useRecordContext();

  const value = get(record, source);

  const string = value ? `${value} ${unit ?? ''}`.trimEnd() : '-';

  return (
    <Grid item xs={12} md={6}>
      <Box display="flex" flexDirection="column">
        <Typography variant="label">{label}</Typography>
        <TextField record={{ string }} source="string" />
      </Box>
    </Grid>
  );
};

const TxMonitoringThresholdsDialogBody = () => (
  <Grid container spacing={4}>
    <Grid item xs={12} md={6}>
      <SelectInput
        choices={countryChoices('en')}
        label="Select country"
        fullWidth
        source="country"
        validate={required()}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography color="primary" variant="h6">Incoming transactions</Typography>
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        What is the currency of the incoming transactions you expect to handle?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput
        choices={currencies.map((curr) => ({ name: curr, id: curr }))}
        label="Currency"
        fullWidth
        source="incoming.currency"
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        What is the volume of incoming transactions you expect to handle per month?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="incoming.monthlyVolume"
        label="Monthly volume"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        What is the estimated singular highest incoming amount?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="incoming.max"
        label="Amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        What is the estimated typical incoming amount?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="incoming.median"
        label="Typical"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        How many incoming transactions do you expect to process per month?
      </Typography>
    </Grid>

    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="incoming.monthlyFrequency"
        label="Number of transactions"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        For how long is this incoming behavior expected?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="incoming.expectedTransactionTimeline.amount"
        label="Amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput
        choices={unitsOfTimeChoices}
        label="Unit"
        fullWidth
        source="incoming.expectedTransactionTimeline.unit"
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography color="primary" variant="h6">Outgoing transactions</Typography>
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        What is the currency of the outgoing transactions you expect to handle?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput
        fullWidth
        choices={currencies.map((curr) => ({ name: curr, id: curr }))}
        label="Currency"
        source="outgoing.currency"
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        What is the volume of outgoing transactions you expect to handle per month?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="outgoing.monthlyVolume"
        label="Monthly volume"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        What is the estimated singular highest outgoing amount?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="outgoing.max"
        label="Amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        What is the estimated typical outgoing amount?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="outgoing.median"
        label="Typical"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        How many outgoing transactions do you expect to handel per month?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="outgoing.monthlyFrequency"
        label="Number of transactions"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        For how long is this outgoing behavior expected?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="outgoing.expectedTransactionTimeline.amount"
        label="Amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput
        choices={unitsOfTimeChoices}
        label="Unit"
        fullWidth
        source="outgoing.expectedTransactionTimeline.unit"
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography color="primary" variant="h6">Process transactions</Typography>
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        What is the currency of the processing transactions you expect to handle?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput
        fullWidth
        choices={currencies.map((curr) => ({ name: curr, id: curr }))}
        label="Currency"
        source="process.currency"
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        What is the volume of transactions you expect process per month?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="process.monthlyVolume"
        label="Monthly volume"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        What is the estimated singular highest processing amount?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="process.max"
        label="Amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        What is the estimated typical process amount?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="process.median"
        label="Typical"
        validate={minValue(0)}
      />
    </Grid>

    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        How many transactions do you expect to process per month?
      </Typography>
    </Grid>

    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="process.monthlyFrequency"
        label="Number of transactions"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        For how long is this processing behavior expected?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="process.expectedTransactionTimeline.amount"
        label="Amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput
        choices={unitsOfTimeChoices}
        label="Unit"
        fullWidth
        source="process.expectedTransactionTimeline.unit"
      />
    </Grid>

    <Grid item xs={12} md={12}>
      <Typography color="primary" variant="h6">Total transactions</Typography>
    </Grid>

    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        What is the currency of the transactions you expect to handle?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput
        fullWidth
        choices={currencies.map((curr) => ({ name: curr, id: curr }))}
        label="Currency"
        source="total.currency"
      />
    </Grid>

    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        What is the total volume of transactions you expect per month?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="total.monthlyVolume"
        label="Monthly volume"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        What is the estimated singular highest amount?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="total.max"
        label="Amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        What is the estimated typical amount?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="total.median"
        label="Typical"
        validate={minValue(0)}
      />
    </Grid>

    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        How many transactions do you expect in total per month?
      </Typography>
    </Grid>

    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="total.monthlyFrequency"
        label="Number of transactions"
        validate={minValue(0)}
      />
    </Grid>

    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        For how long is this behavior expected?
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source="total.expectedTransactionTimeline.amount"
        label="Amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput
        choices={unitsOfTimeChoices}
        label="Unit"
        fullWidth
        source="total.expectedTransactionTimeline.unit"
      />
    </Grid>
  </Grid>
);

const SignatoryAccordionBody = ({ index }: {index: number}) => {
  const values = useRecordContext();

  if (!values) return null;

  const record = values?.at(index);

  if (isEmpty(record)) return null;

  return (
    <RecordContextProvider value={record}>
      <Grid container spacing={4}>
        <NestedField
          source="incoming.monthlyVolume"
          label="What is the monthly incoming volume of transactions you will conduct?"
          unit={record?.incoming?.currency}
        />
        <NestedField
          source="incoming.expectedTransactionTimeline.amount"
          label="What is the expected incoming transaction time-line?"
          unit={record?.incoming?.expectedTransactionTimeline?.unit}
        />
        <NestedField
          source="incoming.monthlyFrequency"
          label="How many incoming transactions will you conduct on a monthly basis?"
        />
        <NestedField
          source="incoming.max"
          label="What is the estimated singular highest incoming amount?"
          unit={record?.incoming?.currency}
        />

        <NestedField
          source="incoming.median"
          label="What is the estimated typical incoming amount?"
          unit={record?.incoming?.currency}
        />

        <NestedField
          source="outgoing.monthlyVolume"
          label="What is the monthly outgoing volume of transactions you will conduct?"
          unit={record?.outgoing?.currency}
        />
        <NestedField
          source="outgoing.expectedTransactionTimeline.amount"
          label="What is the expected outgoing transaction time-line?"
          unit={record?.outgoing?.expectedTransactionTimeline?.unit}
        />
        <NestedField
          source="outgoing.monthlyFrequency"
          label="How many outgoing transactions will you conduct on a monthly basis?"
        />

        <NestedField
          source="outgoing.max"
          label="What is the estimated singular highest outgoing amount?"
          unit={record?.outgoing?.currency}
        />

        <NestedField
          source="outgoing.median"
          label="What is the estimated typical outgoing amount?"
          unit={record?.outgoing?.currency}
        />

        <NestedField
          source="process.monthlyVolume"
          label="What is the volume of transactions you expect process per month?"
          unit={record?.process?.currency}
        />
        <NestedField
          source="process.expectedTransactionTimeline.amount"
          label="What is the expected transaction processing time-line?"
          unit={record?.process?.expectedTransactionTimeline?.unit}
        />
        <NestedField
          source="process.monthlyFrequency"
          label="How many transactions will you process on a monthly basis?"
        />
        <NestedField
          source="process.max"
          label="What is the estimated singular highest processing amount?"
          unit={record?.process?.currency}
        />
        <NestedField
          source="process.median"
          label="What is the estimated typical processing amount?"
          unit={record?.process?.currency}
        />

        <NestedField
          source="total.monthlyVolume"
          label="What is the total volume of transactions you expect per month?"
          unit={record?.total?.currency}
        />
        <NestedField
          source="total.expectedTransactionTimeline.amount"
          label="What is the expected transaction time-line?"
          unit={record?.total?.expectedTransactionTimeline?.unit}
        />
        <NestedField
          source="total.monthlyFrequency"
          label="How many transactions do you expect in total per month?"
        />
        <NestedField
          source="total.max"
          label="What is the estimated singular highest amount?"
          unit={record?.total?.currency}
        />
        <NestedField
          source="total.median"
          label="What is the estimated typical amount?"
          unit={record?.total?.currency}
        />

      </Grid>
    </RecordContextProvider>
  );
};

const ItemLabel = ({ value } : {value: Record<string, string>}) => {
  if (isEmpty(value)) return null;

  return (

    <Typography
      variant="h6"
      color="primary.main"
    >
      {getName(value?.country, 'en')}
    </Typography>
  );
};

const TxMonitoringThresholds = () => {
  const value = useWatch({ name: '_value' });
  const { setValue } = useFormContext();
  useEffect(() => {
    setValue('value', getTxMonitoringThresholds(value ?? []));
  }, [value, setValue]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Typography
          color="#646464"
          variant="h6"
          marginBottom={4}
        >
          Country of activity
        </Typography>
        <ArrayInput
          rootField="_value"
          title="Add transaction country"
          itemNameSource="country"
          ItemLabel={ItemLabel}
          DialogBody={TxMonitoringThresholdsDialogBody}
          AccordionBody={SignatoryAccordionBody}
          validate={required()}
          transformItemData={cleaner}
        />
      </Grid>
      <Grid item xs={12} md={12} justifyContent="center" display="flex">
        <TextInput
          fullWidth
          source="comment"
          multiline
          rows={4}
          helperText={false}
          placeholder="Please explain your decision."
          validate={required()}
        />
      </Grid>
    </Grid>
  );
};

export default TxMonitoringThresholds;
