import React from 'react';

import {
  required,
  minValue,
  SelectInput,

} from 'react-admin';

import {
  Grid,
} from '@mui/material';

import useThresholdVariant from '../../../../hooks/useThresholdVariant';

import NumberInput from '../../../layout/inputFields/NumberInput';

import PriorityParameters from './PriorityParameters';
import ThresholdInput from './ThresholdInput';

import Label from '../../../../customFields/Label';

import timePeriods from '../../../../constants/timePeriods';

const Frequency = () => {
  const { thresholdVariant: globalThresholdVariant } = useThresholdVariant({ source: 'default' });
  const { thresholdVariant: formThresholdVariant } = useThresholdVariant({ source: 'form' });

  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <ThresholdInput source="default" thresholdVariant={globalThresholdVariant} allowCommon displayText="Frequency" />
            </Grid>
            <Grid item xs={12} md={12}>
              <ThresholdInput
                source="form"
                thresholdVariant={formThresholdVariant}
                allowCommon
                displayText="Deviation in %"

              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Label
                variant="label"
                title="Period"
                info="The period that the threshold will be checked against."
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <NumberInput
                source="ruleParameters.period.amount"
                fullWidth
                label="Length"
                validate={[required(), minValue(1)]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectInput
                source="ruleParameters.period.unit"
                choices={Object.values(timePeriods).map((period) => ({ id: period, name: period }))}
                fullWidth
                label="Period"
                validate={[required(), minValue(1)]}
              />
            </Grid>
          </Grid>
        </Grid>
        <PriorityParameters />
      </Grid>
    </Grid>
  );
};

export default Frequency;
