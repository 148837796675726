import React from 'react';

import { Grid, Typography } from '@mui/material';

import { SelectInput, TextInput } from 'react-admin';

import booleanChoices from '../../../constants/booleanChoices';
import { NationalIdentifierInputFields } from '../../../customFields/NationalIdentifier';

const Signer = () => (
  <Grid container spacing={10}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
      >
        Signer
      </Typography>
    </Grid>
    <Grid item xs={12} md={10}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <SelectInput
            source="value.confirm"
            fullWidth
            choices={booleanChoices.en}
            label="Hereby it is certified that the provided information is accurate and complete."
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextInput fullWidth source="value.firstName" label="First name" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput fullWidth source="value.latName" label="Last name" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput fullWidth source="value.role" label="Role" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput fullWidth source="value.email" label="Email" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput fullWidth source="value.phoneNumber" label="Phone number" />
        </Grid>
        <Grid item xs={12} md={12}>
          <NationalIdentifierInputFields source="value.nationalIdentifier" />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} md={10}>
      <TextInput
        fullWidth
        source="comment"
        multiline
        rows={5}
        helperText={false}
        placeholder="Please explain your decision."
      />
    </Grid>
  </Grid>
);

export default Signer;
