import React, { useCallback, useState } from 'react';

import { Identifier, useRefresh, useUpdate } from 'react-admin';

import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';

import { isEmpty } from 'lodash';
import useDialogStatus from '../../hooks/useDialogStatus';

const SelectOne = ({
  id,
  selected,
  otherSelected,
} : {
  id: Identifier,
  selected: boolean,
  otherSelected: boolean
}) => {
  const [blurred, setIsBlurred] = useState(false);
  const [comment, setComment] = useState('');
  const refresh = useRefresh();
  const { open, openDialog, closeDialog } = useDialogStatus();
  const [update] = useUpdate();

  const handleSubmit = useCallback(() => {
    update(
      'data-points/select-one',
      { id, data: { comment } },
      {
        onSuccess: () => {
          closeDialog();
          refresh();
        },
      },
    );
  }, [closeDialog, comment, id, refresh, update]);

  return (
    <>
      {!selected && (
        <Button variant={otherSelected ? 'outlined' : 'contained'} onClick={openDialog}>Select</Button>)}
      {selected && (
        <Box display="flex" alignItems="center" gap={1}>
          <CheckIcon color="secondary" />
          <Typography color="secondary">Selected</Typography>
        </Box>
      )}

      <Dialog open={open} maxWidth="md" fullWidth onClose={closeDialog}>
        <DialogTitle>Correct value</DialogTitle>
        <DialogContent>
          <TextField
            required
            onBlur={() => setIsBlurred(true)}
            error={blurred && comment === ''}
            helperText={blurred && comment === '' ? 'Explanation is required' : ''}
            onChange={(e) => setComment(e.target.value)}
            fullWidth
            variant="outlined"
            minRows={3}
            multiline
            placeholder="Please explain your decision."
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setComment(''); setIsBlurred(false); closeDialog(); }}>Cancel</Button>
          <Button disabled={isEmpty(comment)} onClick={handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default SelectOne;
