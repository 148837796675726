import React, { useCallback, useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  DateField,
  ArrayField,
  SingleFieldList,
  usePermissions,
  ReferenceArrayField,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useGetList,
  Identifier,
  useListFilterContext,
} from 'react-admin';

import {
  Box,
  Card, CircularProgress, Divider, Typography,
} from '@mui/material';

import ReferenceEntityField from '../../customFields/ReferenceEntityField';
import StatusField from '../../customFields/StatusField';
import TeamChip from '../../customFields/TeamChip';

import { SubListNavigation } from '../layout/SubNavigation';
import { StyledTab, StyledTabs } from '../layout/Tab';
import Pagination from '../layout/Pagination';
import NoResults from '../layout/NoResults';
import LinkField from '../layout/LinkField';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';

import hasAccess from '../../utilities/hasAccess';

import MergeCases from './bulkActions/MergeCases';

import EntityFilter from '../filters/EntityFilter';
import RuleFilter from '../filters/RuleFilter';
import { Actions } from '../../constants/actions';

export const CaseListBody = ({
  bulkActionButtons = true,
}: {
  bulkActionButtons?: boolean
}) => {
  const { permissions } = usePermissions<Actions[]>();
  return (
    <Datagrid
      bulkActionButtons={(hasAccess(permissions, 'CaseUpdate') && bulkActionButtons) ? <MergeCases /> : false}
      sx={boldDataGridStyle}
      empty={<NoResults variant="h6" />}
    >
      <LinkField sortable={false} source="id" />
      <DateField sortable source="createdAt" showTime />
      <ArrayField sortable={false} source="entities" label="Entities">
        <SingleFieldList linkType={false}>
          <ReferenceEntityField source="" chip />
        </SingleFieldList>
      </ArrayField>
      <ReferenceArrayField sortable={false} source="teams" reference="teams" label="Teams" emptyText="-">
        <SingleFieldList placeholder="-">
          <TeamChip />
        </SingleFieldList>
      </ReferenceArrayField>
      <StatusField sortable={false} source="status" />
    </Datagrid>
  );
};

const CaseCountLabel = ({
  count,
  label,
  countIsLoading,
  shouldCount,
}: {
  count?: number
  label: string
  countIsLoading: boolean
  shouldCount: boolean
}) => {
  if (!shouldCount) return <Typography>{label}</Typography>;

  if (countIsLoading) {
    return (
      <Box display="flex" alignItems="center" gap={2}>
        <Typography>{label}</Typography>
        <CircularProgress size={11} />
      </Box>
    );
  }

  return <Typography>{`${label} (${count ?? 0})`}</Typography>;
};

const CaseCountProvider = ({
  setCount,
  setCountIsLoading,
  shouldCount,
  setShouldCount,
  children,
}: {
  setCountIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  shouldCount: boolean
  setShouldCount: React.Dispatch<React.SetStateAction<boolean>>
  setCount: React.Dispatch<React.SetStateAction<{
    id: Identifier;
    open: number,
    closed: number
  } | undefined>>
  children: React.ReactElement
}) => {
  const { filterValues } = useListFilterContext();

  useEffect(
    () => {
      setShouldCount(Object.keys(filterValues).every((key) => key === 'status'));
    },
    [filterValues, setShouldCount],
  );

  const { data, isLoading } = useGetList<{
    id: Identifier,
    open: number,
    closed: number
  }>('cases/count', { filter: filterValues }, { enabled: shouldCount });

  useEffect(() => {
    setCountIsLoading(isLoading);
  }, [isLoading, setCountIsLoading]);

  useEffect(() => {
    setCount(data?.at(0));
  }, [data, setCount]);

  return children;
};

const CaseList = () => {
  const [statusTab, setStatusTab] = useState(0);
  const [status, setStatusFilter] = useState('Open');

  const [countIsLoading, setCountIsLoading] = useState(false);
  const [shouldCount, setShouldCount] = useState(true);
  const [count, setCount] = useState<{
    id: Identifier,
    open: number,
    closed: number
  }>();

  const handleStatusChange = useCallback((
    _event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    setStatusTab(newValue);
    if (newValue === 0) setStatusFilter('Open');
    if (newValue === 1) setStatusFilter('Closed');
  }, []);

  return (
    <List
      filter={{ status }}
      empty={false}
      pagination={<Pagination disableEmptyText />}
      filters={[
        <ReferenceArrayInput source="teams" reference="teams" filter={{ active: true }}>
          <AutocompleteArrayInput
            label="Team"
            filterToQuery={(searchText: string) => ({ name: searchText })}
            optionText="name"
          />
        </ReferenceArrayInput>,
        <EntityFilter label="Entities" source="entities" />,
        <RuleFilter source="pings.rule" label="Rules" />,
      ]}
      sort={{ field: 'createdAt', order: 'DESC' }}
      exporter={false}
    >
      <Card sx={{ margin: 0 }} variant="outlined">
        <CaseCountProvider
          shouldCount={shouldCount}
          setCount={setCount}
          setShouldCount={setShouldCount}
          setCountIsLoading={setCountIsLoading}
        >
          <Card sx={{ margin: 0 }}>
            <SubListNavigation>
              <StyledTabs value={statusTab} onChange={handleStatusChange}>
                <StyledTab
                  label={React.createElement(CaseCountLabel, {
                    count: count?.open,
                    label: 'Open',
                    countIsLoading,
                    shouldCount,
                  })}
                />
                <StyledTab
                  label={React.createElement(CaseCountLabel, {
                    count: count?.closed,
                    label: 'Closed',
                    countIsLoading,
                    shouldCount,
                  })}
                />
              </StyledTabs>
            </SubListNavigation>
            <Divider />
          </Card>
        </CaseCountProvider>
        <CaseListBody />
      </Card>
    </List>
  );
};

export default CaseList;
