import React from 'react';
import {
  Edit,
  TextField,
  DateField,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Datagrid,
  SelectArrayInput,
  required,
  usePermissions,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { useFormState } from 'react-hook-form';

import {
  Card, CardContent, CardHeader, Divider, Grid, Typography,
} from '@mui/material';

import ReferenceMemberField from '../../customFields/ReferenceMemberField';
import ReferenceResourceField from '../../customFields/ReferenceResourceField';

import { permissionRolesChoices } from '../../constants/permissionRoles';
import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import { Actions } from '../../constants/actions';

import hasAccess from '../../utilities/hasAccess';

import ApiKeyField from './Fields/ApiKeyField';
import ApiKeyBreadcrumb from './ApiKeyBreadcrumb';
import Pagination from '../layout/Pagination';
import useValidateId from '../../hooks/useValidateId';

const CustomToolbar = () => {
  const { isDirty } = useFormState();
  if (!isDirty) {
    return null;
  }
  return (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
};

const ApiKeyEdit = () => {
  const { permissions } = usePermissions<Actions[]>();
  const validatedId = useValidateId();
  if (!validatedId.isValid) return null;

  return (
    <Edit mutationMode="pessimistic" redirect={false}>
      <ApiKeyBreadcrumb />
      <TabbedForm toolbar={hasAccess(permissions, 'ApiKeyUpdate') ? <CustomToolbar /> : false}>
        <FormTab label="Information">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card variant="outlined">
                <CardHeader title="API Key" />
                <Divider />
                <CardContent>
                  <Grid container rowSpacing={2} columnSpacing={4}>
                    <Grid container item xs={12} md={6} spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Typography variant="label">
                          Permission roles
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <SelectArrayInput
                          source="permissionRoles"
                          validate={required()}
                          choices={permissionRolesChoices}
                          label="Permission Roles"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Typography variant="label">
                          Client ID
                        </Typography>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <TextField source="clientId" label="Client ID" />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Typography variant="label">
                          Client secret
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        {hasAccess(permissions, 'ApiKeyGetSecret') && (<ApiKeyField />)}
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </FormTab>
        {hasAccess(permissions, 'AuditTrackList') && (
          <FormTab label="Audit Tracks">
            <Grid container>
              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <ReferenceManyField
                    reference="audit-tracks"
                    target="resource.id"
                    sort={{ field: 'date', order: 'DESC' }}
                    perPage={15}
                    pagination={<Pagination disableEmptyText />}
                  >
                    <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                      <TextField sortable={false} source="id" />
                      <ReferenceMemberField label="User" />
                      <TextField sortable={false} source="description" />
                      <DateField sortable source="date" showTime />
                    </Datagrid>
                  </ReferenceManyField>
                </Card>
              </Grid>
            </Grid>
          </FormTab>
        )}
        <FormTab label="Activity">
          <Grid container>
            <Grid item xs={12} md={12}>
              <Card variant="outlined">
                <ReferenceManyField
                  reference="audit-tracks"
                  target="authenticator.id"
                  sort={{ field: 'date', order: 'DESC' }}
                  perPage={15}
                  pagination={<Pagination disableEmptyText />}
                >
                  <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                    <TextField sortable={false} source="id" />
                    <TextField sortable={false} source="description" />
                    <ReferenceResourceField label="Resource" />
                    <DateField sortable source="date" showTime />
                  </Datagrid>
                </ReferenceManyField>
              </Card>
            </Grid>
          </Grid>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default ApiKeyEdit;
