import React from 'react';

import {
  TextField, TextFieldProps, useRecordContext,
} from 'react-admin';

import {
  Box, Typography, styled,
} from '@mui/material';
import { capitalize, get, has } from 'lodash';

const COLOR_MAP = {
  Pending: { dot: '#F3B61F', text: '#F3B61F' },
  Review: { dot: '#F3B61F', text: '#F3B61F' },
  Resolved: { dot: '#528C46', text: '#528C46' },
  Available: { dot: '#528C46', text: '#528C46' },
  Open: { dot: '#528C46', text: '#528C46' },
  Proceed: { dot: '#528C46', text: '#528C46' },
  True: { dot: '#528C46', text: '#528C46' },
  Confirmed: { dot: '#1F487E', text: '#1F487E' },
  No: { dot: '#1F487E', text: '#1F487E' },
  None: { dot: '#1F487E', text: '#1F487E' },
  false: { dot: '#1F487E', text: '#1F487E' },
  Ignored: { dot: '#DE2B2B', text: '#DE2B2B' },
  Yes: { dot: '#DE2B2B', text: '#DE2B2B' },
  true: { dot: '#DE2B2B', text: '#DE2B2B' },
  Blocked: { dot: '#DE2B2B', text: '#DE2B2B' },
  Block: { dot: '#DE2B2B', text: '#DE2B2B' },
  Closed: { dot: '#DE2B2B', text: '#DE2B2B' },
  False: { dot: '#DE2B2B', text: '#DE2B2B' },
};

const Dot = styled('span')(({ color }) => ({
  height: '6px',
  width: '6px',
  borderRadius: '50%',
  marginRight: '4px',
  backgroundColor: color,
}));

const StatusField = ({ source }: {source: string} & TextFieldProps) => {
  const record = useRecordContext();
  if (!record || !has(record, source)) return <Typography>-</Typography>;

  const capitalisedValue = capitalize(record[source]);

  const colors = get(COLOR_MAP, capitalisedValue) || { dot: 'inherit', text: 'inherit' };

  return (
    <Box display="flex" alignItems="center">
      <Dot color={colors.dot} />
      <TextField
        record={{
          [source]: capitalisedValue,
        }}
        source={source}
        color={colors.text}
      />
    </Box>
  );
};

export default StatusField;
