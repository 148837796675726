import { Resolver } from 'react-hook-form';
import { ValueOf } from '../../utilities/types';

import conflictFields, { ConflictFields } from '../../constants/conflictFields';

import { organisationNumberValidation } from './fields/OrganizationNumber';

const conflictFieldsValidationMap: Record<ConflictFields, Resolver | undefined> = {
  [conflictFields.FIRST_NAME]: undefined,
  [conflictFields.LAST_NAME]: undefined,
  [conflictFields.COMPANY_NAME]: undefined,
  [conflictFields.REGISTRATION_NUMBER]: organisationNumberValidation,
  [conflictFields.TIN]: undefined,
  [conflictFields.CITY]: undefined,
  [conflictFields.RESIDENCE]: undefined,
  [conflictFields.EMAIL]: undefined,
  [conflictFields.POSTAL_CODE]: undefined,
  [conflictFields.WEBSITE]: undefined,
  [conflictFields.PHONE_NUMBER]: undefined,
  [conflictFields.ADDRESS]: undefined,
  [conflictFields.SIGNATORIES]: undefined,
  [conflictFields.BENEFICIAL_OWNER]: undefined,
  [conflictFields.BENEFICIAL_OWNERS]: undefined,
  [conflictFields.ALTERNATIVE_BENEFICIAL_OWNERS]: undefined,
  [conflictFields.OWNER_STRUCTURE]: undefined,
  [conflictFields.LEGAL_FORM]: undefined,
  [conflictFields.IS_FINANCIAL_INSTITUTION]: undefined,
  [conflictFields.INDUSTRY]: undefined,
  [conflictFields.ACTIVITIES]: undefined,
  [conflictFields.TRANSACTION_MONITORING_THRESHOLD]: undefined,
  [conflictFields.SIGNER]: undefined,
  [conflictFields.PURPOSE]: undefined,
  [conflictFields.PRODUCTS]: undefined,
  [conflictFields.TAX_RESIDENCY]: undefined,
  [conflictFields.PEP]: undefined,
  [conflictFields.CITIZENSHIP]: undefined,
  [conflictFields.EMPLOYMENT_STATUS]: undefined,
  [conflictFields.INCOME]: undefined,
  [conflictFields.SOURCE_OF_FUNDS]: undefined,
  [conflictFields.ROLE]: undefined,
  [conflictFields.STATE_OWNED]: undefined,
  [conflictFields.FORECASTED_TURNOVER]: undefined,
  [conflictFields.PREVIOUS_TURNOVER]: undefined,
  [conflictFields.LISTED_FINANCIAL_MARKET]: undefined,
  [conflictFields.DATE_OF_BIRTH]: undefined,
  [conflictFields.SOURCE_OF_WEALTH]: undefined,
  [conflictFields.MIDDLE_NAME]: undefined,
  [conflictFields.OTHER_LEGAL_FORM]: undefined,
  [conflictFields.OPERATION_START_DATE]: undefined,
} as const;

export type ConflictFieldsValidationMap = ValueOf<typeof conflictFieldsValidationMap>;

export default conflictFieldsValidationMap;
