import { ValueOf } from '../utilities/types';
import { requestRoles } from './requestRoles';
import { requestTypes } from './requestTypes';

const requestEntityFields = {
  [requestTypes.TRANSACTION]:
      [requestRoles.PROCESSOR, requestRoles.RECIPIENT, requestRoles.INITIATOR],
  [requestTypes.CAR_PURCHASE]:
      [requestRoles.CAR,
        requestRoles.SELLER,
        requestRoles.BUYER,
        requestRoles.GUARANTOR,
      ],
  [requestTypes.CAR_KYC]: [requestRoles.ENTITY],
  [requestTypes.INDIVIDUAL_KYC]: [requestRoles.ENTITY],
  [requestTypes.BUSINESS_KYC]: [requestRoles.ENTITY],
};

export const requestEntityFromFields = {
  [requestTypes.TRANSACTION]: requestRoles.INITIATOR,
  [requestTypes.CAR_PURCHASE]: requestRoles.BUYER,
} as const;

export type RequestEntityFromFields = ValueOf<typeof requestEntityFromFields>;

export const requestEntityToFields = {
  [requestTypes.TRANSACTION]: requestRoles.RECIPIENT,
  [requestTypes.CAR_PURCHASE]: requestRoles.SELLER,
};

export type RequestEntityToFields = ValueOf<typeof requestEntityToFields>;

export default requestEntityFields;
