import React from 'react';
import { DateTime } from 'luxon';

import {
  ListBase,
} from 'react-admin';

import {
  Dialog,
  DialogTitle,
  Card,
  DialogContent,
} from '@mui/material';

import Pagination from '../layout/Pagination';
import { requestListMap } from '../../constants/requestFeatures';
import { requestTypes } from '../../constants/requestTypes';

const TransactionListComponent = requestListMap[requestTypes.TRANSACTION];

const DetailedCategorizationTable = ({
  open,
  closeDialog,
  from,
  to,
  entityId,
  currency,
}: {
  open: boolean;
  closeDialog: () => void;
  from: string;
  to: string;
  entityId: string;
  currency: string;
}) => (
  <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xl">
    <DialogTitle color="secondary">Detailed Transactions</DialogTitle>
    <DialogContent>
      <Card variant="outlined">
        <ListBase
          storeKey={false}
          resource="requests"
          filter={{
            requestType: requestTypes.TRANSACTION,
            'extraInformation.currency': currency,
            'custom.entity': entityId,
            'custom.from': DateTime.fromISO(from).startOf('day').toISO(),
            'custom.to': DateTime.fromISO(to).endOf('day').toISO(),
          }}
          perPage={10}
          sort={{ field: 'extraInformation.date', order: 'DESC' }}
        >
          <TransactionListComponent />
          <Pagination disableEmptyText rowsPerPageOptions={[5, 10, 25]} />
        </ListBase>
      </Card>
    </DialogContent>
  </Dialog>
);

export default DetailedCategorizationTable;
