const safeGetItemLocalStorage = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch {
    return null;
  }
};

const safeSetItemLocalStorage = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch {
    // nothing to do
  }
};

const safeRemoveItemLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch {
    // nothing to do
  }
};

class AuthTokenStore {
  token: string | null;

  storageKey: string;

  constructor(storageKey: string) {
    this.token = null;
    this.storageKey = storageKey;
  }

  getToken() {
    if (!this.token) {
      this.token = safeGetItemLocalStorage(this.storageKey);
    }
    return this.token;
  }

  setToken(token: string) {
    this.token = token;
    safeSetItemLocalStorage(this.storageKey, token);
  }

  removeToken() {
    this.token = null;
    safeRemoveItemLocalStorage(this.storageKey);
  }
}

const authTokenStore = new AuthTokenStore('auth');

export default authTokenStore;
