import React from 'react';
import { ValueOf } from '../../utilities/types';

import conflictFields, { ConflictFields } from '../../constants/conflictFields';

import FirstName from './fields/FirstName';
import CompanyName from './fields/CompanyName';
import LastName from './fields/LastName';
import OrganizationNumber from './fields/OrganizationNumber';
import Tin from './fields/Tin';
import City from './fields/City';
import Residence from './fields/Residence';
import Email from './fields/Email';
import PostalCode from './fields/PostalCode';
import Address from './fields/Address';
import Website from './fields/Website';
import PhoneNumber from './fields/PhoneNumber';
import Signatories from './fields/Signatories';
import LegalForm from './fields/LegalForm';
import BeneficialOwner from './fields/BeneficialOwner';
import IsFinancialInstitution from './fields/IsFinancialInstitution';
import Industry from './fields/Industry';
import Activities from './fields/Activities';
// import PersonalNumber from '../components/conflict/fields/PersonalNumber';
import BeneficialOwners from './fields/BeneficialOwners';
import AlternativeBeneficialOwners from './fields/AlternativeBeneficialOwners';
import OwnerStructure from './fields/OwnerStructure';
import Salary from './fields/Salary';
import Citizenship from './fields/Citizenship';
import EmploymentStatus from './fields/EmploymentStatus';
import SourceOfFunds from './fields/SourceOfFunds';
import Purpose from './fields/Purpose';
import Products from './fields/Products';
import TaxResidency from './fields/TaxResidency';
import Pep from './fields/Pep';
import Signer from './fields/Signer';
import TxMonitoringThresholds from './fields/TxMonitoringThresholds';
import Role from './fields/Role';
import StateOwned from './fields/StateOwned';
import ForecastedTurnover from './fields/ForecastedTurnover';
import PreviousTurnover from './fields/previousTurnover';
import ListedFinancialMarket from './fields/ListedFinancialMarket';
import DateOfBirth from './fields/DateOfBirth';
import SourceOfWealth from './fields/SourceOfWealth';
import OperationStartDate from './fields/OperationStartDate';

const conflictFieldsMap: Record<ConflictFields, React.ComponentType> = {
  [conflictFields.FIRST_NAME]: FirstName,
  [conflictFields.LAST_NAME]: LastName,
  [conflictFields.COMPANY_NAME]: CompanyName,
  [conflictFields.REGISTRATION_NUMBER]: OrganizationNumber,
  [conflictFields.TIN]: Tin,
  [conflictFields.CITY]: City,
  [conflictFields.RESIDENCE]: Residence,
  [conflictFields.EMAIL]: Email,
  [conflictFields.POSTAL_CODE]: PostalCode,
  [conflictFields.WEBSITE]: Website,
  [conflictFields.PHONE_NUMBER]: PhoneNumber,
  [conflictFields.ADDRESS]: Address,
  [conflictFields.SIGNATORIES]: Signatories,
  [conflictFields.BENEFICIAL_OWNER]: BeneficialOwner,
  [conflictFields.BENEFICIAL_OWNERS]: BeneficialOwners,
  [conflictFields.ALTERNATIVE_BENEFICIAL_OWNERS]: AlternativeBeneficialOwners,
  [conflictFields.OWNER_STRUCTURE]: OwnerStructure,
  [conflictFields.LEGAL_FORM]: LegalForm,
  [conflictFields.IS_FINANCIAL_INSTITUTION]: IsFinancialInstitution,
  [conflictFields.INDUSTRY]: Industry,
  [conflictFields.ACTIVITIES]: Activities,
  [conflictFields.TRANSACTION_MONITORING_THRESHOLD]: TxMonitoringThresholds,
  [conflictFields.SIGNER]: Signer,
  [conflictFields.PURPOSE]: Purpose,
  [conflictFields.PRODUCTS]: Products,
  [conflictFields.TAX_RESIDENCY]: TaxResidency,
  [conflictFields.PEP]: Pep,
  [conflictFields.CITIZENSHIP]: Citizenship,
  [conflictFields.EMPLOYMENT_STATUS]: EmploymentStatus,
  [conflictFields.INCOME]: Salary,
  [conflictFields.SOURCE_OF_FUNDS]: SourceOfFunds,
  // [conflictFields.PERSONAL_NUMBER]: PersonalNumber,
  [conflictFields.ROLE]: Role,
  [conflictFields.STATE_OWNED]: StateOwned,
  [conflictFields.FORECASTED_TURNOVER]: ForecastedTurnover,
  [conflictFields.PREVIOUS_TURNOVER]: PreviousTurnover,
  [conflictFields.LISTED_FINANCIAL_MARKET]: ListedFinancialMarket,
  [conflictFields.DATE_OF_BIRTH]: DateOfBirth,
  [conflictFields.SOURCE_OF_WEALTH]: SourceOfWealth,
  [conflictFields.MIDDLE_NAME]: () => null,
  [conflictFields.OTHER_LEGAL_FORM]: () => null,
  [conflictFields.OPERATION_START_DATE]: OperationStartDate,
  // [conflictFields.CONTROL_TYPE]: PrimitiveInput,
  // [conflictFields.FAMILY_CONTROL]: PrimitiveInput,
  // [conflictFields.DIRECT_CONTROL]: PrimitiveInput,
} as const;

export type ConflictFieldsMap = ValueOf<typeof conflictFieldsMap>;

export default conflictFieldsMap;
