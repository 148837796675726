import React, { useCallback, useMemo, useState } from 'react';

import {
  useRecordContext,
} from 'react-admin';

import { DateTime } from 'luxon';

import { useParams } from 'react-router-dom';
import SubNavigation from '../layout/SubNavigation';
import { StyledTabs, StyledTab } from '../layout/Tab';

import { ValueOf } from '../../utilities/types';

import Params from './Params';

import DailySum from './tabs/DailySum';
import RollingSum from './tabs/RollingSum';
import DetailedView from './tabs/DetailedView';
import Flow from './tabs/flow';
import Distribution from './tabs/Distribution';
import { requestTypes } from '../../constants/requestTypes';
import Summary from './Summary';
import TransactionFilterForm from './TransactionFilterForm';

const tabs = {
  DAILY_SUM: 'dailySum',
  ROLLING_SUM: 'rollingSum',
  DETAILED_VIEW: 'detailedView',
  FLOW: 'flow',
  DISTRIBUTION: 'distribution',
} as const;

type TabValues = ValueOf<typeof tabs>;

const Transaction = () => {
  const [tab, setTab] = useState<TabValues>(tabs.DAILY_SUM);

  const { id } = useParams();
  const record = useRecordContext();

  const currentDate = DateTime.now();

  const [params, setParams] = useState<Params>({
    from: currentDate.minus({ days: 30 }).toFormat('yyyy-MM-dd'),
    to: currentDate.toFormat('yyyy-MM-dd'),
    currency: record?.currencies?.at(0) ?? 'EUR',
    rollingSumWindow: 30,
    distributionWindow: 0,
    maxDepth: 1,
  });

  const filter = useMemo(() => ({
    entityId: id ?? '',
    requestType: requestTypes.TRANSACTION,
    from: params.from,
    currency: params.currency,
    to: params.to,
  }), [id, params]);

  const handleTabChange = useCallback((
    event: React.SyntheticEvent,
    newValue: ValueOf<typeof tabs>,
  ) => {
    setTab(newValue);
  }, []);

  return (
    <>
      <TransactionFilterForm setParams={setParams} params={params} />
      <Summary params={params} filter={filter} />
      <SubNavigation>
        <StyledTabs value={tab} onChange={handleTabChange}>
          <StyledTab label="Daily Sum" value={tabs.DAILY_SUM} />
          <StyledTab label="Rolling Sum" value={tabs.ROLLING_SUM} />
          <StyledTab label="Flow" value={tabs.FLOW} />
          <StyledTab label="Distribution" value={tabs.DISTRIBUTION} />
          <StyledTab label="Detailed View" value={tabs.DETAILED_VIEW} />
        </StyledTabs>
      </SubNavigation>
      {tab === tabs.DAILY_SUM
        && <DailySum filter={filter} />}
      {tab === tabs.ROLLING_SUM
        && <RollingSum filter={filter} setParams={setParams} params={params} />}
      {tab === tabs.FLOW && <Flow setParams={setParams} params={params} />}
      {tab === tabs.DISTRIBUTION
        && <Distribution filter={filter} setParams={setParams} params={params} />}
      {tab === tabs.DETAILED_VIEW
        && <DetailedView filter={filter} />}
    </>
  );
};

export default Transaction;
