import React, { useState, useEffect, useCallback } from 'react';

import {
  Button,
  Box,
  Typography,
} from '@mui/material';

import {
  NumberInput,
  PasswordInput,
  TextField,
  TextInput,
  DateInput,
  Validator,
  NumberField,
  DateField,
} from 'react-admin';

import { useFormContext } from 'react-hook-form';

import CreateIcon from '@mui/icons-material/Create';
import CancelIcon from '@mui/icons-material/Cancel';

const typeFields = {
  text: TextField,
  number: NumberField,
  password: TextField,
  date: DateField,
} as const;

const typeInputs = {
  text: TextInput,
  number: NumberInput,
  password: PasswordInput,
  date: DateInput,
} as const;

const EditField = ({
  source,
  label,
  showEdit,
  validate,
  type = 'text',
}: {
  source: string,
  label: string,
  showEdit: boolean,
  validate?: Validator | Validator[],
  type?: keyof typeof typeFields,
}) => {
  const [editable, setEditable] = useState(false);

  const { formState: { isSubmitSuccessful }, resetField } = useFormContext();

  const handleChange = useCallback(() => {
    const newValueEditable = !editable;
    if (!newValueEditable) resetField(source);
    setEditable(newValueEditable);
  }, [editable, resetField, source]);

  useEffect(() => {
    if (isSubmitSuccessful) { setEditable(false); }
  }, [editable, isSubmitSuccessful]);

  if (!editable) {
    const FieldComponent = typeFields[type];
    return (
      <Box>
        <Typography variant="smallTitle">{label}</Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <FieldComponent source={source} emptyText="-" />
          {showEdit && (
            <Button startIcon={<CreateIcon color="secondary" />} size="small" onClick={handleChange}>
              Edit
            </Button>
          )}
        </Box>
      </Box>
    );
  }

  const InputComponent = typeInputs[type];
  return (
    <Box>
      <Typography variant="smallTitle">{label}</Typography>
      <Box display="flex" alignItems="baseline" justifyContent="space-between">
        <InputComponent
          color="secondary"
          autoFocus
          label=""
          source={source}
          validate={validate}
        />
        <Button startIcon={<CancelIcon color="error" />} size="small" onClick={handleChange}>
          Cancel
        </Button>
      </Box>

    </Box>
  );
};

export default EditField;
