import React from 'react';
import { Resource, ResourceOptions, useGetResourceLabel } from 'react-admin';

import hasAccess from '../../utilities/hasAccess';
import getResourceName from '../../utilities/getResourceName';

import { Actions } from '../../constants/actions';
import { useRegisterBreadcrumb } from '../layout/navbar/BreadcrumbsManager';

const getActionName = (resourceName: string, actionType: string) => `${getResourceName(resourceName)}${actionType}` as Actions;

const ResourceBreadcrumb = ({
  name,
}: { name: string }) => {
  const getResourceLabel = useGetResourceLabel();
  useRegisterBreadcrumb({
    title: getResourceLabel(name, 2),
    path: name,
  });

  return null;
};

type AuthenticatedResourceInput = {
  name: string
  list?: React.FC
  edit?: React.FC
  show?: React.FC
  create?: React.FC
  icon?: React.FC;
  options?: ResourceOptions
}

const AuthenticatedResource = ({
  name,
  list,
  edit,
  show,
  create,
  icon,
  options,
}: AuthenticatedResourceInput) => (
  <>
    <ResourceBreadcrumb name={name} />
    <Resource
      name={name}
      list={list}
      edit={edit}
      show={show}
      create={create}
      icon={icon}
      options={options}
    />
  </>
);

AuthenticatedResource.raName = Resource.raName;
AuthenticatedResource.registerResource = ({
  create,
  edit,
  icon,
  list,
  name,
  options,
  show,
}: AuthenticatedResourceInput, permissions: Actions[]) => ({
  name,
  options,
  hasList: !!list && hasAccess(permissions, getActionName(name, 'List')),
  hasCreate: !!create && hasAccess(permissions, getActionName(name, 'Create')),
  hasEdit: !!edit && hasAccess(permissions, getActionName(name, 'Get')),
  hasShow: !!show && hasAccess(permissions, getActionName(name, 'Get')),
  icon,
});

export default AuthenticatedResource;
