import React, { useState, useEffect } from 'react';

import {
  Button,
  Box,
  Typography,
  styled,
} from '@mui/material';

import {
  ChipField,
  FormDataConsumer,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectArrayInput,
  SingleFieldList,
} from 'react-admin';

import { useFormContext } from 'react-hook-form';

import CreateIcon from '@mui/icons-material/Create';
import CancelIcon from '@mui/icons-material/Cancel';

const Title = styled(Typography)({
  fontWeight: '400',
  fontSize: '13px',
  color: '#646464',
});

const GroupsField = ({ showEdit }: {showEdit: boolean}) => {
  const [editable, setEditable] = useState(false);

  const handleChange = () => {
    setEditable(!editable);
  };

  const { formState: { isSubmitSuccessful } } = useFormContext();

  useEffect(() => {
    if (isSubmitSuccessful) { setEditable(false); }
  }, [editable, isSubmitSuccessful]);

  if (!editable) {
    return (
      <Box>
        <Title>Groups</Title>
        <Box pt={1} display="flex" alignItems="center" justifyContent="space-between">
          <ReferenceArrayField page={1} perPage={200} label="Tags" reference="groups" source="groups">
            <FormDataConsumer>
              {({ formData }) => (formData?.groups?.length > 0 && (
                <SingleFieldList>
                  <ChipField source="name" />
                </SingleFieldList>
              )) || <Typography>None</Typography>}
            </FormDataConsumer>
          </ReferenceArrayField>

          { showEdit && (
            <Button startIcon={<CreateIcon color="secondary" />} size="small" onClick={handleChange}>
              Edit
            </Button>
          )}
        </Box>
      </Box>
    );
  }
  return (
    <Box>
      <Title>Groups</Title>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <ReferenceArrayInput page={1} perPage={200} label="Groups" source="groups" reference="groups">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <Button startIcon={<CancelIcon color="error" />} size="small" onClick={handleChange}>
          Cancel
        </Button>
      </Box>

    </Box>
  );
};

export default GroupsField;
