import { ValueOf } from '../utilities/types';
import languages, { Languages } from './languages';

const pepRoles = {
  HEAD_OF_STATE_OR_GOVERNMENT_MINISTER: 'headOfStateOrGovernmentMinister',
  LEGISLATIVE_MEMBER: 'legislativeMember',
  POLITICAL_PARTY_BOARD_MEMBER: 'politicalPartyBoardMember',
  HIGH_COURT_JUDGE: 'highCourtJudge',
  SENIOR_AUDIT_OR_CENTRAL_BANK_OFFICIAL: 'seniorAuditOrCentralBankOfficial',
  AMBASSADOR_MILITARY_OFFICER: 'ambassadorMilitaryOfficer',
  STATE_OWNED_ENTERPRISE_OFFICIAL: 'stateOwnedEnterpriseOfficial',
  LOCAL_COUNCIL: 'localCouncil',
  UNKNOWN: 'unknown',
} as const;

const pepRoleEnLabels = {
  [pepRoles.HEAD_OF_STATE_OR_GOVERNMENT_MINISTER]: 'Head of state or government, minister, deputy or assistant minister',
  [pepRoles.LEGISLATIVE_MEMBER]: 'Member of Parliament or member of a similar legislative body',
  [pepRoles.POLITICAL_PARTY_BOARD_MEMBER]: 'Member of the board of political parties',
  [pepRoles.HIGH_COURT_JUDGE]: 'Judge at the supreme court, constitutional court, or other high-level judicial bodies whose decisions can only exceptionally be appealed',
  [pepRoles.SENIOR_AUDIT_OR_CENTRAL_BANK_OFFICIAL]: 'Senior official at audit authorities or member of the governing body of the central bank',
  [pepRoles.AMBASSADOR_MILITARY_OFFICER]: 'Ambassador, head of mission, or high-ranking officer in the armed forces',
  [pepRoles.STATE_OWNED_ENTERPRISE_OFFICIAL]: 'Person who is part of the administrative, management, or control body of state-owned enterprises',
  [pepRoles.LOCAL_COUNCIL]: 'Person who is part of a regional or municipality council',
  [pepRoles.UNKNOWN]: 'Unknown role',
} as const;

const pepRoleSvLabels = {
  [pepRoles.HEAD_OF_STATE_OR_GOVERNMENT_MINISTER]: 'Stats- eller regeringschef, minister, vice- eller biträdande minister',
  [pepRoles.LEGISLATIVE_MEMBER]: 'Parlamentsledamot eller ledamot av liknande lagstriftande organ',
  [pepRoles.POLITICAL_PARTY_BOARD_MEMBER]: 'Ledamot i styrelse för politiska partier',
  [pepRoles.HIGH_COURT_JUDGE]: 'Domare i högsta domstol, konstitutionell domstol eller andra rättsliga organ på hög nivå vilkas beslut endast undantagsvis kan överklagas',
  [pepRoles.SENIOR_AUDIT_OR_CENTRAL_BANK_OFFICIAL]: 'Högre tjänsteman vid revisionsmyndigheter eller ledamot i centralbankens styrande organ',
  [pepRoles.AMBASSADOR_MILITARY_OFFICER]: 'Ambassadör, beskickningschef eller hög officerare i försvarsmakten',
  [pepRoles.STATE_OWNED_ENTERPRISE_OFFICIAL]: 'Person som ingår i statsägda företags förvaltnings-, lednings- eller kontrollorgan',
  [pepRoles.LOCAL_COUNCIL]: '',
  [pepRoles.UNKNOWN]: 'Okänd',
} as const;

const pepRoleLabels = {
  [languages.EN]: pepRoleEnLabels,
  [languages.SV]: pepRoleSvLabels,
} as const;

export const pepRoleChoices = (
  language: Languages,
) => Object.values(pepRoles).map((section) => ({
  id: section,
  name: pepRoleLabels[language][section],
}));

export type PepRoles = ValueOf<typeof pepRoles>;

export default pepRoles;
