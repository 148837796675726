import React from 'react';
import {
  ArrayInput,
  RadioButtonGroupInput,
  SimpleFormIterator,
  TextInput,
  minValue,
  required,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import { useWatch } from 'react-hook-form';

import NumberInput from '../../../layout/inputFields/NumberInput';
import InfoPopover from '../../../layout/InfoPopover';

const SOURCE = 'ruleParameters.zipCodes';

const ZipCodeBlocklist = () => {
  const shouldPurchase = useWatch({ name: 'ruleParameters.shouldPurchase', defaultValue: true });

  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography variant="label">Blocked zip codes</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <ArrayInput source={SOURCE} label={false}>
            <SimpleFormIterator
              fullWidth
              sx={{
                '& .RaSimpleFormIterator-line': {
                  borderBottom: 'none',
                  paddingY: 2,
                },
              }}
            >
              <TextInput fullWidth label="Zip code" source="" helperText={false} />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container direction="column">
            <Typography variant="label">Should purchase external data</Typography>
            <RadioButtonGroupInput
              defaultValue
              choices={[
                { id: true, name: 'Yes' },
                { id: false, name: 'No' },
              ]}
              parse={(r) => r === 'true'}
              source="ruleParameters.shouldPurchase"
              helperText={false}
              label={false}
              validate={required()}
            />
          </Grid>
        </Grid>
        {shouldPurchase && (
          <>
            <Grid item xs={12} md={12}>
              <Typography variant="label">Data update frequency</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <NumberInput
                fullWidth
                source="ruleParameters.updateFreq"
                label="Days"
                validate={[required(), minValue(0)]}
                popups={[
                  <InfoPopover key="update freq" title="Update frequency" content="The number of days between updates of the underlying data. Note that if the underlying data is older than the input number of days, the data will be re-purchased." />,
                ]}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ZipCodeBlocklist;
