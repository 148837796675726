import React, {
  useCallback, useMemo,
} from 'react';

import {
  RecordContextProvider,
  useGetOne,
  useRecordContext,
  useResourceContext,
} from 'react-admin';

import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Slide,
  Typography,
} from '@mui/material';

import ArrowBackIosFilled from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosFilled from '@mui/icons-material/ArrowForwardIos';

import { useNavigate, useParams } from 'react-router-dom';

import RenderElements from './RenderElements';
import UploadFiles from './UploadFiles';

import { Page } from '../types';

const FormNavigation = ({
  pages,
  currentPage,
  handelNavigation,
}: {
  pages: Page[],
  currentPage?: string,
  handelNavigation: (page: string) => void
}) => (
  <Box>
    <List disablePadding>
      {pages.map((page, i) => (
        <ListItem
          disablePadding
          key={page.url}
        >
          <ListItemButton
            selected={page.url === currentPage}
            onClick={() => handelNavigation(page.url)}
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#2380611F',
              },
              '&.Mui-selected:hover': {
                backgroundColor: '#2380611F',
              },
              '&:hover': {
                backgroundColor: '#2380611F',
              },
            }}
          >
            <Box display="flex" gap={2} py={2}>
              <Typography fontWeight="bold" variant="body2">
                {`${i + 1}. ${page.title}`}
              </Typography>
            </Box>
          </ListItemButton>
        </ListItem>
      ))}
      <ListItem
        disablePadding
      >
        <ListItemButton
          selected={currentPage === 'upload-files'}
          onClick={() => handelNavigation('upload-files')}
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#2380611F',
            },
            '&.Mui-selected:hover': {
              backgroundColor: '#2380611F',
            },
            '&:hover': {
              backgroundColor: '#2380611F',
            },
          }}
        >
          <Box display="flex" gap={2} py={2}>
            <Typography fontWeight="bold" variant="body2">
              {`${pages.length + 1}. Upload files`}
            </Typography>
          </Box>
        </ListItemButton>
      </ListItem>
    </List>
  </Box>
);

const KycFormView = () => {
  const record = useRecordContext();
  const redirect = useNavigate();
  const resource = useResourceContext();

  const {
    formId,
    formPage,
    id,
    kycFormId,
  } = useParams();

  const { data: form } = useGetOne('forms', { id: formId }, { enabled: !!formId });
  const { data: kycForm } = useGetOne('form-answers', { id: kycFormId }, { enabled: !!kycFormId });

  const currentPageObj = useMemo(
    () => form?.pages?.find((page: Page) => formPage === page.url),
    [form?.pages, formPage],
  );

  const currentPageNumber = useMemo(
    () => (form?.pages?.findIndex((page: Page) => formPage === page.url) ?? 0) + 1,
    [form?.pages, formPage],
  );

  const handelNavigation = useCallback((pageUrl: string) => {
    redirect(`/${resource}/${id}/form-answers/${formId}/${kycFormId}/${pageUrl}`);
  }, [formId, id, kycFormId, redirect, resource]);

  const handelForward = useCallback(() => {
    // current page already have + 1 to the index
    const nextUrl = form?.pages?.at(currentPageNumber)?.url;
    if (!nextUrl) {
      handelNavigation('upload-files');
    } else {
      handelNavigation(nextUrl);
    }
  }, [currentPageNumber, form?.pages, handelNavigation]);

  const handelBack = useCallback(() => {
    // current page already have + 1 to the index
    const backUrl = form?.pages?.at(currentPageNumber - 2)?.url;

    if (formPage === 'upload-files') {
      handelNavigation(form?.pages?.at(-1).url);
    } else {
      handelNavigation(backUrl);
    }
  }, [currentPageNumber, form?.pages, formPage, handelNavigation]);

  if (!record || !form) return null;

  if (!record) return null;

  return (
    <Slide in direction="right">
      <Grid container gap={1} columns={100}>
        <Grid item xs={100} md={100} mb={3}>
          <Box display="flex" alignItems="center">
            <IconButton size="medium" onClick={() => redirect(`/${resource}/${id}/form-answers`)}>
              <ArrowBackIosFilled fontSize="medium" color="primary" />
            </IconButton>
            <Typography color="primary" variant="h5">{form?.title}</Typography>
          </Box>
        </Grid>
        <Grid item xs={18} md={18} sx={{ backgroundColor: 'background.paper' }}>
          <Card variant="outlined" sx={{ m: 0, borderRadius: 1, height: '100%' }}>
            <FormNavigation
              pages={form.pages}
              currentPage={formPage}
              handelNavigation={handelNavigation}
            />
          </Card>
        </Grid>
        <Grid item xs={81} md={81} sx={{ backgroundColor: 'background.grey' }}>
          <Card
            variant="outlined"
            sx={{
              m: 0,
              p: 2,
              height: '100%',
              minHeight: 900,
              borderRadius: 1,
            }}
          >
            {currentPageObj && (
              <RenderElements
                kycForm={kycForm}
                language={form.language}
                elements={currentPageObj.elements}
                title={`${currentPageNumber}. ${currentPageObj.title}`}
              />
            )}
            {formPage === 'upload-files' && (
              <RecordContextProvider value={kycForm}>
                <UploadFiles pageNumber={(form?.pages?.length ?? 0) + 1} />
              </RecordContextProvider>
            )}
            <Box display="flex" justifyContent="space-between" px={8} py={2}>
              <Button onClick={handelBack} startIcon={<ArrowBackIosFilled />} sx={{ display: currentPageNumber === 1 ? 'none' : '' }}>
                <Typography fontSize={14} fontWeight="bold">Previous</Typography>
              </Button>
              <Box />
              <Button
                onClick={handelForward}
                endIcon={<ArrowForwardIosFilled />}
                sx={{ display: formPage === 'upload-files' ? 'none' : '' }}
              >
                <Typography fontSize={14} fontWeight="bold">Next</Typography>
              </Button>
            </Box>

          </Card>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default KycFormView;
