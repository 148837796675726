import { ValueOf } from '../utilities/types';

const ruleParameters = {
  AUDITOR_STATUSES: { id: 'auditorStatuses', name: 'Auditor statuses' },
  CATEGORY: { id: 'category', name: 'Category' },
  CHANGE_FREQ: { id: 'changeFreq', name: 'Change frequency', riskBased: true },
  CHANGE_MONTHS_LIMIT: { id: 'changeMonthsLimit', name: 'Period', unit: 'months' },
  COLLECT_FIELDS: { id: 'collectFields', name: 'Collect Fields' },
  COMMON_THRESHOLD: { id: 'commonThreshold', name: 'Common threshold' },
  CONNECTED_ENTITIES_TO_CHECK: { id: 'connectedEntitiesToCheck', name: 'Entities to check' },
  COUNTRIES: { id: 'countries', name: 'Countries' },
  COUNTRY_CODE: { id: 'countryCode', name: 'Country' },
  DEFAULT_COMMON_THRESHOLD: { id: 'default.commonThreshold', name: 'Default Common threshold' },
  DEFAULT_THRESHOLDS: { id: 'default.thresholds', name: 'Default Thresholds' },
  DEVIATION: { id: 'threshold', name: 'Deviation', unit: '%' },
  DIRECTION: { id: 'direction', name: 'Direction' },
  FORM_COMMON_THRESHOLD: { id: 'form.commonThreshold', name: 'Form Common threshold' },
  FORM_THRESHOLDS: { id: 'form.thresholds', name: 'Form Thresholds' },
  FROM: { id: 'timeWindow.from', name: 'From' },
  HIT_RATIO: { id: 'hitRatio', name: 'Hit ratio', unit: '%' },
  KYC_FORM_DATA_MAX_AGE: { id: 'kycFormDataMaxAge', name: 'KYC form max age', unit: 'days' },
  KYC_PARAMS: { id: 'kycParams', name: 'KYC parameters', unit: 'days' },
  LEGAL_SCORE: { id: 'legalScore', name: 'Legal score threshold' },
  LOWER_AGE_LIMIT: { id: 'lowerAgeLimit', name: 'Lower age threshold', unit: 'years' },
  LOWEST_ACCEPTABLE_INCOME: { id: 'threshold', name: 'Lowest acceptable income' },
  MINIMUM_NUMBER_OF_OBSERVATIONS: { id: 'minimumNumberOfObservations', name: 'Minimum number of observations' },
  MINIMUM_USAGE_TIME: { id: 'minimumUsageTime', subIds: ['amount', 'unit'], name: 'Minimum usage time' },
  MULTIPLIER: { id: 'multiplier', name: 'Multiplier' },
  PARAMETER_PRIORITY: { id: 'parameterPriority', name: 'Parameter priority' },
  PERIOD_IN_MONTHS: { id: 'periodInMonths', name: 'Period', unit: 'months' },
  PERIOD_TO_CONSIDER: { id: 'periodToConsider', subIds: ['amount', 'unit'], name: 'Period to consider' },
  PERIOD: { id: 'period', subIds: ['amount', 'unit'], name: 'Period' },
  SHOULD_PURCHASE: { id: 'shouldPurchase', name: 'Should purchase data' },
  SNI_CODES: { id: 'sniCodes', name: 'Blocked SNI codes' },
  TO: { id: 'timeWindow.to', name: 'To' },
  TYPE_OF_CHECK: { id: 'typeOfCheck', name: 'Type of check' },
  UNACCEPTABLE_EMPLOYMENT_TYPES: { id: 'unacceptableEmploymentTypes', name: 'Unacceptable employment statuses' },
  UNACCEPTABLE_SOURCE_FUNDS: { id: 'unacceptableSourceOfFunds', name: 'Unacceptable source of funds' },
  UNACCEPTABLE_SOURCE_INCOME: { id: 'unacceptableSourceOfIncomes', name: 'Unacceptable source of income' },
  UPDATE_FREQ: { id: 'updateFreq', name: 'Data update frequency', unit: 'days' },
  UPPER_AGE_LIMIT: { id: 'upperAgeLimit', name: 'Upper age threshold', unit: 'years' },
  VALUATION_DEVIATION: { id: 'valuationDeviation', name: 'Valuation deviation' },
  VALUATION_PREFIX: { id: 'valuationPrefix', name: 'Prefix' },
  WINDOW_PERIOD: { id: 'windowPeriod', subIds: ['amount', 'unit'], name: 'Window period' },
  ZIP_CODES: { id: 'zipCodes', name: 'Blocked zip codes' },
} as const;

export type RuleParameters = ValueOf<typeof ruleParameters>

export type RuleParametersKeys = keyof typeof ruleParameters

export default ruleParameters;
