import React, { useCallback } from 'react';

import {
  Identifier,
  RaRecord,
  ReferenceManyField,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';

import {
  Box,
  Button, Card, Dialog, DialogActions, DialogContent, Grid,
} from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash';
import useDialogStatus from '../../hooks/useDialogStatus';

import Pagination from '../layout/Pagination';
import SearchField from '../layout/SearchField';

import { MemberListBody } from '../member/MemberList';
import NoResults from '../layout/NoResults';
import { Member } from '../../utilities/schemas/member';
import { Team } from '../../utilities/schemas/team';

export const transform = (record: RaRecord) => {
  if (!record) return null;

  return ({
    ...record,
    members: record.members.map(({ id }: { id: Identifier }) => id),
  });
};

const RemoveButton = ({ team }: { team: string }) => {
  const record = useRecordContext<Member>();
  const refresh = useRefresh();

  const [remove] = useUpdate(
    'members',
    {
      id: record?.id,
      data: {
        ...record,
        teams: record?.teams.filter((MemberTeam) => team !== MemberTeam),
      },
    },
    {
      mutationMode: 'pessimistic',
      onSuccess: () => {
        refresh();
      },
    },
  );

  const handleClick = useCallback(() => {
    remove();
  }, [remove]);

  return <Button startIcon={<DeleteForeverIcon />} onClick={handleClick} color="error">Remove</Button>;
};

const AddButton = ({ included, team }: { included?: Identifier[], team: Identifier }) => {
  const record = useRecordContext<Member>();
  const refresh = useRefresh();

  const [add] = useUpdate(
    'members',
    {
      id: record?.id,
      data: {
        ...record,
        teams: [...(record?.teams ?? []), team],
      },
    },
    {
      mutationMode: 'pessimistic',
      onSuccess: () => {
        refresh();
      },
    },
  );

  const handleClick = useCallback(() => {
    add();
  }, [add]);

  if (!record) return null;

  return (
    <Button
      startIcon={<AddIcon />}
      onClick={handleClick}
      disabled={included?.includes(record.id)}
      color="primary"
    >
      Add
    </Button>
  );
};

const AddDialog = ({ included, team }: { included: Identifier[], team: Identifier }) => {
  const filter = useWatch({ name: 'filter' });
  return (
    <>
      <Box display="flex" gap={2}>
        <SearchField source="filter.email" label="Email" />
        <SearchField source="filter.firstName" label="First name" />
        <SearchField source="filter.lastName" label="Last name" />
      </Box>
      <Card variant="outlined">
        <ReferenceManyField
          reference="members"
          target="_"
          filter={{ active: true, ...filter }}
          sort={{ field: 'date', order: 'DESC' }}
          pagination={<Pagination disableEmptyText />}
          fullWidth
        >
          <MemberListBody CustomButton={<AddButton included={included} team={team} />} />
        </ReferenceManyField>
      </Card>
    </>
  );
};

const TeamMembers = () => {
  const { open, closeDialog, openDialog } = useDialogStatus();
  const record = useRecordContext<Team>();

  if (!record) return null;
  return (
    <Grid container>
      <Grid item xs={12} md={12} display="flex" justifyContent="flex-end">
        <Button startIcon={<AddIcon />} variant="contained" onClick={openDialog}>Add Members</Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          {(!isEmpty(record.members) && (
            <ReferenceManyField
              reference="members"
              target="id"
              source="members"
              filter={{ active: true }}
              sort={{ field: 'date', order: 'DESC' }}
              pagination={<Pagination disableEmptyText />}
              fullWidth
            >
              <MemberListBody
                CustomButton={<RemoveButton team={record?.id} />}
              />
            </ReferenceManyField>
          )) || <NoResults variant="h6" />}
        </Card>
      </Grid>
      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xl">
        <DialogContent>
          <AddDialog included={record?.members} team={record?.id} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" startIcon={<CloseIcon />} onClick={closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Grid>

  );
};
export default TeamMembers;
