import React from 'react';

import {
  minValue,
  required,
  SelectInput,
} from 'react-admin';

import {
  Grid,
} from '@mui/material';

import ThresholdInput from '../ruleCommon/ThresholdInput';

import NumberInput from '../../../layout/inputFields/NumberInput';

import Label from '../../../../customFields/Label';

import timePeriods from '../../../../constants/timePeriods';

const CashFlow = () => (
  <Grid item xs={12} md={12}>
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <SelectInput
              source="ruleParameters.direction"
              label="Direction of cash flow"
              fullWidth
              choices={[
                {
                  id: 'incoming-outgoing', name: 'Incoming volume minus outgoing volume',
                },
                {
                  id: 'outgoing-incoming', name: 'Outgoing volume minus incoming volume',
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <ThresholdInput source="default" />
          </Grid>
          <Grid item xs={12} md={12}>
            <Label
              variant="label"
              title="Period"
              info="The period that the threshold will be checked against."
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NumberInput
              source="ruleParameters.period.amount"
              fullWidth
              label="Length"
              validate={[required(), minValue(1)]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              source="ruleParameters.period.unit"
              choices={Object.values(timePeriods).map((period) => ({ id: period, name: period }))}
              fullWidth
              label="Period"
              validate={[required(), minValue(1)]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>

);

export default CashFlow;
