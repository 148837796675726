import React, { useCallback } from 'react';

import {
  LinearProgress,
  RecordContextProvider,
  ReferenceManyField,
  TextField,
  useGetOne,
  useRedirect,
} from 'react-admin';

import { Edge, Node } from 'reactflow';

import {
  Button,
  Box,
  SwipeableDrawer,
  Typography,
  Divider,
  Grid,

} from '@mui/material';

import Close from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';

import Pagination from '../../../layout/Pagination';

import { NationalIdentifierSingleField } from '../../../../customFields/NationalIdentifier';

import { requestListMap } from '../../../../constants/requestFeatures';
import entityTypes from '../../../../constants/entityTypes';
import { requestTypes } from '../../../../constants/requestTypes';

interface Props {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  currency: string,
  edge?: Edge<{
    volume: number,
    frequency: number
    grouped: string []
  }>
  node?: Node
}

const TextBox = ({ source, label }: {source: string, label: string}) => (
  <Grid item xs={12} md={3}>
    <Box display="flex" flexDirection="column">
      <Typography variant="label">{label}</Typography>
      <TextField source={source} emptyText="-" />
    </Box>
  </Grid>
);

const IndividualBody = ({ id }: {id: string}) => {
  const { data, isLoading } = useGetOne('individuals', { id });

  if (isLoading) return <LinearProgress sx={{ width: '100%' }} />;

  if (!data) return null;

  return (
    <RecordContextProvider value={data}>
      <Grid container spacing={6}>
        <TextBox source="id" label="ID" />
        <TextBox source="customId" label="Custom ID" />
        <TextBox source="firstName" label="First name" />
        <TextBox source="lastName" label="Last name" />
        <Grid item xs={12} md={12}>
          <Box display="flex" flexDirection="column">
            <Typography variant="label">National identifiers</Typography>
            <NationalIdentifierSingleField limit={5} underline={false} />
          </Box>
        </Grid>
      </Grid>
    </RecordContextProvider>
  );
};

const AccountBody = ({ id }: {id: string}) => {
  const { data, isLoading } = useGetOne('account-entities', { id });

  if (isLoading) return <LinearProgress sx={{ width: '100%' }} />;

  if (!data) return null;

  return (
    <RecordContextProvider value={data}>
      <Grid container spacing={6}>
        <TextBox source="id" label="ID" />
        <TextBox source="accountName" label="Account name" />
        <TextBox source="bic" label="BIC" />
        <TextBox source="provider" label="Provider" />
        <TextBox source="iban" label="IBAN" />
        <TextBox source="bban" label="BBAN" />
      </Grid>
    </RecordContextProvider>
  );
};

const BusinessBody = ({ id }: {id: string}) => {
  const { data, isLoading } = useGetOne('businesses', { id });

  if (isLoading) return <LinearProgress sx={{ width: '100%' }} />;

  if (!data) return null;

  return (
    <RecordContextProvider value={data}>
      <Grid container spacing={6}>
        <TextBox source="id" label="ID" />
        <TextBox source="customId" label="Custom ID" />
        <TextBox source="name" label="Name" />
        <TextBox source="registrationNumber" label="Registration number" />
        <TextBox source="residence" label="Country of registration" />
      </Grid>
    </RecordContextProvider>
  );
};

const EntityBody = ({ node }: {node: Node}) => {
  if (node.type === entityTypes.INDIVIDUAL) {
    return <IndividualBody id={node.id} />;
  }

  if (node.type === entityTypes.BUSINESS) {
    return <BusinessBody id={node.id} />;
  }

  if (node.type === entityTypes.ACCOUNT) {
    return <AccountBody id={node.id} />;
  }

  return null;
};

const Stat = ({
  value,
  label,
  unit,
}: {
  value?: number;
  label: string;
  unit?: string
}) => {
  if (!value) return null;
  return (
    <Box display="flex" gap={1}>
      <Typography fontWeight="bold">
        {label}
        :
      </Typography>
      <Typography>{value}</Typography>
      {unit && <Typography>{unit}</Typography>}
    </Box>
  );
};

const TransactionListComponent = requestListMap[requestTypes.TRANSACTION];

const Drawer = ({
  open,
  setOpen,
  currency,
  edge,
  node,
}: Props) => {
  const redirect = useRedirect();

  const toggleDrawer = useCallback((newOpen: boolean) => () => {
    setOpen(newOpen);
  }, [setOpen]);

  const handleRedirect = useCallback(() => {
    if (!node) return;

    if (node.type === entityTypes.INDIVIDUAL) {
      redirect('edit', 'individuals', node.id);
    }

    if (node.type === entityTypes.BUSINESS) {
      redirect('edit', 'businesses', node.id);
    }
  }, [node, redirect]);

  return (
    <Box sx={{
      '.MuiDrawer-root > .MuiPaper-root': {
        height: edge ? 'calc(65%)' : 'calc(30%)',
        overflow: 'visible',
      },
    }}
    >
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        variant="persistent"
        sx={{
          '& .MuiDrawer-root': {
            position: 'absolute',
          },
          '& .MuiPaper-root': {
            position: 'absolute',
          },
        }}
      >
        <Box
          sx={{
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Box px={4} py={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" gap={8}>
              {edge && <Stat label="Volume" value={edge.data?.volume} unit={currency} />}
              {node
              && <Button onClick={handleRedirect} startIcon={<LaunchIcon />}> See Profile</Button>}
            </Box>
            <Button startIcon={<Close />} onClick={toggleDrawer(false)}>Close</Button>
          </Box>
          <Divider />
          {edge && (
            <ReferenceManyField
              filter={{ id: edge?.data?.grouped, requestType: requestTypes.TRANSACTION }}
              perPage={5}
              pagination={<Pagination disableEmptyText />}
              reference="requests"
              target="_"
              sort={{ field: 'extraInformation.date', order: 'DESC' }}
            >
              <TransactionListComponent />
            </ReferenceManyField>
          )}
          {node
          && (
            <Box padding="1%">
              <EntityBody node={node} />
            </Box>
          )}
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

export default Drawer;
