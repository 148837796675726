import React from 'react';

import {
  Grid,
} from '@mui/material';

import { useRecordContext } from 'react-admin';
import SummaryCard from './SummaryCard';
import RuleLogic from './RuleLogic';
import RuleTriggers from './RuleTriggers';
import RuleResponse from './RuleResponse';
import RuleScore from './RuleScore';
import NotifyEmail from '../../NotifyEmail';
import TeamArraySelectInput from '../../../../../customFields/TeamArraySelectInput';

const RuleSummary = () => {
  const record = useRecordContext();
  const isVersion = !!record?.version;
  const createPing = record?.createPing;
  return (
    <Grid>
      <SummaryCard edit={!isVersion} path="" title="Rule logic">
        <RuleLogic />
      </SummaryCard>
      <SummaryCard edit={!isVersion} path="triggers" title="Rule triggers">
        <RuleTriggers />
      </SummaryCard>
      <SummaryCard edit={!isVersion} path="response" title="Rule response">
        <RuleResponse />
      </SummaryCard>
      {createPing && (
        <SummaryCard edit={!isVersion} path="score" title="Score calculation">
          <RuleScore />
        </SummaryCard>
      )}
      <Grid item xs={12} md={12}>
        <TeamArraySelectInput edit={!isVersion} />
      </Grid>
      <Grid item xs={12} md={12}>
        <NotifyEmail edit={!isVersion} />
      </Grid>
    </Grid>
  );
};

export default RuleSummary;
