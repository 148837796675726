import { useRecordContext } from 'react-admin';
import { useRegisterBreadcrumb } from '../layout/navbar/BreadcrumbsManager';

const getFullName = (record: any) => {
  if (!record?.firstName || !record?.lastName) return undefined;
  return `${record.firstName} ${record.lastName}`;
};

const IndividualBreadcrumb = () => {
  const record = useRecordContext();
  useRegisterBreadcrumb({
    title: getFullName(record)
       ?? record?.customId ?? record?.id,
    path: `/individuals/${record?.id}`,
  });

  return null;
};

export default IndividualBreadcrumb;
