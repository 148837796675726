import React from 'react';
import {
  SaveButton,
  Toolbar,
  NumberInput,
  required,
  minValue,
  maxValue,
  RecordContextProvider,
  useRecordContext,
  TextInput,
  usePermissions,
} from 'react-admin';

import { useFormContext } from 'react-hook-form';

import {
  Box,
  Typography,
} from '@mui/material';

import hasAccess from '../../utilities/hasAccess';

import EditDialog from '../layout/dialog/EditDialog';
import InfoPopover from '../layout/InfoPopover';

import RiskClassInput from '../../customFields/RiskClassInput';
import ActivationButton from '../../customFields/ActivationButton';

import actions, { Actions } from '../../constants/actions';

const CustomToolbar = () => {
  const { formState: { isValid } } = useFormContext();
  const { permissions } = usePermissions<Actions[]>();

  return (
    <Toolbar sx={{ backgroundColor: 'transparent' }}>
      <Box display="flex" width="100%" justifyContent="space-between">
        {hasAccess(permissions, actions.GROUP_UPDATE_ACTIVATION) && (
          <Box display="flex" marginLeft={2}>
            <ActivationButton />
          </Box>
        )}
        <SaveButton
          disabled={!isValid}
          icon={null as any}
        />
      </Box>
    </Toolbar>
  );
};

const GroupEdit = () => {
  const record = useRecordContext();

  return (
    <EditDialog customToolbar={<CustomToolbar />} title="group" action={actions.GROUP_UPDATE}>
      <RecordContextProvider value={record}>
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" width="100%">
          <Box width="80%">
            <Box display="flex" alignItems="center">
              <Typography variant="mediumTitle">Group Name</Typography>
              <InfoPopover title="Group Name" content="Unique name for the group." />
            </Box>
            <TextInput fullWidth validate={required()} source="name" />
          </Box>
          <Box width="80%">
            <Box display="flex" alignItems="center">
              <Typography variant="mediumTitle">Default risk</Typography>
              <InfoPopover title="Default risk" content="Probability of financial crime activity given which entity is in that group." />
            </Box>
            <NumberInput fullWidth validate={[required(), maxValue(99), minValue(0)]} source="score" label="Default risk (%)" />
          </Box>
          <Box width="80%">
            <Box display="flex" alignItems="center">
              <Typography variant="mediumTitle">
                Corresponding risk class
              </Typography>
              <InfoPopover title="Corresponding risk classes" content="The risk class corresponding to the default risk chosen." />
            </Box>
            <RiskClassInput source="score" />
          </Box>
        </Box>
      </RecordContextProvider>
    </EditDialog>
  );
};

export default GroupEdit;
