import { Node, Edge, MarkerType } from 'reactflow';

// eslint-disable-next-line import/extensions, import/no-extraneous-dependencies
import ELK, { ElkNode } from 'elkjs/lib/elk.bundled.js';

import {
  isEmpty, max, maxBy, uniqBy,
} from 'lodash';
import React from 'react';

import theme from '../../../../theme';

type Data = {
  id: string,
  edges: Edge<{
    volume: number,
    frequency: number
    grouped: string []
  }>[]
  nodes: Node[]
}

const freqColor = (frequencyRatio: number) => {
  if (frequencyRatio > 0.80) return theme.palette.red.main; // red

  if (frequencyRatio > 0.50) return theme.palette.orange.main; // orange

  if (frequencyRatio > 0.20) return theme.palette.yellow.main; // yellow

  return theme.palette.primary.main; // default to green
};

const getStyle = (
  edge: Edge,
  edges: Edge[],
  maxVolume: number,
  maxFreq: number,
) => {
  let volume = edge.data?.volume ?? 1;
  let frequency = edge.data?.frequency ?? 1;

  const oppositeEdge = edges.find(
    (edgeItem) => edgeItem.target === edge.source && edgeItem.source === edge.target,
  );

  if (oppositeEdge) {
    volume = max([oppositeEdge.data!.volume, volume])!;
    frequency = max([oppositeEdge.data!.frequency, frequency])!;
  }

  const strokeWidth = Math.min(4, Math.max(1, Math.round((volume / maxVolume) * 3 + 1)));

  const color = freqColor(frequency / maxFreq);

  return {
    strokeWidth,
    color,
  };
};

export const getPositions = async ({ edges, nodes }: {
  edges?: Edge[], nodes?: Node[]}) => {
  if (isEmpty(edges) && isEmpty(nodes)) {
    return {
      dataEdges: [],
      dataNodes: [],
    };
  }

  const graph = {
    id: 'root',
    children: nodes?.map((node) => ({
      ...node,
      width: 45,
      height: 45,
      data: node.data,
    })),
    edges,
  };

  const elk = new ELK({
    defaultLayoutOptions: {
      'elk.algorithm': 'layered',
      'elk.direction': 'DOWN',
      'elk.spacing.nodeNode': '25',
      'elk.layered.spacing.nodeNodeBetweenLayers': '75',
      'elk.layered.spacing': '50',
      'elk.spacing': '50',
      'elk.spacing.individual': '50',
      'elk.edgeRouting': 'SPLINES',
    },
  });

  const graphResult = await elk.layout(graph as unknown as ElkNode);

  const maxVolume = maxBy(edges, (edge) => edge.data?.volume)?.data?.volume ?? 1;

  const maxFreq = maxBy(edges, (edge) => edge.data?.frequency)?.data?.frequency ?? 1;

  return {
    dataEdges: graphResult.edges
      ?.map((edge, i) => {
        const { color, strokeWidth } = getStyle(edges![i], edges!, maxVolume, maxFreq);

        return {
          ...edges![i],
          ...edge,
          style: {
            strokeWidth,
            stroke: color,
          },
          ...((edge as unknown as Edge).data.switchStates.includes('forward') ? {
            markerEnd: {
              type: MarkerType.ArrowClosed,
              color,
            },
          } : {}),
          ...((edge as unknown as Edge).data.switchStates.includes('reversed') ? {
            markerStart: {
              type: MarkerType.ArrowClosed,
              color,
            },
          } : {}),
        };
      }),
    dataNodes: graphResult.children
      ?.map((child, i) => ({
        ...nodes![i],
        ...child,
        position: { x: child.x ?? 0, y: child.y ?? 0 },
      })),
  };
};

export const updateTree = async ({
  data,
  setEdges,
  setNodes,
  usePrevState,
  setUsePrevState,
  edges,
  nodes,
}: {
  data: Data[];
  setEdges: React.Dispatch<React.SetStateAction<Edge<{
    volume: number, frequency: number, grouped: string[],
  }>[]>>;
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>;
  usePrevState: boolean;
  setUsePrevState: React.Dispatch<React.SetStateAction<boolean>>;
  edges: Edge[];
  nodes: Node[];
}) => {
  if (usePrevState) {
    const positionedTree = await getPositions({
      edges: uniqBy([...edges, ...data.at(0)!.edges], 'id'),
      nodes: uniqBy([...nodes, ...data.at(0)!.nodes], 'id'),
    });
    if (positionedTree?.dataEdges) setEdges(positionedTree.dataEdges);
    if (positionedTree?.dataNodes) setNodes(positionedTree.dataNodes);
  } else {
    const positionedTree = await getPositions({
      edges: data.at(0)!.edges,
      nodes: data.at(0)!.nodes,
    });
    if (positionedTree?.dataEdges) setEdges(positionedTree.dataEdges);
    if (positionedTree?.dataNodes) setNodes(positionedTree.dataNodes);
    setUsePrevState(true);
  }
};
