import React from 'react';

import {
  defaultI18nProvider,
  AdminContext,
  AdminUI,
  CustomRoutes,
  Notification,
  Authenticated,
} from 'react-admin';

import { Route, BrowserRouter } from 'react-router-dom';

import BusinessIcon from '@mui/icons-material/Business';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DescriptionIcon from '@mui/icons-material/Description';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import HistoryIcon from '@mui/icons-material/History';
import PeopleIcon from '@mui/icons-material/People';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import TuneIcon from '@mui/icons-material/Tune';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import RouteIcon from '@mui/icons-material/Route';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import NotificationsIcon from '@mui/icons-material/Notifications';

import ApiKeyList from './components/apiKeys/ApiKeyList';
import ApiKeyCreate from './components/apiKeys/ApiKeyCreate';
import ApiKeyEdit from './components/apiKeys/ApiKeyEdit';
import AuditList from './components/audit/AuditList';
import BusinessList from './components/business/BusinessList';
import BusinessEdit from './components/business/BusinessEdit';
import BusinessCreate from './components/business/BusinessCreate';
import CarList from './components/car/CarList';
import CarEdit from './components/car/CarEdit';
import CarCreate from './components/car/CarCreate';
import CaseList from './components/case/CaseList';
import CaseEdit from './components/case/CaseEdit';
import IndividualList from './components/individual/IndividualList';
import IndividualsEdit from './components/individual/IndividualEdit';
import IndividualCreate from './components/individual/IndividualCreate';
import MemberEdit from './components/member/MemberEdit';
import MemberList from './components/member/MemberList';
import MemberCreate from './components/member/MemberCreate';
import PingList from './components/ping/PingList';
import PingShow from './components/ping/PingShow';
import RequestList from './components/request/RequestList';
import RequestCreate from './components/request/RequestCreate';
import RequestEdit from './components/request/RequestEdit';
import RuleCreate from './components/rules/RulesCreate';
import RuleList from './components/rules/RulesList';
import RuleEdit from './components/rules/RulesEdit';
import RiskClassList from './components/riskClass/RiskClassList';
import ReportList from './components/reports/ReportList';
import ReportCreate from './components/reports/ReportCreate';
import TeamList from './components/team/TeamList';
import TeamEdit from './components/team/TeamEdit';
import TeamCreate from './components/team/TeamCreate';
import ProcessList from './components/process/ProcessList';
import Login from './components/authentication/login/Login';
import ResetPasswordPage from './components/authentication/resetpassword/ResetPasswordPage';
import ForgetPasswordPage from './components/authentication/forgetpassword/ForgetPasswordPage';

import Dashboard from './components/dashboard/Dashboard';

import config from './config';
import Layout from './Layout';
import theme from './theme';

import AuthProvider from './utilities/Authprovider';
import addUploadFeature from './utilities/dataProvider/addUploadFeature';
import AuthenticatedResource from './components/authentication/AuthenticatedResource';
import { SAR, StartPage, STR } from './components/fipo';

import menuGroups from './constants/menuGroups';
import AccountEntityList from './components/accountEntity/AccountEntityList';
import AccountEntityEdit from './components/accountEntity/AccountEntityEdit';
import getDataProvider from './utilities/dataProvider/getDataProvider';
import GroupList from './components/group/GroupList';
import SignalList from './components/signal/SignalList';
import queryClient from './utilities/queryClient';

const dataProvider = getDataProvider(config.apiUrl);
const uploadCapableDataProvider = addUploadFeature(dataProvider);

const App = () => (
  <BrowserRouter>
    <AdminContext
      dataProvider={uploadCapableDataProvider}
      theme={theme}
      queryClient={queryClient}
      authProvider={AuthProvider}
      i18nProvider={defaultI18nProvider}
    >
      <AdminUI
        title="Pingwire"
        layout={Layout}
        notification={Notification}
        loginPage={Login}
        dashboard={Dashboard}
      >
        <AuthenticatedResource
          name="account-entities"
          list={AccountEntityList}
          edit={AccountEntityEdit}
          icon={AccountBalanceIcon}
          options={{ label: 'Accounts', group: menuGroups.ENTITIES }}
        />
        <AuthenticatedResource
          name="businesses"
          list={BusinessList}
          edit={BusinessEdit}
          create={BusinessCreate}
          icon={BusinessIcon}
          options={{ group: menuGroups.ENTITIES }}
        />
        <AuthenticatedResource
          name="cars"
          list={CarList}
          edit={CarEdit}
          create={CarCreate}
          icon={DriveEtaIcon}
          options={{ group: menuGroups.ENTITIES }}
        />
        <AuthenticatedResource
          name="individuals"
          list={IndividualList}
          edit={IndividualsEdit}
          create={IndividualCreate}
          icon={PersonPinIcon}
          options={{ group: menuGroups.ENTITIES }}
        />
        <AuthenticatedResource
          name="risk-classes"
          list={RiskClassList}
          icon={CrisisAlertIcon}
          options={{ label: 'Risk Classes', group: menuGroups.RISK_SETUP }}
        />
        <AuthenticatedResource
          name="groups"
          list={GroupList}
          icon={WorkspacesIcon}
          options={{ group: menuGroups.RISK_SETUP }}
        />
        <AuthenticatedResource
          name="processes"
          list={ProcessList}
          icon={RouteIcon}
          options={{ group: menuGroups.RISK_SETUP }}
        />
        <AuthenticatedResource
          name="signals"
          list={SignalList}
          icon={NotificationsIcon}
          options={{ group: menuGroups.RISK_SETUP }}
        />
        <AuthenticatedResource
          name="cases"
          list={CaseList}
          edit={CaseEdit}
          icon={DescriptionIcon}
          options={{ group: menuGroups.RISK_MONITORING }}
        />
        <AuthenticatedResource
          name="pings"
          list={PingList}
          show={PingShow}
          icon={EmojiObjectsIcon}
          options={{ group: menuGroups.RISK_MONITORING }}
        />
        <AuthenticatedResource
          name="requests"
          list={RequestList}
          edit={RequestEdit}
          create={RequestCreate}
          icon={DoubleArrowIcon}
          options={{ group: menuGroups.RISK_MONITORING }}
        />
        <AuthenticatedResource
          name="members"
          list={MemberList}
          create={MemberCreate}
          edit={MemberEdit}
          icon={PeopleIcon}
          options={{ group: menuGroups.ADMINISTRATION }}
        />
        <AuthenticatedResource
          name="api-keys"
          list={ApiKeyList}
          icon={VpnKeyIcon}
          create={ApiKeyCreate}
          edit={ApiKeyEdit}
          options={{ label: 'API Keys', group: menuGroups.ADMINISTRATION }}
        />
        <AuthenticatedResource
          name="audit-tracks"
          list={AuditList}
          icon={HistoryIcon}
          options={{ label: 'Audit Tracks', group: menuGroups.ADMINISTRATION }}
        />
        <AuthenticatedResource
          name="reports"
          list={ReportList}
          create={ReportCreate}
          icon={AssessmentIcon}
          options={{ group: menuGroups.RISK_MONITORING }}
        />
        <AuthenticatedResource
          name="rules"
          list={RuleList}
          edit={RuleEdit}
          create={RuleCreate}
          icon={TuneIcon}
          options={{ group: menuGroups.RISK_SETUP }}
        />
        <AuthenticatedResource
          name="teams"
          list={TeamList}
          edit={TeamEdit}
          create={TeamCreate}
          icon={EngineeringIcon}
          options={{ group: menuGroups.ADMINISTRATION }}
        />
        <AuthenticatedResource
          name="scores"
        />
        <AuthenticatedResource
          name="form-answers"
        />
        <AuthenticatedResource
          name="bank-data"
        />
        <AuthenticatedResource
          name="rule-version"
        />
        <AuthenticatedResource
          name="data-points"
        />
        <AuthenticatedResource
          name="data-point-sources"
        />
        <AuthenticatedResource
          name="connections"
        />
        <CustomRoutes noLayout>
          <Route path="/forget-password/:alias" element={<ForgetPasswordPage />} />
          <Route path="/set-password" element={<ResetPasswordPage />} />
        </CustomRoutes>
        <CustomRoutes noLayout>
          <Route path="/fipo">
            <Route path=":id" element={<Authenticated><StartPage /></Authenticated>} />
            <Route path=":id/STR" element={<Authenticated><STR /></Authenticated>}>
              <Route path=":path" element={<Authenticated><STR /></Authenticated>} />
            </Route>
            <Route path=":id/SAR" element={<Authenticated><SAR /></Authenticated>}>
              <Route path=":path" element={<Authenticated><SAR /></Authenticated>} />
            </Route>
          </Route>
        </CustomRoutes>
      </AdminUI>
    </AdminContext>
  </BrowserRouter>
);

export default App;
