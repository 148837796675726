import React, { useCallback } from 'react';

import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';

import ArrowBackIosFilled from '@mui/icons-material/ArrowBackIos';

import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  RecordContextProvider,
  ReferenceField,
  TextField,
  useGetOne,
  useRecordContext,
} from 'react-admin';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { isArray, isPlainObject } from 'lodash';

import { ConflictFields } from '../../constants/conflictFields';
import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import { ViewJSONDialog } from '../../customFields/ViewJSON';

const ValueField = ({ label }: {label: string}) => {
  const record = useRecordContext();

  const valueIsComplex = (isArray(record?.value) && record?.value?.every(isPlainObject))
  || isPlainObject(record?.value);

  if (!valueIsComplex) return <Typography>{record?.value ?? ''}</Typography>;

  return <ViewJSONDialog title={label} source="value" />;
};

const ConflictResolutionHistory = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const name = searchParams.get('name') as ConflictFields;

  const { data } = useGetOne('resolution-history', { id: `${name}/${id}` });

  const handelBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Slide in direction="right">
      <Grid container gap={1}>
        <Grid item xs={12} md={12} mb={3}>
          <Box display="flex" alignItems="center">
            <IconButton size="medium" onClick={handelBack}>
              <ArrowBackIosFilled fontSize="medium" color="primary" />
            </IconButton>
            <Typography color="primary" variant="h5">Back</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12} md={12} sx={{ backgroundColor: 'background.paper' }}>
              <Card
                variant="outlined"
                sx={{
                  m: 0,
                  p: 2,
                  borderRadius: 1,
                }}
              >
                <CardContent>
                  <Typography variant="h5" color="primary">Resolution History</Typography>
                  <RecordContextProvider value={data}>
                    <Card variant="outlined">
                      <ArrayField source="history">
                        <Datagrid
                          sx={boldDataGridStyle}
                          bulkActionButtons={false}
                        >
                          <DateField source="createdAt" showTime label="Timestamp" emptyText="-" />
                          <ValueField label="Value" />
                          <TextField source="comment" emptyText="-" />
                          <ReferenceField label="User" source="createdBy" reference="members" emptyText="System">
                            <FunctionField render={(member: {firstName: string, lastName: string}) => `${member.firstName} ${member.lastName}`} />
                          </ReferenceField>
                        </Datagrid>
                      </ArrayField>
                    </Card>
                  </RecordContextProvider>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default ConflictResolutionHistory;
