const uploadDocument = (
  url: string,
  files: { fileName: string; file: File }[],
) => new Promise((resolve, reject) => {
  const formData = new window.FormData();
  files.forEach(({ fileName, file }) => {
    formData.append(fileName, file);
  });

  const request = new window.XMLHttpRequest();

  request.open('POST', url);
  request.setRequestHeader('authorization', `Bearer ${window.localStorage.getItem('auth')}`);
  request.onload = () => {
    if (request.readyState === request.DONE) {
      if (request.status >= 400) {
        reject(JSON.parse(request.response));
      } else {
        resolve(JSON.parse(request.response));
      }
    }
  };

  request.send(formData);
});

export default uploadDocument;
