import React from 'react';
import {
  ReferenceArrayField,
  SingleFieldList,
  RaRecord,
  RecordContextProvider,
} from 'react-admin';

import { isEmpty } from 'lodash';

import { Box, Typography } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { ruleNameChoices } from '../../ruleFeatures';
import TeamChip from '../../../../customFields/TeamChip';
import { Rule } from '../../../../utilities/schemas/rules';

const Title = ({ rule }: { rule: Rule }) => {
  const isRiskBased = rule?.ruleParameters?.thresholds
    ?.some((threshold: RaRecord) => !isEmpty(threshold.riskBased));

  return (
    <Box gap={2} display="flex" flexDirection="row" alignItems="center">
      <Typography fontWeight="bold">{ruleNameChoices.find((item) => item.id === rule.name)?.name}</Typography>
      {isRiskBased && (
        <Box display="flex" flexDirection="row">
          <CheckCircleIcon color="secondary" />
          <Typography>Risk-Based</Typography>
        </Box>
      )}
      <RecordContextProvider
        value={rule}
      >
        <ReferenceArrayField source="teams" reference="teams" label="Teams" emptyText="-">
          <SingleFieldList placeholder="-">
            <TeamChip small />
          </SingleFieldList>
        </ReferenceArrayField>
      </RecordContextProvider>
    </Box>
  );
};

export default Title;
