import React from 'react';

import {
  get,
  isArray,
  isObject,
  isString,
  lowerCase,
  startCase,
} from 'lodash';

import {
  useRecordContext,
  TextField,
  RaRecord,
  SelectField,
} from 'react-admin';

import {
  Box,
  Grid,
  Typography,
} from '@mui/material';

import { mapRuleParams, ruleNameChoices } from '../../../ruleFeatures';
import ruleParameters, { RuleParameters } from '../../../../../constants/ruleParams';
import { Rule } from '../../../../../utilities/schemas/rules';

const ruleDataTransform = (data: RaRecord | string, param: string) => {
  const regex = /[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/; // special characters'
  if (param.includes('commonThreshold')) {
    const global = get(data, 'global');
    if (global) return `${global}`;
    return 'Risk-based';
  }

  if (isArray(data)) {
    if ([ruleParameters.FORM_THRESHOLDS.id, ruleParameters.DEFAULT_THRESHOLDS.id]
      .some((field) => field === param as RuleParameters['id'])) {
      return data.map((val) => Object.values(val)
        .filter((v) => !!v)
        .map(((v) => (isObject(v) ? 'Risk-based' : `${v}`)))
        .join(': '))
        .join(', ');
    }
    if (ruleParameters.LOWEST_ACCEPTABLE_INCOME.id === param) {
      return data.map((threshold) => `${threshold.currency}: ${threshold.amount} / ${threshold.unitOfTime}`).join(', ');
    }

    return data.map((item) => lowerCase(startCase(item))).join(', ');
  }
  if (isString(data) && regex.test(data)) return data;
  if (isString(data)) return startCase(data);
  return data;
};

const RuleParams = ({
  param,
}:{
  param: {
    id: string,
    name: string,
    unit?: string,
    subIds?: readonly string[]
    riskBased?: boolean
  } }) => {
  const record = useRecordContext();
  const data = param?.id?.split('.').reduce((o, i) => get(o, i, null), record?.ruleParameters);
  const ruleValue = ruleDataTransform(data, param?.id);

  if (!data) return null;

  if (!ruleValue) return null;
  const string = param?.subIds
    ? param?.subIds?.map((id) => data[id]).join(' ')
    : `${ruleValue} ${param?.unit ?? ''}`;

  return (
    <Grid key={param?.id} item xs={12} sm={6} lg={4}>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" gap={2} alignItems="center">
          <Typography fontWeight="bold" color="black">{param?.name}</Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap={2} alignItems="center" flexWrap="wrap">
          <TextField record={{ data: string }} source="data" />
        </Box>
      </Box>
    </Grid>
  );
};

const RuleLogic = () => {
  const record = useRecordContext<Rule>();

  if (!record) return null;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label" color="black" fontWeight="bold">Rule Name</Typography>
          <SelectField choices={ruleNameChoices} source="name" />
        </Box>
      </Grid>
      <Grid item container spacing={2} xs={12} md={12} display={mapRuleParams[record.name]?.length > 0 ? 'block' : 'none'}>
        <Grid item xs={12} md={12}>
          <Typography variant="label" fontWeight="bold">Rule parameters</Typography>
        </Grid>
        {mapRuleParams[record.name]?.map((param) => (
          <RuleParams
            key={param?.id}
            param={param}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default RuleLogic;
