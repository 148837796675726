import React, { useState } from 'react';
import {
  Edit,
  TextField,
  DateField,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Datagrid,
  Toolbar,
  SaveButton,
  Pagination,
  usePermissions,
  useNotify,
  useRefresh,
} from 'react-admin';

import { useFormState } from 'react-hook-form';

import {
  Card,
  Grid,
} from '@mui/material';

import ReferenceMemberField from '../../customFields/ReferenceMemberField';

import { StyledTab, StyledTabs } from '../layout/Tab';
import SubNavigation from '../layout/SubNavigation';

import GeneralInfo from './editTabs/data/general';
import VehicleInspection from './editTabs/data/vehicleInspection';
import PreviousOwners from './editTabs/data/previousOwners';
import Overview from './editTabs/overview';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';

import hasAccess from '../../utilities/hasAccess';

import CarBreadcrumb from './CarBreadcrumb';
import { Actions } from '../../constants/actions';
import useValidateId from '../../hooks/useValidateId';

const CustomToolbar = () => {
  const { isDirty } = useFormState();
  if (!isDirty) {
    return null;
  }
  return (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
};

const CarEdit = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { permissions } = usePermissions<Actions[]>();
  const [viewTab, setViewTab] = useState(0);

  const handleViewTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setViewTab(newValue);
  };

  const onSuccess = () => {
    notify('Car updated');
    refresh();
  };

  const validatedId = useValidateId();
  if (!validatedId.isValid) return null;

  return (
    <Edit mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
      <CarBreadcrumb />
      <TabbedForm toolbar={<CustomToolbar />}>
        <FormTab label="Overview">
          <Overview />
        </FormTab>
        <FormTab label="Data">
          <SubNavigation>
            <StyledTabs centered value={viewTab} onChange={handleViewTabChange}>
              <StyledTab label="General" value={0} />
              <StyledTab label="Previous owners" value={1} />
              <StyledTab label="Vehicle inspection" value={2} />
            </StyledTabs>
          </SubNavigation>
          {viewTab === 0 && <GeneralInfo />}
          {viewTab === 1 && <PreviousOwners />}
          {viewTab === 2 && <VehicleInspection />}
        </FormTab>
        {hasAccess(permissions, 'AuditTrackList') && (
          <FormTab label="Audit Tracks">
            <Grid container>
              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <ReferenceManyField
                    reference="audit-tracks"
                    target="resource.id"
                    sort={{ field: 'date', order: 'DESC' }}
                    pagination={<Pagination />}
                  >
                    <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                      <TextField sortable={false} source="id" />
                      <ReferenceMemberField label="User" />
                      <TextField sortable={false} source="description" />
                      <DateField sortable={false} source="date" showTime />
                    </Datagrid>
                  </ReferenceManyField>
                </Card>
              </Grid>
            </Grid>
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};

export default CarEdit;
