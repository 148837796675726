import React from 'react';

import { isArray, isEmpty } from 'lodash';

import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  RecordContextProvider,
  TextField,
  useRecordContext,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { Alpha3Code } from 'i18n-iso-countries';

import useEntityData from '../../../../hooks/useEntityData';

import actions from '../../../../constants/actions';
import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';
import { ViewJSONDialog } from '../../../../customFields/ViewJSON';

import NoResults from '../../../layout/NoResults';
import PurchaseButton from '../../../layout/button/PurchaseButton';
import EntityData from './EntityData';
import Pagination from '../../../layout/Pagination';
import { EntityTypes } from '../../../../constants/entityTypes';
import { RelatedEntityRelations, relatedEntityRelationsText } from '../../../../constants/relatedEntityRelations';
import { SanctionTypes } from '../../../../constants/sanctionTypes';
import AccordionField from '../../../../customFields/AccordionField';

type SanctionListCompany = {
  id: string;
  createdAt: string;
  potentialMatches: {
    matchProbability?: number;
    providerId?: string;
    fullName?: string;
    otherNames: {
      fullName?: string;
    }[];
    address?: {
      streetAddress?: string;
      additionalAddress?: string;
      postcode?: string;
      city?: string;
      state?: string;
      country?: Alpha3Code;
      description?: string;
    };
    otherAddresses: {
      streetAddress?: string;
      additionalAddress?: string;
      postcode?: string;
      city?: string;
      state?: string;
      country?: Alpha3Code;
      description?: string;
    }[];
    registrationCountry?: Alpha3Code;
    registrationNumber?: string;
    sanctions: {
      providerId?: string;
      country?: Alpha3Code;
      issuingBody?: string;
      regime?: string;
      sanctionType: SanctionTypes;
      measures?: string[];
      fromDate?: string;
      toDate?: string;
    }[];
    relatedEntities: {
      entityId?: string;
      entityType?: EntityTypes;
      providerId?: string;
      entityName: string;
      relationshipType?: RelatedEntityRelations;
      relationshipDetails?: string;
      fromDate?: string;
      toDate?: string;
    }[];
    profileImages: string[];
    sourceDetails: {
      providerId?: string;
      title?: string;
      description?: string;
      link?: string;
      documentLink?: string;
      dateAdded?: string;
      datePublished?: string;
      isCopyrighted?: boolean;
      publicationSource?: string;
    }[];
    createdAt?: string;
    updatedAt?: string;
  }[];
}

// eslint-disable-next-line no-empty-pattern
const SanctionsAccordion = ({}: { label: string }) => {
  const record = useRecordContext<SanctionListCompany['potentialMatches'][number]>();

  if (!record || isEmpty(record?.sanctions)) {
    return null;
  }

  const accordionTitle = record.sanctions
    .map((sanction) => sanction.issuingBody ?? sanction.sanctionType)
    .join(', ');

  return (
    <AccordionField title={accordionTitle}>
      <Box display="flex" gap={2} flexDirection="column">
        {record?.sanctions?.map((sanction, index) => (
          <RecordContextProvider value={sanction} key={JSON.stringify(sanction)}>
            <Box width="fit-content" padding={1}>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>Number:</strong>
                  {' '}
                  {index + 1}
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>Issuing body:</strong>
                  {' '}
                  <TextField source="issuingBody" emptyText="-" />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>Sanction type:</strong>
                  {' '}
                  <TextField source="sanctionType" emptyText="-" />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>Regime:</strong>
                  {' '}
                  <TextField source="regime" emptyText="-" />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>Measures:</strong>
                  {' '}
                  <FunctionField
                    render={(sanctionRecord: SanctionListCompany['potentialMatches'][number]['sanctions'][number]) => {
                      if (!sanctionRecord.measures?.length) return '-';
                      return sanctionRecord.measures.join(', ');
                    }}
                  />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Typography>
                  <strong>From date:</strong>
                  {' '}
                  <DateField source="fromDate" emptyText="-" />
                </Typography>
              </Box>
              <Box display="flex" gap={1}>
                <Typography>
                  <strong>Country:</strong>
                  <TextField source="country" emptyText="-" />
                </Typography>
              </Box>
            </Box>
          </RecordContextProvider>
        ))}
      </Box>
    </AccordionField>
  );
};

const SanctionListBody = ({
  data,
  handlePurchase,
  isLoading,
}: {
  data: SanctionListCompany | undefined,
  handlePurchase: () => void
  isLoading: boolean
}) => (
  <RecordContextProvider value={data}>
    <Card variant="outlined">
      <CardHeader
        title="Sanction List"
        subheader={(
          <Box display="flex" alignItems="baseline">
            <Typography>Latest update:</Typography>
            <DateField sx={{ marginLeft: 1, fontWeight: 'bold' }} source="createdAt" emptyText="-" showTime />
          </Box>
        )}
        action={(
          <PurchaseButton
            loading={isLoading}
            label="Get Sanction List"
            onClick={handlePurchase}
            empty={!isArray(data?.potentialMatches)}
            action={actions.BUSINESS_CREATE_SANCTION_LIST_COMPANY}
          />
        )}
      />
      <Divider />
      <ArrayField source="potentialMatches" perPage={15}>
        <Datagrid empty={<NoResults variant="h6" />} sx={{ ...boldDataGridStyle, width: '100%' }} bulkActionButtons={false}>
          <FunctionField
            label="Names"
            render={(record: SanctionListCompany['potentialMatches'][number]) => {
              const { fullName } = record;
              if (!fullName) return '-';
              const names = [{ fullName }, ...record.otherNames];
              return names
                .map((name) => name.fullName ?? '')
                .slice(0, 5).join('\n');
            }}
          />
          <TextField source="registrationNumber" emptyText="-" />
          <TextField source="registrationCountry" emptyText="-" />
          <FunctionField
            label="Addresses"
            render={(record: SanctionListCompany['potentialMatches'][number]) => {
              if (!record.address) return '-';
              const addresses = [record.address, ...record.otherAddresses];
              return addresses
                .map((address) => address.description ?? [
                  address.streetAddress,
                  address.additionalAddress,
                  address.postcode,
                  address.city,
                  address.state,
                  address.country,
                ].filter((item) => !!item).join(', '))
                .slice(0, 5).join('\n');
            }}
          />
          <SanctionsAccordion label="Sanctions" />
          <FunctionField
            label="Relations"
            render={(record: SanctionListCompany['potentialMatches'][number]) => {
              if (record.relatedEntities.length === 0) return '-';
              return record.relatedEntities
                .map((relatedEntity) => [
                  relatedEntity.entityName,
                  relatedEntity.relationshipType
                    ? relatedEntityRelationsText[relatedEntity.relationshipType] : undefined,
                ].filter((text) => !!text).join(' - '))
                .slice(0, 5).join('\n');
            }}
          />
          <ViewJSONDialog title="Inspect raw data" />
        </Datagrid>
        <Pagination disableEmptyText />
      </ArrayField>
    </Card>
  </RecordContextProvider>
);

const SanctionList = () => {
  const {
    data,
    handlePurchase,
    isLoading,
  } = useEntityData<SanctionListCompany>({
    resource: 'businesses',
    source: 'sanction-list',
    getAction: actions.BUSINESS_GET_SANCTION_LIST_COMPANY,
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <EntityData />
      </Grid>
      <Grid item xs={12} md={12}>
        <SanctionListBody data={data} handlePurchase={handlePurchase} isLoading={isLoading} />
      </Grid>
    </Grid>
  );
};

export default SanctionList;
