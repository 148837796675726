import ruleNames, { RuleNames } from '../../../constants/ruleNames';
import ruleParameters, { RuleParameters } from '../../../constants/ruleParams';

const mapRuleParams: Record<RuleNames, RuleParameters[]> = {
  [ruleNames.ADDITIONAL_DELIVERY_ADDRESS]: [ruleParameters.UPDATE_FREQ],
  [ruleNames.AFFORDABILITY]: [
    ruleParameters.MULTIPLIER,
    ruleParameters.PERIOD_IN_MONTHS,
  ],
  [ruleNames.AGE_THRESHOLD]: [ruleParameters.UPPER_AGE_LIMIT,
    ruleParameters.LOWER_AGE_LIMIT,
  ],
  [ruleNames.AMOUNT_THRESHOLD]: [ruleParameters.DEFAULT_THRESHOLDS],
  [ruleNames.ANOMALY_DETECTION_INCOMING_AVERAGE]: [
    ruleParameters.DEVIATION,
    ruleParameters.WINDOW_PERIOD,
    ruleParameters.MINIMUM_NUMBER_OF_OBSERVATIONS,
    ruleParameters.PERIOD_TO_CONSIDER,
    ruleParameters.MINIMUM_USAGE_TIME,
  ],
  [ruleNames.ANOMALY_DETECTION_INCOMING_FREQUENCY]: [
    ruleParameters.DEVIATION,
    ruleParameters.WINDOW_PERIOD,
    ruleParameters.MINIMUM_NUMBER_OF_OBSERVATIONS,
    ruleParameters.PERIOD_TO_CONSIDER,
    ruleParameters.MINIMUM_USAGE_TIME,
  ],
  [ruleNames.ANOMALY_DETECTION_INCOMING_SINGLE_AMOUNT]: [
    ruleParameters.DEVIATION,
    ruleParameters.MINIMUM_NUMBER_OF_OBSERVATIONS,
    ruleParameters.PERIOD_TO_CONSIDER,
    ruleParameters.MINIMUM_USAGE_TIME,
  ],
  [ruleNames.ANOMALY_DETECTION_INCOMING_VOLUME_ROLLING_SUM]: [
    ruleParameters.DEVIATION,
    ruleParameters.WINDOW_PERIOD,
    ruleParameters.MINIMUM_NUMBER_OF_OBSERVATIONS,
    ruleParameters.PERIOD_TO_CONSIDER,
    ruleParameters.MINIMUM_USAGE_TIME,
  ],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_AVERAGE]: [
    ruleParameters.DEVIATION,
    ruleParameters.WINDOW_PERIOD,
    ruleParameters.MINIMUM_NUMBER_OF_OBSERVATIONS,
    ruleParameters.PERIOD_TO_CONSIDER,
    ruleParameters.MINIMUM_USAGE_TIME,
  ],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_FREQUENCY]: [
    ruleParameters.DEVIATION,
    ruleParameters.WINDOW_PERIOD,
    ruleParameters.MINIMUM_NUMBER_OF_OBSERVATIONS,
    ruleParameters.PERIOD_TO_CONSIDER,
    ruleParameters.MINIMUM_USAGE_TIME,
  ],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_SINGLE_AMOUNT]: [
    ruleParameters.DEVIATION,
    ruleParameters.MINIMUM_NUMBER_OF_OBSERVATIONS,
    ruleParameters.PERIOD_TO_CONSIDER,
    ruleParameters.MINIMUM_USAGE_TIME,
  ],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_VOLUME_ROLLING_SUM]: [
    ruleParameters.DEVIATION,
    ruleParameters.WINDOW_PERIOD,
    ruleParameters.MINIMUM_NUMBER_OF_OBSERVATIONS,
    ruleParameters.PERIOD_TO_CONSIDER,
    ruleParameters.MINIMUM_USAGE_TIME,
  ],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_AVERAGE]: [
    ruleParameters.DEVIATION,
    ruleParameters.WINDOW_PERIOD,
    ruleParameters.MINIMUM_NUMBER_OF_OBSERVATIONS,
    ruleParameters.PERIOD_TO_CONSIDER,
    ruleParameters.MINIMUM_USAGE_TIME,
  ],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_FREQUENCY]: [
    ruleParameters.DEVIATION,
    ruleParameters.WINDOW_PERIOD,
    ruleParameters.MINIMUM_NUMBER_OF_OBSERVATIONS,
    ruleParameters.PERIOD_TO_CONSIDER,
    ruleParameters.MINIMUM_USAGE_TIME,
  ],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_SINGLE_AMOUNT]: [
    ruleParameters.DEVIATION,
    ruleParameters.MINIMUM_NUMBER_OF_OBSERVATIONS,
    ruleParameters.PERIOD_TO_CONSIDER,
    ruleParameters.MINIMUM_USAGE_TIME,
  ],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_VOLUME_ROLLING_SUM]: [
    ruleParameters.DEVIATION,
    ruleParameters.WINDOW_PERIOD,
    ruleParameters.MINIMUM_NUMBER_OF_OBSERVATIONS,
    ruleParameters.PERIOD_TO_CONSIDER,
    ruleParameters.MINIMUM_USAGE_TIME,
  ],
  [ruleNames.ANOMALY_DETECTION_TOTAL_AVERAGE]: [
    ruleParameters.DEVIATION,
    ruleParameters.WINDOW_PERIOD,
    ruleParameters.MINIMUM_NUMBER_OF_OBSERVATIONS,
    ruleParameters.PERIOD_TO_CONSIDER,
    ruleParameters.MINIMUM_USAGE_TIME,
  ],
  [ruleNames.ANOMALY_DETECTION_TOTAL_FREQUENCY]: [
    ruleParameters.DEVIATION,
    ruleParameters.WINDOW_PERIOD,
    ruleParameters.MINIMUM_NUMBER_OF_OBSERVATIONS,
    ruleParameters.PERIOD_TO_CONSIDER,
    ruleParameters.MINIMUM_USAGE_TIME,
  ],
  [ruleNames.ANOMALY_DETECTION_TOTAL_SINGLE_AMOUNT]: [
    ruleParameters.DEVIATION,
    ruleParameters.MINIMUM_NUMBER_OF_OBSERVATIONS,
    ruleParameters.PERIOD_TO_CONSIDER,
    ruleParameters.MINIMUM_USAGE_TIME,
  ],
  [ruleNames.ANOMALY_DETECTION_TOTAL_VOLUME_ROLLING_SUM]: [
    ruleParameters.DEVIATION,
    ruleParameters.WINDOW_PERIOD,
    ruleParameters.MINIMUM_NUMBER_OF_OBSERVATIONS,
    ruleParameters.PERIOD_TO_CONSIDER,
    ruleParameters.MINIMUM_USAGE_TIME,
  ],
  [ruleNames.ANOMALY_DETECTION]: [
    ruleParameters.DIRECTION,
    ruleParameters.DEFAULT_THRESHOLDS,
    ruleParameters.DEFAULT_COMMON_THRESHOLD,
    ruleParameters.PERIOD,
  ],
  [ruleNames.BUSINESS_ACTIVE]: [ruleParameters.UPDATE_FREQ],
  [ruleNames.BUSINESS_AUDITOR]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.AUDITOR_STATUSES,
  ],
  [ruleNames.BUSINESS_DATA_CHANGED]: [],
  [ruleNames.BUSINESS_EQUITY_SHARE]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.DEFAULT_THRESHOLDS,
  ],
  [ruleNames.BUSINESS_EQUITY]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.DEFAULT_THRESHOLDS,
  ],
  [ruleNames.BUSINESS_FINANCIAL_STATEMENT_COMMENT]: [ruleParameters.UPDATE_FREQ],
  [ruleNames.BUSINESS_FTAX]: [ruleParameters.UPDATE_FREQ],
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS_SUM]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.DEFAULT_THRESHOLDS,
  ],
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.DEFAULT_THRESHOLDS,
  ],
  [ruleNames.BUSINESS_PAYMENT_REMARKS_SUM]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.DEFAULT_THRESHOLDS,
  ],
  [ruleNames.BUSINESS_PAYMENT_REMARKS]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.DEFAULT_THRESHOLDS,
  ],
  [ruleNames.BUSINESS_PROVIDER_WARNING]: [ruleParameters.UPDATE_FREQ],
  [ruleNames.BUSINESS_SANCTION_LIST]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.HIT_RATIO,
    ruleParameters.COUNTRY_CODE,
  ],
  [ruleNames.BUSINESS_VAT]: [ruleParameters.UPDATE_FREQ],
  [ruleNames.CAR_FREQ_HISTORICAL_OWNERS]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.CHANGE_FREQ,
    ruleParameters.CHANGE_MONTHS_LIMIT,
  ],
  [ruleNames.CAR_GUARANTOR_RELATED]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.CONNECTED_ENTITIES_TO_CHECK,
  ],
  [ruleNames.CAR_IN_CIRCULATION]: [ruleParameters.UPDATE_FREQ],
  [ruleNames.CAR_VALUATION]: [ruleParameters.VALUATION_DEVIATION, ruleParameters.VALUATION_PREFIX],
  [ruleNames.CASH_FLOW]: [
    ruleParameters.DEFAULT_THRESHOLDS,
    ruleParameters.PERIOD,
    ruleParameters.DIRECTION,
  ],
  [ruleNames.CATEGORIZATION_AMOUNT_THRESHOLD]: [ruleParameters.DEFAULT_THRESHOLDS],
  [ruleNames.CO_ADDRESS]: [ruleParameters.UPDATE_FREQ],
  [ruleNames.COLLECTOR]: [ruleParameters.UPDATE_FREQ, ruleParameters.COLLECT_FIELDS],
  [ruleNames.CONNECTED_BUYER_SELLER]: [ruleParameters.UPDATE_FREQ],
  [ruleNames.CONNECTED_ENTITY_PEP]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.HIT_RATIO,
    ruleParameters.COUNTRY_CODE,
    ruleParameters.CONNECTED_ENTITIES_TO_CHECK,
  ],
  [ruleNames.CONNECTED_ENTITY_SANCTION_LIST]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.HIT_RATIO,
    ruleParameters.COUNTRY_CODE,
    ruleParameters.CONNECTED_ENTITIES_TO_CHECK,
  ],
  [ruleNames.COUNTRY_CITIZENSHIP_BLACKLIST]: [
    ruleParameters.COUNTRIES,
  ],
  [ruleNames.COUNTRY_RESIDENCE_BLACKLIST]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.COUNTRIES,
    ruleParameters.SHOULD_PURCHASE,
  ],
  [ruleNames.COUNTRY_RESIDENCE_WHITELIST]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.COUNTRIES,
    ruleParameters.SHOULD_PURCHASE,
  ],
  [ruleNames.EMPLOYMENT_TYPE]: [ruleParameters.UNACCEPTABLE_EMPLOYMENT_TYPES],
  [ruleNames.FOREIGN_ADDRESS]: [ruleParameters.UPDATE_FREQ],
  [ruleNames.INCOMING_CATEGORIZATION_FREQUENCY]: [
    ruleParameters.DEFAULT_COMMON_THRESHOLD,
    ruleParameters.FORM_COMMON_THRESHOLD,
    ruleParameters.DEFAULT_THRESHOLDS,
    ruleParameters.PERIOD,
    ruleParameters.CATEGORY,
  ],
  [ruleNames.INCOMING_CATEGORIZATION_ROLLING_SUM]: [
    ruleParameters.DEFAULT_THRESHOLDS,
    ruleParameters.PERIOD,
    ruleParameters.CATEGORY,
  ],
  [ruleNames.INCOMING_FREQUENCY]: [
    ruleParameters.DEFAULT_COMMON_THRESHOLD,
    ruleParameters.FORM_COMMON_THRESHOLD,
    ruleParameters.FORM_THRESHOLDS,
    ruleParameters.DEFAULT_THRESHOLDS,
    ruleParameters.PERIOD,
  ],
  [ruleNames.INCOMING_VOLUME_ROLLING_SUM]: [
    ruleParameters.PERIOD,
    ruleParameters.FORM_COMMON_THRESHOLD,
    ruleParameters.FORM_THRESHOLDS,
    ruleParameters.DEFAULT_THRESHOLDS,
    ruleParameters.PARAMETER_PRIORITY,
    ruleParameters.KYC_FORM_DATA_MAX_AGE,
  ],
  [ruleNames.INDIVIDUAL_CRIMINAL_RECORD]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.LEGAL_SCORE,
    ruleParameters.CONNECTED_ENTITIES_TO_CHECK,
  ],
  [ruleNames.INDIVIDUAL_PEP]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.HIT_RATIO,
    ruleParameters.COUNTRY_CODE,
  ],
  [ruleNames.INDIVIDUAL_SANCTION_LIST]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.HIT_RATIO,
    ruleParameters.COUNTRY_CODE,
  ],
  [ruleNames.IP_COUNTRY_BLACKLIST]: [ruleParameters.UPDATE_FREQ,
    ruleParameters.COUNTRIES,
  ],
  [ruleNames.KYC_VALID]: [
    ruleParameters.KYC_PARAMS,
    ruleParameters.KYC_FORM_DATA_MAX_AGE,
    ruleParameters.TYPE_OF_CHECK,
  ],
  [ruleNames.LOW_INCOME]: [ruleParameters.LOWEST_ACCEPTABLE_INCOME],
  [ruleNames.OUTGOING_CATEGORIZATION_FREQUENCY]: [
    ruleParameters.DEFAULT_THRESHOLDS,
    ruleParameters.PERIOD,
    ruleParameters.CATEGORY,
    ruleParameters.DEFAULT_COMMON_THRESHOLD,
    ruleParameters.FORM_COMMON_THRESHOLD,
  ],
  [ruleNames.OUTGOING_CATEGORIZATION_ROLLING_SUM]: [
    ruleParameters.DEFAULT_THRESHOLDS,
    ruleParameters.PERIOD,
    ruleParameters.CATEGORY,
  ],
  [ruleNames.OUTGOING_FREQUENCY]: [
    ruleParameters.DEFAULT_THRESHOLDS,
    ruleParameters.DEFAULT_COMMON_THRESHOLD,
    ruleParameters.FORM_COMMON_THRESHOLD,
    ruleParameters.FORM_THRESHOLDS,
    ruleParameters.PERIOD,
  ],
  [ruleNames.OUTGOING_VOLUME_ROLLING_SUM]: [
    ruleParameters.FORM_COMMON_THRESHOLD,
    ruleParameters.FORM_THRESHOLDS,
    ruleParameters.DEFAULT_THRESHOLDS,
    ruleParameters.PERIOD,
    ruleParameters.PARAMETER_PRIORITY,
    ruleParameters.KYC_FORM_DATA_MAX_AGE,
  ],
  [ruleNames.PROCESSING_FREQUENCY]: [
    ruleParameters.DEFAULT_COMMON_THRESHOLD,
    ruleParameters.FORM_COMMON_THRESHOLD,
    ruleParameters.FORM_THRESHOLDS,
    ruleParameters.DEFAULT_THRESHOLDS,
    ruleParameters.PERIOD,
  ],
  [ruleNames.PROCESSING_VOLUME_ROLLING_SUM]: [
    ruleParameters.PERIOD,
    ruleParameters.FORM_COMMON_THRESHOLD,
    ruleParameters.FORM_THRESHOLDS,
    ruleParameters.DEFAULT_THRESHOLDS,
    ruleParameters.PARAMETER_PRIORITY,
    ruleParameters.KYC_FORM_DATA_MAX_AGE,
  ],
  [ruleNames.RELATED_INDIVIDUALS_CRIMINAL_RECORD]: [
    ruleParameters.UPDATE_FREQ,
    ruleParameters.LEGAL_SCORE,
  ],
  [ruleNames.SELLER_OWNS_CAR]: [ruleParameters.UPDATE_FREQ],
  [ruleNames.SNI_CODE_BLACKLIST]: [ruleParameters.UPDATE_FREQ, ruleParameters.SNI_CODES],
  [ruleNames.SOURCE_OF_FUNDS]: [ruleParameters.UNACCEPTABLE_SOURCE_FUNDS],
  [ruleNames.SOURCE_OF_INCOME]: [ruleParameters.UNACCEPTABLE_SOURCE_INCOME],
  [ruleNames.SPAR_CHANGED]: [],
  [ruleNames.SUSPICIOUS_FUNDS]: [ruleParameters.DEFAULT_THRESHOLDS, ruleParameters.PERIOD],
  [ruleNames.TEST_BLOCK]: [],
  [ruleNames.TEST_REVIEW]: [],
  [ruleNames.TIME_WINDOW]: [ruleParameters.FROM, ruleParameters.TO],
  [ruleNames.TOTAL_CATEGORIZATION_FREQUENCY]: [
    ruleParameters.DEFAULT_THRESHOLDS,
    ruleParameters.PERIOD,
    ruleParameters.CATEGORY,
    ruleParameters.DEFAULT_COMMON_THRESHOLD,
    ruleParameters.FORM_COMMON_THRESHOLD,
  ],
  [ruleNames.TOTAL_CATEGORIZATION_ROLLING_SUM]: [
    ruleParameters.DEFAULT_THRESHOLDS,
    ruleParameters.PERIOD,
    ruleParameters.CATEGORY,
  ],
  [ruleNames.TOTAL_FREQUENCY]: [
    ruleParameters.DEFAULT_THRESHOLDS,
    ruleParameters.DEFAULT_COMMON_THRESHOLD,
    ruleParameters.FORM_COMMON_THRESHOLD,
    ruleParameters.FORM_THRESHOLDS,
    ruleParameters.PERIOD,
  ],
  [ruleNames.TOTAL_VOLUME_ROLLING_SUM]: [
    ruleParameters.FORM_COMMON_THRESHOLD,
    ruleParameters.FORM_THRESHOLDS,
    ruleParameters.DEFAULT_THRESHOLDS,
    ruleParameters.PERIOD,
    ruleParameters.PARAMETER_PRIORITY,
    ruleParameters.KYC_FORM_DATA_MAX_AGE,
  ],
  [ruleNames.ZIP_CODE_BLACKLIST]: [ruleParameters.UPDATE_FREQ, ruleParameters.ZIP_CODES],
};

export default mapRuleParams;
