import React from 'react';

import {
  ListBase,
} from 'react-admin';

import {
  Grid,
  Card,
  CardHeader,
  Divider,
} from '@mui/material';

import Pagination from '../../layout/Pagination';

import { requestListMap } from '../../../constants/requestFeatures';
import { requestTypes } from '../../../constants/requestTypes';

const TransactionListComponent = requestListMap[requestTypes.TRANSACTION];

const DetailedView = ({
  filter,
}: {
  filter: {
    from: string;
    to: string;
    requestType: typeof requestTypes.TRANSACTION;
    currency: string;
    entityId?: string;
  }
}) => (
  <Grid container spacing={6}>
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <CardHeader title="Detailed Transactions" />
        <Divider />
        <ListBase
          storeKey={false}
          filter={{
            requestType: filter?.requestType,
            'extraInformation.currency': filter?.currency,
            'custom.entity': filter?.entityId,
            'custom.from': filter?.from,
            'custom.to': filter?.to,
          }}
          perPage={10}
          resource="requests"
          sort={{ field: 'extraInformation.date', order: 'DESC' }}
        >
          <TransactionListComponent />
          <Pagination disableEmptyText rowsPerPageOptions={[10, 15, 20]} />
        </ListBase>
      </Card>
    </Grid>
  </Grid>

);

export default DetailedView;
