import React from 'react';

import {
  Accordion, AccordionDetails, AccordionSummary, Box, Card, Divider, Grid, Typography,
} from '@mui/material';

import ChevronRight from '@mui/icons-material/ChevronRight';

import {
  get, isEmpty,
} from 'lodash';

import {
  RecordContextProvider, useRecordContext, TextField,
} from 'react-admin';

import { Alpha3Code, getName } from 'i18n-iso-countries';

import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import conflictFields from '../../../../constants/conflictFields';

type TxObj = {
  monthlyVolume: number,
  monthlyFrequency: number,
  max: number,
  median: number
  expectedTransactionTimeline: {
    amount: number;
    unit: string;
  }
}

type TxMonitoringThresholds = {
  [k in string]: {
    [j in Alpha3Code]?: {
      incoming?: TxObj
      outgoing?: TxObj
      process?: TxObj
      total?: TxObj
    }
  }
}

type SignificantTransactionCountryObject = {
  country: string,
  incoming?: TxObj & { currency: string }
  outgoing?: TxObj & { currency: string }
  process?: TxObj & { currency: string }
  total?: TxObj & { currency: string }
}

type ExtraParametersObj = {
  disabled?: boolean;
  enabled?: boolean;
  label?: string
}

type ExtraParameters = {
  [k in 'incoming' | 'outgoing' | 'process' | 'total']?: {
    disabled?: boolean,
    volume?: ExtraParametersObj,
    frequency?: ExtraParametersObj,
    max?: ExtraParametersObj,
    median?: ExtraParametersObj,
    expectedTransactionTimeline: ExtraParametersObj
  }
}

const LABEL = {
  [languages.EN]: 'Country of activity ',
  [languages.SV]: 'Verksamhetsland',
};

const NestedField = ({
  label,
  source,
  unit,
}: {
  label: string;
  source: string;
  unit?: string;
}) => {
  const record = useRecordContext();

  const value = get(record, source);
  const sx = get(record, 'sx', {});

  const string = `${value} ${unit ?? ''}`.trimEnd();

  if (!value) {
    return (
      <Grid item xs={12} md={12}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">{label}</Typography>
          <Typography>-</Typography>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} md={12}>
      <Box display="flex" flexDirection="column">
        <Typography variant="label">{label}</Typography>
        <TextField record={{ string }} sx={sx} source="string" emptyText="-" />
      </Box>
    </Grid>
  );
};

const ValueField = ({
  language,
  extraParameters,
}: {
  language?: Languages,
  extraParameters?: ExtraParameters
}) => {
  const record = useRecordContext();

  const value = get(record, 'value', {});
  const sx = get(record, 'sx', {});

  const countries: SignificantTransactionCountryObject[] = [];
  Object.entries(value as TxMonitoringThresholds).forEach(([currency, countryObj]) => {
    Object.entries(countryObj).forEach(([country, txObj]) => {
      const existingCountry = countries.find((c) => c.country === country);
      if (existingCountry) {
        if (txObj.incoming) {
          existingCountry.incoming = { currency, ...txObj.incoming };
        }
        if (txObj.outgoing) {
          existingCountry.outgoing = { currency, ...txObj.outgoing };
        }
        if (txObj.process) {
          existingCountry.process = { currency, ...txObj.process };
        }
        if (txObj.total) {
          existingCountry.total = { currency, ...txObj.total };
        }
      } else {
        countries.push({
          country,
          ...(txObj.incoming ? { incoming: { currency, ...txObj.incoming } } : {}),
          ...(txObj.outgoing ? { outgoing: { currency, ...txObj.outgoing } } : {}),
          ...(txObj.process ? { process: { currency, ...txObj.process } } : {}),
          ...(txObj.total ? { total: { currency, ...txObj.total } } : {}),
        });
      }
    });
  });

  if (!language) return null;

  if (isEmpty(countries)) return <Typography>-</Typography>;

  return (
    <Card
      variant="outlined"
      square
      sx={{
        m: 0,
        py: 0,
        width: '100%',
      }}
    >
      <Grid container bgcolor="background.paper">
        {countries?.map((
          txObj,
          index,
        ) => (
          <Grid key={`signatory-${index + 1}`} item xs={12} md={12}>
            <Accordion key={`${txObj.country}  ${index + 1}`} sx={{ maxWidth: null, backgroundColor: 'transparent' }}>
              <AccordionSummary
                expandIcon={<ChevronRight />}
                sx={{
                  flexDirection: 'row-reverse',
                  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                    transform: 'rotate(90deg)',
                  },
                }}
              >
                <Typography
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    ml: 4,
                    ...sx,
                  }}
                  variant="subtitle2"
                  fontWeight="bold"
                >
                  {getName(txObj.country, language)}
                </Typography>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <RecordContextProvider value={{ language, ...txObj, sx }}>
                  <Grid container spacing={6} py="1%" px="1%">
                    {!extraParameters?.incoming?.volume?.disabled && (
                      <NestedField
                        source="incoming.monthlyVolume"
                        label={extraParameters?.incoming?.volume?.label ?? 'What is the monthly incoming volume of transactions you will conduct?'}
                        unit={txObj?.incoming?.currency}
                      />
                    )}
                    {!extraParameters?.incoming?.expectedTransactionTimeline?.enabled && (
                      <NestedField
                        source="incoming.expectedTransactionTimeline.amount"
                        label={extraParameters?.incoming?.expectedTransactionTimeline?.label ?? 'What is the expected incoming transaction time-line?'}
                        unit={txObj?.incoming?.expectedTransactionTimeline?.unit}
                      />
                    )}
                    {!extraParameters?.incoming?.max?.disabled && (
                      <NestedField
                        source="incoming.max"
                        label={extraParameters?.incoming?.max?.label ?? 'What is the estimated incoming singular highest amount?'}
                        unit={txObj?.incoming?.currency}
                      />
                    )}
                    {!extraParameters?.incoming?.median?.disabled && (
                      <NestedField
                        source="incoming.median"
                        label={extraParameters?.incoming?.median?.label ?? 'What is the estimated incoming typical amount?'}
                        unit={txObj?.incoming?.currency}
                      />
                    )}
                    {!extraParameters?.incoming?.frequency?.disabled && (
                      <NestedField
                        source="incoming.monthlyFrequency"
                        label={extraParameters?.incoming?.frequency?.label ?? 'How many incoming transactions will you conduct on a monthly basis?'}
                      />
                    )}
                    {!extraParameters?.outgoing?.volume?.disabled && (
                      <NestedField
                        source="outgoing.monthlyVolume"
                        label={extraParameters?.outgoing?.volume?.label ?? 'What is the monthly outgoing volume of transactions you will conduct?'}
                        unit={txObj?.outgoing?.currency}
                      />
                    )}
                    {!extraParameters?.outgoing?.expectedTransactionTimeline?.enabled && (
                      <NestedField
                        source="outgoing.expectedTransactionTimeline.amount"
                        label={extraParameters?.outgoing?.expectedTransactionTimeline?.label ?? 'What is the expected outgoing transaction time-line?'}
                        unit={txObj?.outgoing?.expectedTransactionTimeline?.unit}
                      />
                    )}
                    {!extraParameters?.outgoing?.max?.disabled && (
                      <NestedField
                        source="outgoing.max"
                        label={extraParameters?.outgoing?.max?.label ?? 'What is the estimated outgoing singular highest amount?'}
                        unit={txObj?.outgoing?.currency}
                      />
                    )}
                    {!extraParameters?.outgoing?.median?.disabled && (
                      <NestedField
                        source="outgoing.median"
                        label={extraParameters?.outgoing?.median?.label ?? 'What is the estimated outgoing typical amount?'}
                        unit={txObj?.outgoing?.currency}
                      />
                    )}
                    {!extraParameters?.outgoing?.frequency?.disabled && (
                      <NestedField
                        source="outgoing.monthlyFrequency"
                        label={extraParameters?.outgoing?.frequency?.label ?? 'How many outgoing transactions will you conduct on a monthly basis?'}
                      />
                    )}
                    {!extraParameters?.process?.volume?.disabled && (
                      <NestedField
                        source="process.monthlyVolume"
                        label={extraParameters?.process?.volume?.label ?? 'What is the volume of transactions you expect process per month?'}
                        unit={txObj?.outgoing?.currency}
                      />
                    )}
                    {!extraParameters?.process?.expectedTransactionTimeline?.enabled && (
                      <NestedField
                        source="process.expectedTransactionTimeline.amount"
                        label={extraParameters?.process?.expectedTransactionTimeline?.label ?? 'What is the expected processing transaction time-line?'}
                        unit={txObj?.process?.expectedTransactionTimeline?.unit}
                      />
                    )}
                    {!extraParameters?.process?.max?.disabled && (
                      <NestedField
                        source="process.max"
                        label={extraParameters?.process?.max?.label ?? 'What is the estimated singular highest processing amount?'}
                        unit={txObj?.outgoing?.currency}
                      />
                    )}
                    {!extraParameters?.process?.median?.disabled && (
                      <NestedField
                        source="process.median"
                        label={extraParameters?.process?.median?.label ?? 'What is the estimated typical process amount?'}
                        unit={txObj?.process?.currency}
                      />
                    )}
                    {!extraParameters?.process?.frequency?.disabled && (
                      <NestedField
                        source="process.monthlyFrequency"
                        label={extraParameters?.process?.frequency?.label ?? 'How many transactions do you expect to process per month?'}
                      />
                    )}
                    {!extraParameters?.total?.volume?.disabled && (
                      <NestedField
                        source="total.monthlyVolume"
                        label={extraParameters?.total?.volume?.label ?? 'What is the total volume of transactions you expect per month?'}
                        unit={txObj?.total?.currency}
                      />
                    )}
                    {!extraParameters?.total?.expectedTransactionTimeline?.enabled && (
                      <NestedField
                        source="total.expectedTransactionTimeline.amount"
                        label={extraParameters?.total?.expectedTransactionTimeline?.label ?? 'What is the expected transaction time-line?'}
                        unit={txObj?.total?.expectedTransactionTimeline?.unit}
                      />
                    )}
                    {!extraParameters?.total?.max?.disabled && (
                      <NestedField
                        source="total.max"
                        label={extraParameters?.total?.max?.label ?? 'What is the estimated singular highest amount?'}
                        unit={txObj?.total?.currency}
                      />
                    )}
                    {!extraParameters?.total?.median?.disabled && (
                      <NestedField
                        source="total.median"
                        label={extraParameters?.total?.median?.label ?? 'What is the estimated typical amount?'}
                        unit={txObj?.total?.currency}
                      />
                    )}
                    {!extraParameters?.total?.frequency?.disabled && (
                      <NestedField
                        source="total.monthlyFrequency"
                        label={extraParameters?.total?.frequency?.label ?? 'How many transactions do you expect in total per month?'}
                      />
                    )}
                  </Grid>
                </RecordContextProvider>
              </AccordionDetails>
            </Accordion>
            {index + 1 !== countries.length && <Divider />}
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

const SignificantTransactionCountries = ({
  extraParameters,
}: {
  extraParameters?: ExtraParameters,
}) => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={LABEL[language]}
      isComplexType
      source="txMonitoringThresholds"
      datapoint={conflictFields.TRANSACTION_MONITORING_THRESHOLD}
    >
      <ValueField language={language} extraParameters={extraParameters} />
    </Answer>
  );
};

SignificantTransactionCountries.ValueField = ValueField;

export default SignificantTransactionCountries;
