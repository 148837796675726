import React, { useCallback, useState } from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  Pagination,
  usePermissions,
  useNotify,
  useRefresh,
} from 'react-admin';

import {
  Card,
  Grid,
  SxProps,
} from '@mui/material';

import { Route, Routes } from 'react-router-dom';
import useCustomEditView from '../../hooks/useCustomEditView';

import { StyledTab, StyledTabs } from '../layout/Tab';
import SubNavigation from '../layout/SubNavigation';

import { AccountEntityListBody } from '../accountEntity/AccountEntityList';

import CarOwnership from '../../customFields/CarOwnership';
import ReferenceMemberField from '../../customFields/ReferenceMemberField';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import actions, { Actions } from '../../constants/actions';
import List from './editTabs/kyc/relatedIndividuals/List';
import OwnerShip from './editTabs/kyc/OwnerShip';
import CompanyRepresentation from './editTabs/kyc/CompanyRepresentation';
import GeneralCompanyInformation from './editTabs/kyc/GeneralCompanyInformation';
import Information from './editTabs/Information';
import FinancialData from './editTabs/kyc/FinancialData';

import hasAccess from '../../utilities/hasAccess';

import KycForm from '../kycForm';
import KycFormView from '../kycForm/view';
import BusinessBreadcrumb from './BusinessBreadcrumb';
import Transaction from '../transaction';
import Categorization from '../categorization';
import CustomToolbar from '../../customFields/CustomToolbar';
import ConflictResolution from '../conflict/ConflictResolution';
import ConflictResolutionManualInput from '../conflict/ConflictResolutionManualInput';
import ConflictResolutionHistory from '../conflict/ConflictResolutionHistory';
import SanctionList from './editTabs/kyc/SanctionList';
import useValidateId from '../../hooks/useValidateId';

const customEditSx = (isCustomView: boolean): SxProps => (
  isCustomView
    ? { '& .MuiPaper-root': { backgroundColor: 'transparent', boxShadow: 'none' } }
    : {}
);

const BusinessEdit = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { permissions } = usePermissions<Actions[]>();
  const [kycTab, setKycTab] = useState('general');

  const { isCustomView } = useCustomEditView();

  const handleKycTabChange = useCallback((
    _event: React.SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setKycTab(newValue);
  }, []);

  const onSuccess = () => {
    notify('Individual updated');
    refresh();
  };

  const validatedId = useValidateId();
  if (!validatedId.isValid) return null;

  return (
    <Edit
      mutationMode="pessimistic"
      mutationOptions={{ onSuccess }}
      sx={customEditSx(isCustomView)}
    >
      <>
        <BusinessBreadcrumb />
        <Routes>
          <Route path="/form-answers/:formId/:kycFormId/:formPage">
            <Route path="" Component={KycFormView} />
          </Route>
          <Route path="conflict-resolution">
            <Route path="" Component={ConflictResolution} />
            <Route path="manual-input" Component={ConflictResolutionManualInput} />
            <Route path="resolution-history" Component={ConflictResolutionHistory} />
          </Route>
          <Route
            path="*"
            element={(
              <TabbedForm
                mode="all"
                toolbar={<CustomToolbar />}
                sx={isCustomView ? { display: 'none' } : {}}
              >
                <FormTab
                  label="Information"
                >
                  <Information setKycTab={setKycTab} />
                </FormTab>
                <FormTab label="KYC Data">
                  <SubNavigation>
                    <StyledTabs value={kycTab} onChange={handleKycTabChange}>
                      <StyledTab label="General" value="general" />
                      <StyledTab label="Company representation" value="company-representation" />
                      <StyledTab label="Ownership" value="ownership" />
                      <StyledTab label="Financial Data" value="financial-data" />
                      <StyledTab label="Sanction list" value="sanction-list" />
                      <StyledTab label="Related Individuals" value="related-individuals" />
                    </StyledTabs>
                  </SubNavigation>
                  {kycTab === 'general' && (
                    <GeneralCompanyInformation />
                  )}
                  {kycTab === 'company-representation' && (
                    <CompanyRepresentation />
                  )}
                  {kycTab === 'ownership' && (
                    <OwnerShip />
                  )}
                  {kycTab === 'financial-data' && (
                    <FinancialData />
                  )}
                  {kycTab === 'sanction-list' && (
                    <SanctionList />
                  )}
                  {kycTab === 'related-individuals' && (
                    <List />
                  )}
                </FormTab>
                <FormTab label="KYC Form" path="form-answers">
                  <KycForm />
                </FormTab>
                {hasAccess(permissions, actions.REQUEST_GET) && (
                  <FormTab label="Transactions">
                    <Transaction />
                  </FormTab>
                )}
                {hasAccess(permissions, actions.CAR_GET) && (
                  <FormTab label="Car ownership">
                    <CarOwnership />
                  </FormTab>
                )}
                {hasAccess(permissions, actions.ACCOUNT_ENTITY_GET) && (
                  <FormTab label="Connected Accounts">
                    <Grid container>
                      <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                          <ReferenceManyField
                            fullWidth
                            perPage={5}
                            pagination={<Pagination rowsPerPageOptions={[5, 10, 20]} />}
                            reference="account-entities"
                            target="connectedEntities.id"
                            sort={{ field: 'createdAt', order: 'DESC' }}
                          >
                            <AccountEntityListBody />
                          </ReferenceManyField>
                        </Card>
                      </Grid>
                    </Grid>
                  </FormTab>
                )}
                {hasAccess(permissions, actions.BANK_DATA_GET_CATEGORIZATION) && (
                  <FormTab label="Categorization">
                    <Categorization />
                  </FormTab>
                )}
                {hasAccess(permissions, actions.AUDIT_TRACK_LIST) && (
                  <FormTab label="Audit Tracks">
                    <Grid container>
                      <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                          <ReferenceManyField
                            reference="audit-tracks"
                            target="resource.id"
                            sort={{ field: 'date', order: 'DESC' }}
                            pagination={<Pagination />}
                          >
                            <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                              <TextField sortable={false} source="id" />
                              <ReferenceMemberField label="User" />
                              <TextField sortable={false} source="description" />
                              <DateField sortable source="date" showTime />
                            </Datagrid>
                          </ReferenceManyField>
                        </Card>
                      </Grid>
                    </Grid>
                  </FormTab>
                )}
              </TabbedForm>
            )}
          />
        </Routes>
      </>
    </Edit>
  );
};

export default BusinessEdit;
